import React, { useEffect, useState } from "react";
import RightSidebar from "../RightSidebar/RIghtSidebar";
import { API } from "../../API/API";
import { toast } from "react-toastify";
import axios from "axios";
import DOMPurify from "dompurify";
import { useDispatch, useSelector } from "react-redux";
import { fetchBetButton } from "../../Redux/Slices/betButtonSlice";

function Button_value() {
  const uId = sessionStorage.getItem("userData");
  const token = sessionStorage.getItem("token");

  const dispatch = useDispatch();
  const { buttonData } = useSelector((state) => state.buttonData);
  const [data, setData] = useState(buttonData);
  // console.log(data);
  // const
  // const Button_Api = async () => {
  //   try {
  //     let res = await API.get(`/Get_Button_value_List?uid=${uId}`);
  //     res = res.data.data;
  //     // console.log("Button_Api", res);
  //     setData(res);
  //   } catch (e) {
  //     console.log("Error While Fatch Button_Api", e);
  //   }
  // };

  useEffect(() => {
    dispatch(fetchBetButton({ id: uId, token }));
  }, [dispatch, uId]);
  useEffect(() => {
    setData(buttonData);
  }, [buttonData]);

  const handleInputChange = (event, id) => {
    const updatedData = data.map((item) => {
      if (item.id === id) {
        const sanitizedValue = DOMPurify.sanitize(event.target.value);
        return {
          ...item,
          price_level:
            event.target.name === "price_level"
              ? sanitizedValue.replace(/[^0-9k]/gi, "").substring(0, 9)
              : item.price_level,
          price_value:
            event.target.name === "price_value"
              ? sanitizedValue.replace(/[^0-9]/gi, "").substring(0, 9)
              : item.price_value,
        };
      }
      return item;
    });
    // console.log("update", updatedData);
    setData(updatedData);
  };

  const handleButtonClick = async () => {
    try {
      const promises = data.map(async (item) => {
        let res = await API.post(
          "Update_Button_value_list",
          {
            id: item.id,
            uid: uId,
            price_level: item.price_level,
            price_value: item.price_value,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );
        // console.log("res",res)
        return res.data;
      });
      // console.log("Button_Api_promises", promises);
      const results = await Promise.all(promises);

      const isSuccess = results.every((res) => res.success);
      console.log("isSuccess", isSuccess);
      if (isSuccess) {
        toast.success("Successful");
        // Button_Api();
        dispatch(fetchBetButton({ id: uId, token }));
      } else {
        toast.error("Failed to update some items");
      }
      // setData(res);
    } catch (e) {
      console.log("Error While Fatch Button_Api", e);
    }
  };
  return (
    <div className="col-lg-10  pt-5 pt-3 ">
      <div className="row px-1 pt-4 pt-3">
        <div className="col-lg-8  px-2 score-card">
          <div className="container">
            <div className="row">
              <div className="col-md-12 ">
                <div className="section_bg">
                  <div className="mybet_wedget">
                    <div className="bars_bg">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="F-h p-2 pb-0">
                            <p className="ms-3">Set Button Value</p>
                          </div>
                          <hr
                            style={{ color: "orange", marginBottom: "2px" }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <from>
                        <div className="col-md-12 d-flex justify-space">
                          <div className="col-md-6 w-50">
                            <label
                              className="form-label fw-bold ms-2"
                              style={{ fontSize: "large" }}
                            >
                              Price Label:
                            </label>
                          </div>
                          <div className="col-md-6 w-50">
                            <label
                              className="form-label fw-bold ms-2"
                              style={{ fontSize: "large" }}
                            >
                              Price Value:
                            </label>
                          </div>
                        </div>
                        <div className="col-md-12 d-flex">
                          <div className="col-md-6 ms-1">
                            {data.map((item) => (
                              <div className="col-md-12" key={item.id}>
                                {/* <label className="fromdate">Price Label</label> */}
                                <br />
                                <input
                                  className="form-control me-2"
                                  type="text"
                                  name="price_level"
                                  value={item.price_level}
                                  onChange={(e) =>
                                    handleInputChange(e, item.id)
                                  }
                                  maxLength={9}
                                />
                              </div>
                            ))}
                          </div>
                          <div className="col-md-6 ms-2">
                            {data.map((item) => (
                              <div className="col-md-12" key={item.id}>
                                {/* <label className="fromdate">Price Value</label> */}
                                <br />
                                <input
                                  className="form-control me-2"
                                  type="text"
                                  name="price_value"
                                  value={item.price_value}
                                  onChange={(e) =>
                                    handleInputChange(e, item.id)
                                  }
                                  maxLength={9}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                        {data.length > 0 && (
                          <div
                            className="col-md-12 text-center d-flex "
                            style={{ justifyContent: "center" }}
                          >
                            <div className="col-md-2 mt-2">
                              <button
                                className="btn with-f-btn mt-lg-4 mt-2 "
                                onClick={() => handleButtonClick()}
                              >
                                Update
                              </button>
                            </div>
                          </div>
                        )}

                        <br />
                      </from>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <RightSidebar />
      </div>
    </div>
  );
}

export default Button_value;
