import { useEffect, useState } from "react";

import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import DOMPurify from "dompurify";
import { useNavigate } from "react-router-dom";
import { API } from "../../API/API";
import { logoutUser } from "../../Redux/Slices/authSlice";
import { encryptData } from "../encryption";
// import { fetchuserBalance } from "../../Redux/Slices/userBalanceSlice";

const K3Modal = ({
  open,
  data,
  onClose,
  setHistoryBoolean,
  historyBoolean,
  title,
  currentValue,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [remainingTime, setRemainingTime] = useState(20);
  const [stakeValue, setStakeValue] = useState(0);
  const [selectedWallet, setSelectedWallet] = useState("PTSWallet");
  // const { userBalance } = useSelector((state) => state.userBalanceData);

  // const { userId, jwt_token } = useSelector((state) => state.Auth);
  const userId = sessionStorage.getItem("userData");
  const jwt_token = sessionStorage.getItem("token");
  // const { Net_Balance, Net_DepositeFund, exp_amount, wallet_amount } = dashdata;

  const { value, id, color, btn, number } = data;
  const ButtonArr = [
    {
      label: "10",
      value: "10",
    },
    {
      label: "50",
      value: "50",
    },
    {
      label: "100",
      value: "100",
    },
    {
      label: "200",
      value: "200",
    },
    {
      label: "500",
      value: "500",
    },
    {
      label: "1k",
      value: "1000",
    },
    {
      label: "2k",
      value: "2000",
    },
    {
      label: "3k",
      value: "3000",
    },
    {
      label: "5k",
      value: "5000",
    },
    {
      label: "10k",
      value: "10000",
    },
    {
      label: "30k",
      value: "30000",
    },
  ];

  const handleInputChange = (event) => {
    const sanitizedValue = DOMPurify.sanitize(event.target.value);
    const isValidInput = /^[0-9]*$/.test(sanitizedValue);
    if (isValidInput) {
      setStakeValue(sanitizedValue);
    }
  };

  const handleStakeChange = (value) => {
    setStakeValue(value);
  };

  const handleWalletChange = (event) => {
    setSelectedWallet(event.target.value);
  };

  const handleSaveChanges = async () => {
    // if (id) {
    try {
      if (stakeValue <= 0) {
        toast.error("Invalid Amount");
        return;
      }
      if (currentValue <= 5) {
        toast.error("Bet In Next Round");
        onClose(true);
        return;
      }
      // body :{
      //   uid
      //   number
      //   stake
      //   wallet_type
      //   gameid
      //   }
      let res = await API.post(
        "placebetK3",
        {
          uid: userId,
          number: value,
          stake: stakeValue,
          wallet_type: "Netwallet",
          gameid: 1,
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (res && res.data && res.data.data === "success") {
        onClose(false);
        toast.success("Bet Successfully Placed");
        setHistoryBoolean(!historyBoolean);
     
      } else {
        toast.error(res.data.data);
        onClose(false);
      }
    } catch (e) {
      console.log("Error While Fetch AvbetCancle_API", e);

      if (
        e.response &&
        e.response.data &&
        e.response.data.showableMessage ===
          "Please login to access this resource"
      ) {
        sessionStorage.clear();
        window.location.href = "/Login";
      }
    } finally {
      onClose();
      setStakeValue(0);
    }
    // }
  };

  useEffect(() => {
    if (remainingTime === 0) {
      onClose();
    }
  }, [remainingTime]);

  useEffect(() => {
    let timer;
    if (remainingTime > 0) {
      timer = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);
    }
    return () => {
      clearInterval(timer);
    };
  }, [remainingTime]);

  return (
    <>
      <div
        onClick={() => {
          onClose(false);
        }}
        className="color-modal-wrapper"
      ></div>
      <div className="color-modal-container card">
        <div className="model-h-dive  d-flex justify-content-between ">
          <div className="px-2 p-1"> {title} </div>
          <div className="px-2 p-1">Time Left {remainingTime}s</div>
        </div>

        <div className="innercontent  innercontentD">
          <div className="innerheading px-2 py-2 text-black">
            <div className="maindis">
              <h6 className="text-black">You Choose</h6>
              <h6 className="text-black">
                {" "}
                {btn == true ? color : value > 18 ? number : value}
              </h6>
            </div>
          </div>

          <div
            className="row mx-0 align-items-center"
            style={{ background: "#029c02" }}
          >
            <div className="col-3">
              <div className="paymettext1 text-white fw-bold py-3">
                Stake (INR)
              </div>
            </div>
            <div className="col-9 ">
              <input
                type="text"
                value={stakeValue}
                onChange={handleInputChange}
                style={{ width: "70%" }}
                maxLength={7}
              />
            </div>

            <div className="col-3">
              <div className="paymettext1 text-white fw-bold py-3">
                Wallet Type
              </div>
            </div>
            <div className="col-9 ">
              <input
                type="radio"
                id="PTSWallet"
                value="PTSWallet"
                checked={selectedWallet === "PTSWallet"}
                onChange={handleWalletChange}
              />
              &nbsp;
              <label className="labels" htmlFor="PTSWallet">
                PTS Wallet
              </label>
            </div>
            <div className="col-12 px-0">
              {/* <ul className="modallist d-flex align-items-center  ps-0  py-3">
                {ButtonArr.map((btn) => (
                  <li
                    className={`border rounded-2 px-2 py-2 text-white  ${
                      stakeValue === btn.value && "is-activated"
                    } `}
                    key={btn.label}
                    value={btn.value}
                    onClick={(e) => handleStakeChange(e.target.value)}
                  >
                    {btn.label}
                  </li>
                ))}
              </ul> */}

              <div className="modallist d-flex  ps-0 py-2">
                {ButtonArr.map((btn) => (
                  <button
                    className={`num-box d-flex justify-content-center align-items-center ${
                      stakeValue === btn.value && "is-activated"
                    } `}
                    key={btn.label}
                    value={btn.value}
                    onClick={(e) => handleStakeChange(e.target.value)}
                  >
                    {btn.label}
                  </button>
                ))}
              </div>
            </div>
          </div>
          <div
            className="d-flex justify-content-start modallist mt-1 "
            style={{ background: "#029c02" }}
          >
            <button
              onClick={() => {
                onClose(false);
              }}
              className="red-button grebtn1"
              style={{ marginLeft: "30px" }}
            >
              Close
            </button>
            <button
              className="grebtn bte"
              style={{ marginRight: "60px" }}
              onClick={() => handleSaveChanges()}
            >
              Place Bets
            </button>
          </div>
        </div>
        {/* </div> */}
      </div>
    </>
  );
};
export default K3Modal;
