import React, { memo, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { saveTeams } from "../../Redux/Slices/SaveTeamsSlice";
import OddsTable2 from "./Table/OddsTable2";
import { fetchbetStackData } from "../../Redux/api/betStackAPI";

const BookMaker = ({
  data,
  handleModal,
  setmarketType,
  setbetType,
  setbetDetails,
  setRealtimeData,
  marketType,
  betType,
  realtimeData,
}) => {
  // console.log("Bookmaker",data)
  const dispatch = useDispatch();
  const token = sessionStorage.getItem("token");
  const uid = sessionStorage.getItem("userData");
  const { id } = useSelector((state) => state.auth.currentMatch);
  const { bookStack } = useSelector((state) => state.betStack);

  const [dataValue, setDataValue] = useState("");

  const [count, setCount] = useState(0);

  const SaveTeams_API = async (mname, team, sid) => {
    dispatch(saveTeams({ id, mname, team, token, sid }));
  };

  useEffect(() => {
    if (data && data?.oddDatas && data?.oddDatas.length > 0 && count < 1) {
      data?.oddDatas?.forEach((runner) => {
        SaveTeams_API("Bookmaker Market", runner.rname, runner.sid);
      });
      setCount(count + 1);
    }
  }, [data]);

  const checkValid = () => {
    if (realtimeData && marketType === "Bookmaker Market") {
      const filteredData = data.oddDatas?.filter(
        (item) => item.rname == dataValue?.team && item.sid == dataValue?.selId
      );

      if (betType === "back") {
        if (
          filteredData[0].sid == dataValue?.selId &&
          filteredData[0].status != "SUSPENDED" &&
          filteredData[0]?.b1 == dataValue?.valueOf &&
          filteredData[0]?.rname == dataValue?.team
        ) {
          return;
        } else {
          setRealtimeData(false);
          setDataValue({});
        }
      } else if (betType === "lay") {
        if (
          filteredData[0].sid == dataValue?.selId &&
          filteredData[0].status != "SUSPENDED" &&
          filteredData[0]?.l1 == dataValue?.valueOf &&
          filteredData[0]?.rname == dataValue?.team
        ) {
          return;
        } else {
          setRealtimeData(false);
          setDataValue({});
        }
      }
    }
  };

  useEffect(() => {
    checkValid();
  }, [realtimeData, dataValue, data]);

  useEffect(() => {
    dispatch(
      fetchbetStackData({
        userId: uid,
        mId: id,
        matchType: "Bookmaker Market",
        token,
      })
    );
  }, [dispatch, id]);

  return (
    <>
      {data && data?.oddDatas.length > 0 && (
        <div className="row  bg-dark ">
          <div className="Match-ods-h d-flex align-items-center">
            <p className="mx-2 mt-3 d-flex">
              BOOKMAKER FAST BET(0% COMMISION){" "}
              <div className="green-live-dot ms-2 mt-2"></div>
            </p>
          </div>
          <div className="row back-lay-row  bg-dark ">
            <div className="col-7 "></div>
            <div className="col-5 d-flex">
              <button className="back ">Back</button>
              <button className="lay ms-1">Lay</button>
            </div>
          </div>
          <div className="betting-team-info">
            {data &&
              data?.oddDatas?.length > 0 &&
              data?.oddDatas?.map((item, index) => (
                <OddsTable2
                  key={index}
                  data={item}
                  matchType="Bookmaker Market"
                  mid={data.mid}
                  handleModal={handleModal}
                  setbetType={setbetType}
                  setmarketType={setmarketType}
                  setbetDetails={setbetDetails}
                  setRealtimeData={setRealtimeData}
                  setDataValue={setDataValue}
                  Teamexp={bookStack?.filter((ele) => ele.team == item?.rname)}
                />
              ))}
          </div>
        </div>
      )}
    </>
  );
};


export default memo(BookMaker);
