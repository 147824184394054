import { useState } from "react";
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const Table = ({
  setShowModal,
  req,
  betDetails,
  setbetDetails,
  period,
  index,
  setRealTimeData,
}) => {
  //  console.log("betdetails===>",index)
  const dispatch = useDispatch();
  const { id } = useParams();
  const handleModal = (value, no) => {
    setbetDetails({
      ...betDetails,
      gameid: id,
      multipler: value,
      period: 0,
      nation: req.nation,
      number: no,
    });
    setShowModal(true);
    setRealTimeData(true);
  };

  const { thirtyTwoCardStack } = useSelector(
    (state) => state.thirtyTwoCardGameStack
  );

  return (
    <div className="card-table-inner-row row">
      <div className="col-5 d-flex align-items-center ps-2">
        {req?.nation || req?.nat}

        {/* <span className="success-o ms-1"> 0</span> */}
        {thirtyTwoCardStack.map(
          (ele) =>
            ele.number === index + 1 && (
              <span
                className={`ms-3  ${
                  ele.number == index + 1 && ele.amount >= 0
                    ? "success-1"
                    : "zero"
                }`}
              >
                {ele.number == index + 1 && ele.amount}
              </span>
            )
        )}
      </div>
      {req.gstatus === "SUSPENDED" || req.gstatus === "CLOSED" ? (
        <>
          <div className="col-3 d-flex align-items-center justify-content-center px-2">
            <button className="back-btn">{req.gstatus}</button>
          </div>
          <div className="col-3 d-flex align-items-center justify-content-center ">
            <button className="lay-btn"> {req.gstatus}</button>
          </div>
        </>
      ) : (
        <>
          <div className="col-3 d-flex align-items-center justify-content-center px-2">
            <button
              onClick={() => {
                handleModal(req.b1, index + 1);
              }}
              className="back-btn"
            >
              {req.b1}
            </button>
          </div>
          <div className="col-3 d-flex align-items-center justify-content-center ">
            <button
              className="lay-btn"
              onClick={() => {
                handleModal(req.l1, index + 5);
              }}
            >
              {req.l1}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default memo(Table);
