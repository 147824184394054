import React, { lazy, useEffect, useState } from "react";
// import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "./Components/Navbar/Navbar";
import Sidebar from "./Components/Sidebar/Sidebar";
import InternetStatusChecker from "./Components/InternetStatusChecker";
import "./App.css";

import Home from "./Components/Home/Home";
import "react-toastify/dist/ReactToastify.css";
import Sport from "./Components/SportNew/Sport";
import WalletSignIn from "./Components/WalletSignin/WalletSignIn";
import MyMarkeet from "./Components/MyMarkeet/MyMarkeet";
import BettingHistory from "./Components/BettingHistory/BettingHistory";
import FundsTransfer from "./Components/FundsTransfer/FundsTransfer";
import WithdrawHistory from "./Components/WithdrawHistory/WithdrawHistory";
import GameWithdrawHistory from "./Components/WithdrawHistory/GameWithdrawHistory";
import Login from "./Components/Login/Login";
import AdminLogin from "./Components/Login/AdminLogin";
// import WithDrawalUSDT from "./Components/WithdrawelUSDT/WithDrawalUSDT";
import {
  Routes,
  Route,
  BrowserRouter,
  useLocation,
  useNavigate,
} from "react-router-dom";
import WithDrawalUSDT from "./Components/WithdrawelUSDT/WithDrawalUSDT";
import AccountStatement from "./Components/AccountStatement/AccountStatement";
import TransactionHistory from "./Components/TransactionHistory/TransactionHistory";
import ProfitLoss from "./Components/ProfitLoss/ProfitLoss";
import CasinoResults from "./Components/CasinoResults/CasinoResults";
import LiveCasino from "./Components/LiveCasino/LiveCasino";
import Deposit from "./Components/Deposit/Deposit";
import ActivationDeposit from "./Components/Deposit/ActivationDeposit";
import InPlay from "./Components/InPlay/InPlay";
import ChangePassword from "./Components/ChangePassword/ChangePassword";
import ColorGame from "./Components/ColorGame/ColorGame";
import Aviator from "./Components/AviatorNew/Aviator";
import TeenPatti from "./Components/TeenPatti/TeenPatti";
import ThirtyTwoCards from "./Components/32Cards/ThirtyTwoCards";
import CardsB from "./Components/32cardsB/CardsB";
import DragonTiger from "./Components/DragonTiger/DragonTiger";
import Lucky from "./Components/Lucky7A/Lucky";
import Footer from "./Components/Footer/Footer";
import { Provider, useDispatch } from "react-redux";
import { store } from "./Redux/Store/store";
import { ToastContainer, toast } from "react-toastify";
import Withdrawal from "./Components/WithdrawelUSDT/WithDrawal";
import WithDrawalGame from "./Components/WithdrawelUSDT/WithDrawalGame";
import DepositHistory from "./Components/Deposit History/DepositHistory";
import Depositpayment from "./Components/Deposit History/Depositpayment";
import ActDepositHistory from "./Components/Deposit History/ActDepositHistory";
import PrivateRoutes from "./Utility/PrivateRoutes";
import Profile from "./Components/Profile/Profile";
import Iframe from "./Components/Iframe/Iframe";
import DragonTiger2 from "./Components/DragonTiger/DragonTiger2";
// import Andarbahar from "./Components/Andarbahar/Andarbahar";
import AviatorIframe from "./Components/Iframe/AviatorIframe";
import Component from "./Components/Iframe/ProxyIframe";
import AviatorBetHistory from "./Components/Aviator History/AviatorBetHistory";
import AviatorResult from "./Components/Aviator History/AviatorResult";
import LokSabha from "./Components/LokSabha/LokSabha";
import Ipl2024 from "./Components/Ipl2024/Ipl2024";
import Roulette from "./Components/Roulette/Roulette";
import Button_value from "./Components/ButtonValue/Button_value";
import FundTransferHistory from "./Components/FundsTransfer/FundTransferHistory";
import FootballSports from "./Components/Sports Football/FootballSports";
import TennisSport from "./Components/Sport Tennis/TennisSport";
import Registration from "./Components/Registration/Registration";
import WelcomePage from "./Components/Registration/WelcomePage";
import DirectDepositIncome from "./Components/IncomeReports/DirectDepositIncome";
import DirectBettingIncome from "./Components/IncomeReports/DirectBettingIncome";
import HoldingBonus from "./Components/IncomeReports/HoldingBonus";
import RankSalaryIncome from "./Components/IncomeReports/RankSalaryIncome";
import RecruiterBonus from "./Components/IncomeReports/RecruiterBonus";
import LossTradeBonus from "./Components/IncomeReports/LossTradeBonus";
import DirectTeam from "./Components/TeamReports/DirectTeam";
import LevelDetails from "./Components/TeamReports/LevelDetails";
import MyDownline from "./Components/TeamReports/MyDownline";
import WithdrawalFundConvert from "./Components/WithdrawalFundConvert/WithdrawalFundConvert";
import FundConvertHistory from "./Components/WithdrawalFundConvert/FundConvertHistory";
import GameWalletRecive from "./Components/WithdrawalFundConvert/GameWalletRecive";
import ConvertedBonus from "./Components/IncomeReports/ConvertedBonus";
import Mlmdashboard from "./Components/MLM Dashboard/Mlmdashboard";
import WinningAmount from "./Components/IncomeReports/WinningAmount";
import FrenFundConvert from "./Components/FranchiseeFundConvert/FrenFundConvert";
import FrenFundConvertHistory from "./Components/FranchiseeFundConvert/FrenFundConvertHistory";
import TeenPatti1day from "./Components/TeenPatti/TeenPatti1day";
import TeenPattiOpen from "./Components/TeenPatti/TeenPattiOpen";
import Lucky7B from "./Components/Lucky7A/Lucky7B";
import Casinowar from "./Components/Casinoqueen/Casinowar";
import Bollywoodcasino from "./Components/Casinoqueen/Bollywoodcasino";
import BallbyBall from "./Components/Ball By Ball/BallbyBall";
import SuperOver from "./Components/Super Over/SuperOver";
import FiveCricket from "./Components/Five Cricket/FiveCricket";
import TwentyDTL from "./Components/DTL 20-20/TwentyDTL";
import TestTeenpatti from "./Components/TeenPatti/TestTeenpatti";
import RaceTwenty from "./Components/Race20-20/RaceTwenty";
import OneDPoker from "./Components/Poker/OneDPoker";
import Baccarat1 from "./Components/Baccarat/Baccarat1";
import Baccarat2 from "./Components/Baccarat/Baccarat2";
import PokerTwenty from "./Components/Poker/PokerTwenty";
import AndarbaharNew from "./Components/Andarbahar/AndarbaharNew";
import PokerSix from "./Components/Poker/PokerSix";
import OneDayDragonTiger from "./Components/OneDayDragonTiger/OneDayDragonTiger";
import Card32B from "./Components/32cardsB/Card32B";
import InstantWorli from "./Components/Instant Worli/InstantWorli";
import AmarAkbar from "./Components/Amar Akbar Antho/AmarAkbar";
import FrenWalletRecive from "./Components/FranchiseeFundConvert/FrenWalletRecive";
import BankDetails from "./Components/BankDetails/BankDetails";
import DifferentialIncome from "./Components/IncomeReports/DifferentialIncome";
import WithdrawalStatement from "./Components/AccountStatement/WithdrawalStatement";
import MatchingBonus from "./Components/IncomeReports/MatchingBonus";
import BinaryTree from "./Components/BinaryTree/BinaryTree";
import LevelIncome from "./Components/IncomeReports/LevelIncome";
import { logoutUser, setDevToolsOpen } from "./Redux/Slices/authSlice";
import ForgetPassword from "./Components/ForgetPassword/ForgetPassword";
import DepositPaymentHistory from "./Components/Deposit History/DepositPaymentHistory";
import Pagenotfound from "./Components/Not Found/Pagenotfound";
import ColorGameHistory from "./Components/ColorGame/ColorGameHistory";
import DepositGateway from "./Components/Deposit History/DepositGateway";
import DepositGatewayHistory from "./Components/Deposit History/DepositGatewayHistory";
import TRXGame from "./Components/TRX/TRXGame";
import K3Game from "./Components/K3Game/K3Game";

const Andarbahar = lazy(() => import("./Components/Andarbahar/Andarbahar"));

const App = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [locationSidebar, setlocationSidebar] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };
  const handleLinkClick = () => {
    // Close the sidebar when a link is clicked
    setShowSidebar(false);
  };

  useEffect(() => {
    const location = window.location.pathname;

    setlocationSidebar(!location.includes("iframe"));
  }, []);

  // useEffect(() => {
  //   const handleContextMenu = (event) => {
  //     event.preventDefault();
  //   };

  //   const handleStorageChange = () => {
  //     dispatch(setDevToolsOpen(true));
  //     dispatch(logoutUser());
  //     navigate("/login");
  //   };
  //   const handleResize = () => {
  //     if (
  //       window.outerWidth - window.innerWidth > 100 ||
  //       window.outerHeight - window.innerHeight > 100
  //     ) {
  //       toast.error("PLease Close Developer Tools");
  //       dispatch(setDevToolsOpen(true));
  //       dispatch(logoutUser());
  //       navigate("/login");
  //     } else {
  //       dispatch(setDevToolsOpen(false));
  //     }
  //   };

  //   window.addEventListener("contextmenu", handleContextMenu);
  //   window.addEventListener("resize", handleResize);
  //   window.addEventListener("storage", handleStorageChange);

  //   return () => {
  //     window.removeEventListener("contextmenu", handleContextMenu);
  //     window.removeEventListener("resize", handleResize);
  //     window.removeEventListener("storage", handleStorageChange);
  //   };
  // }, [dispatch, navigate]);

  // document.addEventListener("keydown", (e) => {
  //   if (
  //     (e.ctrlKey &&
  //       (e.key === "I" ||
  //         e.key === "i" ||
  //         e.key === "u" ||
  //         e.key === "U" ||
  //         e.key === "J" ||
  //         e.key === "j" ||
  //         e.key === "C" ||
  //         e.key === "c")) ||
  //     e.key === "F12"
  //   ) {
  //     e.preventDefault();
  //   }
  // });

  return (
    <div className="bg-black position-relative row app-row overflow-hidden">
      <InternetStatusChecker />
      <ToastContainer />
      {locationSidebar && (
        <>
          <Navbar toggleSidebar={toggleSidebar} showSidebar={showSidebar} />

          <Sidebar
            handleLinkClick={handleLinkClick}
            showSidebar={showSidebar}
          />
          {/* <Footer /> */}
        </>
      )}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/AdminLogin" element={<AdminLogin />} />
        <Route path="/ForgetPassword" element={<ForgetPassword />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/WalletSignIn" element={<WalletSignIn />} />
        <Route path="/iframe/:Id" element={<Iframe />} />
        <Route path="/AViframe/:Url" element={<AviatorIframe />} />
        <Route path="/Component/:id" component={Component} />
        <Route path="/welcomepage/:uid" element={<WelcomePage />} />
        <Route element={<PrivateRoutes />}>
          <Route path="/InPlay" element={<InPlay />} />
          <Route path="/Sport/:id" element={<Sport />} />
          <Route path="/Football/:id" element={<FootballSports />} />
          <Route path="/Tennis/:id" element={<TennisSport />} />
          <Route path="/MyMarkeet" element={<MyMarkeet />} />
          <Route path="/BettingHistory" element={<BettingHistory />} />
          <Route path="/FundsTransfer" element={<FundsTransfer />} />
          <Route
            path="/FundsTransferHistory"
            element={<FundTransferHistory />}
          />
          <Route path="/WithdrawalUSDT" element={<Withdrawal />} />
          <Route path="/WithDrawalGame" element={<WithDrawalGame />} />
          <Route path="/WithdrawalHistory" element={<WithdrawHistory />} />
          <Route
            path="/GameWithdrawHistory"
            element={<GameWithdrawHistory />}
          />
          <Route path="/AccountStatement" element={<AccountStatement />} />
          <Route
            path="/WithdrawalStatement"
            element={<WithdrawalStatement />}
          />
          <Route path="/TransactionHistory" element={<TransactionHistory />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/Ipl2024" element={<Ipl2024 />} />
          <Route path="/ProfitLoss" element={<ProfitLoss />} />
          <Route path="/CasinoResults" element={<CasinoResults />} />
          <Route path="/GameDeposit" element={<Deposit />} />
          <Route path="/GameDepositHistory" element={<DepositHistory />} />
          <Route path="/DepositPayment" element={<Depositpayment />} />
          <Route
            path="/DepositPaymentHistory"
            element={<DepositPaymentHistory />}
          />
          <Route path="/Activation" element={<ActivationDeposit />} />
          <Route path="/ActivationHistory" element={<ActDepositHistory />} />
          <Route path="/ChangePassword" element={<ChangePassword />} />
          <Route path="/LiveCasino" element={<LiveCasino />} />
          <Route path="/Roulette" element={<Roulette />} />
          <Route path="/ColorGame" element={<ColorGame />} />
          <Route path="/TRXGame" element={<TRXGame />} />
          <Route path="/K3Game" element={<K3Game />} />
          <Route path="/Aviator" element={<Aviator />} />
          <Route path="/AviatorbetHistory" element={<AviatorBetHistory />} />
          <Route path="/AviatorResult" element={<AviatorResult />} />
          <Route path="/TeenPatti/:id" element={<ThirtyTwoCards />} />
          <Route path="/32Cards/:id" element={<TeenPatti />} />
          {/* <Route path="/32CardsB" element={<CardsB />} /> */}
          <Route path="/DragonTiger/:id" element={<DragonTiger2 />} />
          <Route path="/DragonTiger2/:id" element={<DragonTiger />} />
          <Route path="/Andar_Bahar/:id" element={<Andarbahar />} />
          <Route path="/Lucky7A/:id" element={<Lucky />} />
          <Route path="/LokSabha" element={<LokSabha />} />
          <Route path="/ButtonValue" element={<Button_value />} />
          <Route path="/Lucky7B/:id" element={<Lucky7B />} />
          <Route path="/teenpattiOneday/:id" element={<TeenPatti1day />} />
          <Route path="/teenpattiOpen/:id" element={<TeenPattiOpen />} />
          <Route path="/teenpattitest/:id" element={<TestTeenpatti />} />
          <Route path="/Casinowar/:id" element={<Casinowar />} />
          <Route path="/Bollywoodcasino/:id" element={<Bollywoodcasino />} />
          <Route path="/ballbyball/:id" element={<BallbyBall />} />
          <Route path="/ssuperover/:id" element={<SuperOver />} />
          <Route path="/fivecricket/:id" element={<FiveCricket />} />
          <Route path="/twentyDTL/:id" element={<TwentyDTL />} />
          <Route path="/race20/:id" element={<RaceTwenty />} />
          <Route path="/oneDpoker/:id" element={<OneDPoker />} />
          <Route path="/twentypoker/:id" element={<PokerTwenty />} />
          <Route path="/sixpoker/:id" element={<PokerSix />} />
          <Route path="/baccarat1/:id" element={<Baccarat1 />} />
          <Route path="/baccarat2/:id" element={<Baccarat2 />} />
          <Route path="/OneDayDTiger/:id" element={<OneDayDragonTiger />} />
          <Route path="/andarbaharnew/:id" element={<AndarbaharNew />} />
          <Route path="/card32B/:id" element={<Card32B />} />
          <Route path="/amarakbarant/:id" element={<AmarAkbar />} />
          <Route path="/instantworli/:id" element={<InstantWorli />} />

          <Route
            path="/DirectDepositIncome"
            element={<DirectDepositIncome />}
          />
          <Route
            path="/DirectBettingIncome"
            element={<DirectBettingIncome />}
          />
          <Route path="/RoiBonus" element={<HoldingBonus />} />
          <Route path="/CTOIncome" element={<RankSalaryIncome />} />
          <Route path="/RoyaltyBonus" element={<RecruiterBonus />} />
          <Route path="/LossTradeBonus" element={<LossTradeBonus />} />
          <Route path="/DirectTeam" element={<DirectTeam />} />
          <Route path="/LevelDetails" element={<LevelDetails />} />
          <Route path="/MyDownline" element={<MyDownline />} />
          <Route
            path="/WithdrawalFundConvert"
            element={<WithdrawalFundConvert />}
          />
          <Route path="/gamewalletreceive" element={<GameWalletRecive />} />
          <Route path="/FundConvertHistory" element={<FundConvertHistory />} />
          <Route path="/ConvertedBonus" element={<ConvertedBonus />} />
          <Route path="/mlmdashboard" element={<Mlmdashboard />} />
          <Route path="/WinningAmount" element={<WinningAmount />} />
          <Route path="/LevelIncome" element={<LevelIncome />} />

          <Route path="/FrenFundConvert" element={<FrenFundConvert />} />
          <Route
            path="/FrenFundConvertHistory"
            element={<FrenFundConvertHistory />}
          />
          <Route path="/FrenWalletRecive" element={<FrenWalletRecive />} />
          <Route path="/WithdrawalShare" element={<DifferentialIncome />} />
          <Route path="/BankDetails" element={<BankDetails />} />
          <Route path="/MatchingBonus" element={<MatchingBonus />} />
          <Route path="/BinaryTree" element={<BinaryTree />} />
          <Route path="/ColorGameHistory" element={<ColorGameHistory />} />
          <Route path="/DepositGateway" element={<DepositGateway />} />
          <Route
            path="/DepositGatewayHistory"
            element={<DepositGatewayHistory />}
          />
        </Route>
        <Route exact path="*" element={<Pagenotfound />} />
      </Routes>
    </div>
  );
};

export default App;
