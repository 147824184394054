import React, { useEffect, useState } from "react";
import RightSidebar from "../RightSidebar/RIghtSidebar";
import "./TRXGame.css";
import { FaRegPlusSquare } from "react-icons/fa";
import { FaRegMinusSquare } from "react-icons/fa";
import io from "socket.io-client";
import TRXModal from "./TRXModal";
import { useDispatch } from "react-redux";
import { API } from "../../API/API";
import { logoutUser } from "../../Redux/Slices/authSlice";
import { useNavigate } from "react-router-dom";
import TRXTimeDisplay from "./TRXTimeDisplay";
import question from "../../Media/iconQuestion.png";

const TRXGame = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId = sessionStorage.getItem("userData");
  const jwt_token = sessionStorage.getItem("token");
  // const { userId, jwt_token } = useSelector((state) => state.Auth);
  const [Getperiod, setGetperiod] = useState("");

  const [toggle, setToggle] = useState(false);
  const [modal, setModal] = useState();
  const [Betdata, setBetData] = useState(null);
  const [roundDetails, setRoundDetails] = useState({});
  const [historyData, setHistoryData] = useState([]);
  const [historyDataAdmin, sethistoryDataAdmin] = useState([]);
  const [loader, setloader] = useState(false);
  const [historyBoolean, setHistoryBoolean] = useState(false);
  const [currentValueT, setCurrentValueT] = useState(0);

  const numberButton = [
    {
      className: "violet-btn voilet",
      value: 0,
      label: "0",
      color: "Violet",
      btn: false,
    },
    {
      className: "small-green-btn",
      value: 1,
      label: "1",
      color: "Green",
      btn: false,
    },
    {
      className: "small-red-btn",
      value: 2,
      label: "2",
      color: "Red",
      btn: false,
    },
    {
      className: "small-green-btn",
      value: 3,
      label: "3",
      color: "Green",
      btn: false,
    },
    {
      className: "small-red-btn",
      value: 4,
      label: "4",
      color: "Red",
      btn: false,
    },
    {
      className: "violet-btn vt",
      value: 5,
      label: "5",
      color: "Violet",
      btn: false,
    },
    {
      className: "small-red-btn",
      value: 6,
      label: "6",
      color: "Red",
      btn: false,
    },
    {
      className: "small-green-btn",
      value: 7,
      label: "7",
      color: "Green",
      btn: false,
    },
    {
      className: "small-red-btn",
      value: 8,
      label: "8",
      color: "Red",
      btn: false,
    },
    {
      className: "small-green-btn",
      value: 9,
      label: "9",
      color: "Green",
      btn: false,
    },
  ];

  const colorButton = [
    {
      className: "Green-button",
      color: "GREEN",
      label: "GREEN",
      value: 10,
      btn: true,
    },
    {
      className: "violet-button ",
      color: "VIOLET",
      label: "VIOLET",
      value: 20,
      btn: true,
    },
    {
      className: "red-button",
      color: "RED",
      label: "RED",
      value: 30,
      btn: true,
    },
  ];

  const bigSmallButton = [
    {
      className: "btas",
      color: "Big",
      label: "Big",
      value: 40,
      btn: true,
    },
    {
      className: "btas1",
      color: "Small",
      label: "Small",
      value: 50,
      btn: true,
    },
  ];

  useEffect(() => {
    if (currentValueT == 0) {
      setloader(true);
    } else {
      setloader(false);
    }
  }, [currentValueT]);

  const ColorResult_API = async () => {
    try {
      // alert(userId);
      let responseHistory = await API.post(
        "getColorbetHistory_TRX",
        {
          uid: userId,
          LoginId: userId,
          GameType: 1,
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setHistoryData(responseHistory?.data?.data[0] || []);
      console.log("historyUser", responseHistory?.data?.data[0]);
      let responseHistoryAdmin = await API.post(
        "getColorbetHistory_TRX",
        {
          uid: userId,
          LoginId: "0",
          GameType: 1,
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("historyAdmin", responseHistoryAdmin?.data?.data[0]);

      sethistoryDataAdmin(responseHistoryAdmin.data.data[0]);
      const gamesno = responseHistoryAdmin?.data?.data[0][0].gamesno;
      // setCurrentPeriodpop(gamesno);

      const incrementedGamesno = (parseInt(gamesno, 10) + 1).toString();
      console.log("incrementedGamesno", incrementedGamesno);
      setGetperiod(incrementedGamesno);
      // dispatch(fetchuserBalance({ id: userId, token: jwt_token }));
    } catch (e) {
      console.log("Error While Fetch AvbetCancle_API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
      // console.log("Error While calling userinfomyReferrals API", e);
      // if(e.status==401){
      //   dispatch(logoutUser())
      //   sessionStorage.clear()
      // }
    }
  };

  useEffect(() => {
    ColorResult_API();
  }, [historyBoolean, loader]);

  // const SOCKET_URL = "https://dreamwin-socket.nakshtech.info/";

  // useEffect(() => {
  //   const cricketMatchesSocket = io(SOCKET_URL);
  //   cricketMatchesSocket.emit("GetInsertRound_TRX");

  //   cricketMatchesSocket.on("GetInsertRound_TRX_FromAPI", (data) => {
  //     // console.log("colorPlay", data[0]);

  //     if (data[0]) {
  //       setRoundDetails(data[0]);
  //     }

  //     const startRoundTime = new Date(data[0].startround).getTime();
  //     const endRoundTime = new Date(data[0].endround).getTime();
  //     const currentTime = new Date(data[0].currentDate).getTime();
  //     // console.log("Current Round ", currentTime);

  //     const timeDifferenceInSeconds = Math.floor(
  //       (endRoundTime - currentTime) / 1000
  //     );
  //     // console.log("diff", timeDifferenceInSeconds);

  //     const remainingCount1 = Math.max(0, timeDifferenceInSeconds);

  //     setcurrentValueT(remainingCount1);
  //     // console.log("remainingCount1", remainingCount1);
  //     if (remainingCount1 === 0) {
  //       setloader(true);
  //     } else {
  //       setloader(false);
  //     }
  //   });

  //   return () => {
  //     cricketMatchesSocket.disconnect();
  //   };
  // }, []);

  function handleBetButton(btn) {
    if (currentValueT > 10 && Getperiod) {
      // console.log("btn", btn, roundDetails);
      let newData = { Getperiod, ...btn };
      // console.log("round", newData);
      setBetData(newData);
      setModal(true);
    }
  }

  const handleBetModalClose = () => {
    setModal(false);
    setBetData(null);
  };

  return (
    <>
      {/* <Navbar toggleSidebar={toggleSidebar} showSidebar={showSidebar} />

      <Sidebar handleLinkClick={handleLinkClick} showSidebar={showSidebar} />
      <Footer /> */}
      <div className="col-lg-10 home">
        <div className="row home">
          <div className="col-lg-8 p-2 center-div">
            <div className="color-game-div pt-3 p-2">
              <div className="color-game-h d-flex justify-content-between  ">
                <div className=" px-2">
                  <p className=" mt-1 ">TRX</p>
                </div>
                <div>
                  <p className="mt-1 px-2">
                    Round ID:
                    {Getperiod ? Getperiod : 0} | Left Time: {currentValueT} |
                    Rules
                  </p>
                </div>
              </div>

              <div className="card  color-game-card">
                {loader && (
                  <div className="loader-container">
                    <div className="loader"></div>
                    <div className="loading-text">Loading...</div>
                  </div>
                )}
                <div className="row mt-4">
                  {colorButton.map((btn, index) => (
                    <div className="col-4" key={index}>
                      <button
                        className={btn.className}
                        disabled={currentValueT > 10 ? false : true}
                        onClick={() => handleBetButton(btn)}
                        readOnly
                      >
                        {btn.label}
                      </button>
                    </div>
                  ))}
                </div>
                <div className="row mt-4">
                  <div className="col-md-2"></div>
                  <div className="col-md-8 d-flex justify-content-between">
                    {numberButton.slice(0, 5).map((btn, index) => (
                      <div
                        className={`${btn.className} d-flex justify-content-center align-items-center`}
                        value={btn.value}
                        disabled={currentValueT > 10 ? false : true}
                        onClick={() => handleBetButton(btn)}
                        key={index}
                        readOnly
                      >
                        {btn.label}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-2"></div>
                  <div className="col-md-8 d-flex justify-content-between">
                    {numberButton.slice(5, 10).map((btn, index) => (
                      <div
                        className={`${btn.className} d-flex justify-content-center align-items-center`}
                        value={btn.value}
                        disabled={currentValueT > 10 ? false : true}
                        onClick={() => handleBetButton(btn)}
                        key={index}
                      >
                        {btn.label}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="row mt-3">
                  {" "}
                  {bigSmallButton.map((btn, index) => (
                    <div className="col-6" key={index}>
                      <button
                        className={btn.className}
                        disabled={currentValueT > 10 ? false : true}
                        onClick={() => handleBetButton(btn)}
                        readOnly
                      >
                        {btn.label}
                      </button>
                    </div>
                  ))}
                  <div className="container">
                    <div
                      className="timer_container position-absolute"
                      style={{ right: "10px", bottom: "15px" }}
                    >
                      {/* <button
                        class="btn btn-secondary"
                        style={{ cursor: "default", background: "#6f42c1" }}
                      >
                        <TRXTimeDisplay
                          setCurrentValueT={setCurrentValueT}
                          currentValueT={currentValueT}
                          ColorResult_API={ColorResult_API}
                        />
                      </button>{" "} */}
                    </div>
                  </div>
                </div>
              </div>

              {/* <-------------------------------Previous-ROund-Results-table----------> */}
              <div className="heighted">
                <div className="color-game-h mt-2">
                  <div className="px-2 pt-1 text-center">
                    <p>Previous Rounds Results</p>
                  </div>
                </div>
                <div className="tablek3-h-div trxtable row table-responsive">
                  <table className="tableColor  bg-white">
                    <tr className="tablheader">
                      <th>Period</th>
                      <th>Block height</th>
                      <th>Block time</th>
                      <th>Hash value</th>

                      <th>Number</th>
                      <th>Result</th>
                      <th>B/M</th>
                    </tr>
                    <tbody>
                      {historyDataAdmin.length == 0 && (
                        <tr>
                          <td colSpan={7}>Data Not found</td>
                        </tr>
                      )}

                      {historyDataAdmin.map((item) => (
                        <>
                          <tr key={item?.BetID}>
                            <td className="align-items-center ps-2">
                              {item?.gamesno}
                            </td>
                            <td>
                              {" "}
                              <a
                                href={`https://tronscan.org/#/block/${item?.blockHeight}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  className="question-mark"
                                  src={question}
                                  alt="log"
                                />
                                <div className="block-height">
                                  {item?.blockHeight}
                                </div>
                              </a>
                            </td>
                            <td>{item?.CurrentTime}</td>
                            <td>{item?.Last5trnsHash}</td>
                            {item?.status != 0 && (
                              <>
                                <td className="align-items-center ps-2">
                                  {item?.number}
                                </td>
                                <td className="align-items-center ps-2">
                                  {item?.number != null &&
                                  item?.number !== "" ? (
                                    item?.number >= 0 && item?.number <= 9 ? (
                                      item?.number == "0" ? (
                                        <>
                                          <div className="ds">
                                            <span className="violet-dot"></span>
                                            <span className="red-dot"></span>
                                          </div>
                                        </>
                                      ) : item?.number == "5" ? (
                                        <>
                                          <div className="ds">
                                            <span className="violet-dot"></span>
                                            <span className="green-dot"></span>
                                          </div>
                                        </>
                                      ) : item?.number % 2 === 0 ? (
                                        <>
                                          <div className="ds">
                                            <span className="red-dot"></span>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div className="ds">
                                            <span className="green-dot"></span>
                                          </div>
                                        </>
                                      )
                                    ) : (
                                      <></>
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </td>
                              </>
                            )}
                            <td className="align-items-center ps-2">
                              {item?.NumberStatus}
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              {/* <<<<<<<<<<<<<<<<<<<<<<<-----------------------My-Bets-table-------------->>>>>>>>>> */}

              <div className="My-Bets-table">
                <div className="color-game-h mt-2">
                  <div className="px-2 pt-1 text-center">
                    <p>My Bets</p>
                  </div>
                </div>

                <div
                  className="tablek3-h-div trxtable row"
                  style={{ overflow: "auto" }}
                >
                  <table className="colbg tableColor table-responsive">
                    <tr className="tablheader">
                      <th>Period</th>
                      <th>Select</th>
                      <th>Result</th>
                      <th>Amount</th>
                      <th>Details</th>
                    </tr>
                    <tbody>
                      {historyData?.length == 0 && (
                        <tr>
                          <td colSpan={7}>Data Not found</td>
                        </tr>
                      )}
                      {historyData.map((item) => (
                        <>
                          <tr key={item?.BetID}>
                            <td className="align-items-center ps-2">
                              {item?.gamesno}
                            </td>
                            <td className="align-items-center ps-2">
                              {item?.ColorBet}
                            </td>
                            {item?.status == 0 && (
                              <>
                                <td className="align-items-center fail">
                                  Pending
                                </td>
                                <td className="align-items-center ps-2">
                                  {item?.stake < 100
                                    ? parseFloat(item?.stake).toFixed(2)
                                    : item?.stake < 1000
                                    ? parseFloat(item?.stake).toFixed(1)
                                    : `${(
                                        parseFloat(item?.stake) / 1000
                                      ).toFixed(2)}K`}
                                </td>
                              </>
                            )}
                            {item?.status != 0 && (
                              <>
                                <td
                                  className={`align-items-center ${
                                    item?.status == 1 ? "success" : "fail"
                                  }`}
                                >
                                  {item?.status == 1 ? "Win" : "Loss"}
                                </td>
                                <td
                                  className={`align-items-center ${
                                    item?.WIN > 0 ? "success" : "fail"
                                  }`}
                                >
                                  ₹
                                  {item?.WIN > 0
                                    ? Number(item?.odds).toFixed(2)
                                    : Number(item?.stake).toFixed(2)}
                                </td>
                              </>
                            )}
                            <td className="align-items-center ps-2">
                              <div
                                onClick={() => {
                                  toggle === item?.BetID
                                    ? setToggle(null)
                                    : setToggle(item?.BetID);
                                }}
                              >
                                {toggle === item?.BetID ? (
                                  <FaRegMinusSquare className="plus-icon" />
                                ) : (
                                  <FaRegPlusSquare className="plus-icon" />
                                )}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="5">
                              {toggle === item?.BetID && (
                                <>
                                  <table className="table-responsive">
                                    <tr>
                                      <th colSpan="2">Period Detail</th>
                                    </tr>
                                    <tr>
                                      <td>Period</td>
                                      <td>{item?.gamesno}</td>
                                    </tr>
                                    <tr>
                                      <td>Contract Money</td>
                                      <td>{item?.stake}</td>
                                    </tr>
                                    <tr>
                                      <td>Delivery</td>
                                      <td>{item?.odds}</td>
                                    </tr>
                                    <tr>
                                      <td>Fee</td>
                                      <td>{item?.Charge}</td>
                                    </tr>
                                    <tr>
                                      <td>Result</td>
                                      <td>
                                        {item?.status === 1
                                          ? "Win"
                                          : item?.status === 2
                                          ? "Loss"
                                          : ""}

                                        {item?.resultColor === "Green" ? (
                                          <div className="green-dot ms-2"></div>
                                        ) : item?.resultColor === "Red" ? (
                                          <div className="red-dot ms-2"></div>
                                        ) : item?.resultColor === "Violet" ? (
                                          <>
                                            {" "}
                                            <div className="violet-dot ms-2"></div>
                                          </>
                                        ) : item?.resultColor === "Violet" ? (
                                          <>
                                            {" "}
                                            <div className="violet-dot ms-2"></div>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Select</td>
                                      <td>{item?.ColorBet}</td>
                                    </tr>
                                    <tr>
                                      <td>Amount</td>
                                      <td
                                        className={`col-6 pt-1  ${
                                          item?.resultStatus == 0
                                            ? "fail"
                                            : "success"
                                        }`}
                                      >
                                        {item?.stake}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Create Time</td>
                                      <td>{item?.dd}</td>
                                    </tr>
                                  </table>
                                </>
                              )}
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {Getperiod && (
            <div className="d-none">
              <TRXTimeDisplay
                setCurrentValueT={setCurrentValueT}
                currentValueT={currentValueT}
                ColorResult_API={ColorResult_API}
              />
            </div>
          )}

          <RightSidebar />
          {modal && (
            <TRXModal
              historyBoolean={historyBoolean}
              setHistoryBoolean={setHistoryBoolean}
              open={setModal}
              data={Betdata}
              onClose={handleBetModalClose}
              currentValueT={currentValueT}
              Getperiod={Getperiod}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default TRXGame;
