import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";

function OddsTable({
  data,
  mid,
  matchType,
  handleModal,
  setbetType,
  setmarketType,
  setbetDetails,
  setRealtimeData,
  setDataValue,
  Teamexp,
  favourite,
}) {
  const { eventTypeid, id } = useSelector((state) => state.auth.currentMatch);

  const [prevB1, setPrevB1] = useState(data?.b1);
  const [prevL1, setPrevL1] = useState(data?.l1);
  const [b1Blink, setB1Blink] = useState(false);
  const [l1Blink, setL1Blink] = useState(false);

  useEffect(() => {
    if (data?.b1 !== prevB1) {
      setB1Blink(true);
      setPrevB1(data?.b1);
      setTimeout(() => setB1Blink(false), 1000);
    }

    if (data?.l1 !== prevL1) {
      setL1Blink(true);
      setPrevL1(data?.l1);
      setTimeout(() => setL1Blink(false), 1000);
    }
  }, [data?.b1, data?.l1]);

  const handleClick = (betDetails, bettype, marketType, value) => {
    // console.log("click", value);
    handleModal(true);
    setRealtimeData(true);
    setbetType(bettype);
    setmarketType(marketType);
    setbetDetails(betDetails);
    // setsubBetDetails(betDetails);
    setDataValue(value);
  };
  // console.log("oddsTable", data);
  return (
    <div
      className="row"
      style={{
        borderBottom: "1px solid rgb(146, 137, 137)",
        paddingBottom: "5px",
      }}
    >
      <div className="col-7 betting-t-n ">
        <p className="ms-2 mb-0 d-flex ">{data.rname} &nbsp;</p>
        {favourite && <span className="favourite ms-2">(FAVOURITE)</span>}
        {Teamexp && Teamexp.length > 0 && (
          <p
            className={`ms-3  ${Teamexp[0].amount >= 0 ? "success-1" : "zero"}`}
          >
            {Teamexp[0].amount}
          </p>
        )}
      </div>
      <div className="col-5 d-flex pos_re">
        {(data.status === "SUSPENDED" || data.status === "Ball Running") && (
          <div className="tr_data_1">
            <button className="spnd">{data.status}</button>
          </div>
        )}
        <div className={`blue-bet-btn mb-0 ${b1Blink ? "blink-green" : ""}`}>
          {isNaN(data?.b1) ? (
            <div className="d-flex flex-column align-items-center">
              0.00
              <span>0.00</span>
            </div>
          ) : (
            <div
              className="d-flex flex-column align-items-center "
              onClick={() =>
                handleClick(
                  {
                    marketId: mid,
                    evntid: eventTypeid,
                    matchid: id,
                    selectionId: data.sid,
                    runnerName: data.rname,
                    handicap: 0,
                    status: data.status,
                    lastPriceTraded: 0,
                    totalMatched: 0,
                    Backprice1: data?.b1,
                    Backprice: data?.b1,
                    Backsize: data?.bs1,
                    Layprice1: 0,
                    Layprice: 0,
                    Laysize: 0,
                    min: 0,
                    max: 0,
                    mname: matchType,
                    betRunnerName: data.rname,
                  },
                  "back",
                  matchType,
                  { valueOf: data?.b1, selId: data.sid, team: data.rname }
                )
              }
            >
              {data?.b1}
              <span>
                {data?.bs1 < 1000
                  ? data?.bs1
                  : (data?.bs1 / 1000).toFixed(1) + "K"}
              </span>
            </div>
          )}
        </div>
        <div
          className={` orange-bet-btn ms-1 mb-0  ${
            l1Blink ? "blink-green" : ""
          }`}
        >
          {isNaN(data?.l1) ? (
            <div className="d-flex flex-column align-items-center">
              0.00
              <span>0.00</span>
            </div>
          ) : (
            <div
              className="d-flex flex-column align-items-center"
              onClick={() =>
                handleClick(
                  {
                    marketId: mid,
                    evntid: eventTypeid,
                    matchid: id,
                    selectionId: data.sid,
                    runnerName: data.rname,
                    handicap: 0,
                    status: data.status,
                    lastPriceTraded: 0,
                    totalMatched: 0,
                    Backprice1: 0,
                    Backprice: 0,
                    Backsize: 0,
                    Layprice1: data?.l1,
                    Layprice: data?.l1,
                    Laysize: data?.ls1,
                    min: 0,
                    max: 0,
                    mname: matchType,
                    betRunnerName: data.rname,
                  },
                  "lay",
                  matchType,
                  { valueOf: data?.l1, selId: data.sid, team: data.rname }
                )
              }
            >
              {data?.l1}
              <span>
                {" "}
                {data?.ls1 < 1000
                  ? data?.ls1
                  : (data?.ls1 / 1000).toFixed(1) + "K"}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}


export default memo(OddsTable);
