// import { Collapse } from "bootstrap";
import React, { useEffect, useState } from "react";
import { MdOutlineStackedLineChart } from "react-icons/md";
import { MdOutlineOndemandVideo } from "react-icons/md";
import batting from "../../Media/batting.png";
import bowling from "../../Media/bowling.png";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import { useParams } from "react-router-dom";
import moment from "moment";

import { useNavigate } from "react-router-dom/dist";
const ScoreCard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();
  const [score, setScore] = useState({});
  const [ShowScore, setShowScore] = useState(false);
  // console.log("score", score.remaining_overs);
  const [chID, setchID] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://scoreapi.365cric.com/api/match/getStream?Cno=" + id
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        // setData(jsonData.data);
        const chID = jsonData?.result?.channelNo;
        setchID(chID);
        console.log("jsonData", jsonData);
        console.log("id", id);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id, chID]);




  const { ComName, openDate, eventTypeid, match_id, name, ldate1 } =
    useSelector((state) => state.auth.currentMatch);

  const date = new Date(openDate);

  date.setHours(date.getHours() - 5);
  date.setMinutes(date.getMinutes() - 30);

  const formattedTime = date.toLocaleTimeString();
  const adjustedDate = date.toISOString();

  // console.log(new Date(adjustedDate));

  const SOCKET_URL = "https://dreamwin-socket.nakshtech.info/";

  useEffect(() => {
    if (eventTypeid == 4) {
      const cricketMatchesSocket = io(SOCKET_URL);
      cricketMatchesSocket.emit("GetIframe", match_id || id, eventTypeid);
      cricketMatchesSocket.on("GetIframe_FromAPI", (data) => {
        // console.log(
        //   "score",
        //   data.data.teams[0].score.split("(")[1].split(")")[0],
        //   data.data.teams[0].score.split("(")[0]
        // );
        // console.log("getframedata", data);

        if (
          data.statusCode == 200 &&
          data.data &&
          data.data.message === "Match score fetched." &&
          data.data.data &&
          Object.keys(data.data.data).length > 0
        ) {
          setShowScore(true);
          setScore(data.data.data);
        } else {
          setShowScore(false);
        }
      });

      return () => {
        cricketMatchesSocket.disconnect();
      };
    }
  }, [id, eventTypeid]);

  function generateShortName(teamName) {
    const words = teamName.split(" ");

    let abbreviation = "";

    words.forEach((word) => {
      abbreviation += word[0];
    });

    return abbreviation.toUpperCase();
  }

  // ==========================Timer======================

  const calculateTimeLeft = () => {
    const difference = new Date(adjustedDate) - new Date();
    let timeLeft = {};
    // console.log("difference", difference);
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);
  const { days, hours, minutes, seconds } = timeLeft;

  return (
    <>
      {" "}
      <div className="match-score-section mt-2 d-flex flex-column align-items-center justify-content-center">
        {score?.match_name && (
          <div className="mt-2 match-name-div d-flex justify-content-center ">
            <button className="match-name-btn market-title">
              {score?.match_name}
            </button>
            <div className="line"></div>
          </div>
        )}
        <div className="mt-1 text-center">
          <p className="mn">{name}</p>
          <p className="mn">{score?.venue}</p>
        </div>

        <div className="in-play-main-div d-flex ">
          <button className="blue-btn">
            <MdOutlineStackedLineChart />
          </button>
          <p
            className="inplay-w d-flex align-items-center inplaynew"
            style={{
              minWidth: new Date(adjustedDate) >= new Date() ? "150px" : "60px",
            }}
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            {new Date(adjustedDate) >= new Date() ? ldate1 : "IN-PLAY"}
          </p>

          <button className="blue-btn">
            <MdOutlineOndemandVideo />
          </button>
        </div>
      </div>
      <div className="px-1 d-lg-none  d- block">
        <div className="TV-outer-border px-3 py-2">
          <div className="live-tv1">
            <div className="d-flex justify-content-between tv-card pb-0 mb-0 p-1">
              <iframe
                allowFullScreen={true}
                style={{
                  width: "100%",
                  border: "none",
                  opacity: "1",
                  visibility: "visible",
                  color: "white ",
                }}
                id="frame_video_21"
                src={`https://video.proexch.in/tv/static?chid=${chID}`}
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      {
        // ShowScore &&

        eventTypeid === "4" && (
          <>
            <div className="">
              <div className="match-score-section mt-2 d-flex flex-column align-items-center justify-content-center">
                {ShowScore && (
                  <div className="main-score-card-div bg-black">
                    <div className="row">
                      <div className="col-5 ">
                        <div className="row">
                          <div className="col-3  p-2  ">
                            <div className="batting-icon d-flex justify-content-center align-items-center ms-1">
                              <img
                                className="bat-icon-img"
                                src={batting}
                                alt="batting"
                              />
                            </div>
                          </div>
                          <div className="col-4  d-flex justify-content-center ">
                            {score && score.teams && score.teams.length > 0 && (
                              <p className="team-a-name mt-3">
                                {score.teams[0].team_short_name.length < 6
                                  ? score.teams[0].team_short_name
                                  : generateShortName(score.teams[0].team_name)}
                              </p>
                            )}
                          </div>
                          {score && score.teams && score.teams.length > 0 && (
                            <div className="col-5 d-flex flex-column justify-content-center align-items-center ">
                              <p className="team-a-name mb-0">
                                {" "}
                                {score?.teams[0]?.score?.split("(")[0]}
                              </p>
                              <p className="A-team-score mt-0 ">
                                {" "}
                                {
                                  score?.teams[0]?.score
                                    ?.split("(")[1]
                                    ?.split(")")[0]
                                }
                                &nbsp; OV
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-2">
                        <button className="stumps mt-3">
                          {score.match_format || "Stumps"}
                        </button>
                      </div>
                      <div className="col-5">
                        <div className="row">
                          {score && score.teams && score.teams.length > 0 && (
                            <div className="col-7 d-flex flex-column justify-content-center align-items-center  ">
                              <p className="team-a-name mb-0">
                                {score?.teams[1]?.score?.split("(")[0]}
                              </p>
                              <p className="A-team-score mt-0">
                                {" "}
                                {
                                  score?.teams[1]?.score
                                    ?.split("(")[1]
                                    ?.split(")")[0]
                                }
                                &nbsp;
                                {score?.teams[1]?.score
                                  ?.split("(")[1]
                                  ?.split(")")[0] && "OV"}
                              </p>
                            </div>
                          )}
                          {score && score?.teams && score.teams.length > 0 && (
                            <div className="col-2  d-flex justify-content-center  ">
                              <p className="team-a-name mt-3">
                                {score?.teams[1]?.team_short_name.length < 6
                                  ? score?.teams[1]?.team_short_name
                                  : generateShortName(
                                      score?.teams[1]?.team_name
                                    )}{" "}
                              </p>
                            </div>
                          )}

                          <div className="col-3  p-2  ">
                            <div className="batting-icon d-flex justify-content-center align-items-center ms-1">
                              <img
                                className="bat-icon-img"
                                src={bowling}
                                alt="bowling"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {!match_id && ShowScore && (
                      <div className="row">
                        <div className="col-md-4 ">
                          <div className="mx-2 d-flex "></div>
                          <div className="row">
                            <div className="col-6 batter-info d-flex flex-column justify-content-center align-items-center mt-2 ">
                              <p className="mb-0">Current Runrate </p>
                              <p className="mb-1">Required Runrate </p>
                            </div>
                            <div className="col-6 batter-info d-flex flex-column justify-content-center align-items-center mt-2">
                              <p className="mb-0">{score.currentRunRate}</p>
                              <p className="mb-0">
                                {score.requireRunRate || 0.0}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 d-flex justify-content-center  ">
                          <div className=" over-ball-div d-block  ">
                            <div className=" mx-2 d-flex justify-content-between align-items-center mt-3">
                              {score?.last24balls?.map((digit, index) => (
                                <div
                                  className={`${
                                    digit?.score_card === "ww" ||
                                    digit?.score_card === "wd"
                                      ? "red-info"
                                      : digit?.score_card === "0"
                                      ? "no-run-info"
                                      : digit?.score_card === "1"
                                      ? "single-info"
                                      : digit?.score_card === "4"
                                      ? "six-info"
                                      : digit?.score_card === "6"
                                      ? "six-info"
                                      : "single-info"
                                  }`}
                                  key={index}
                                >
                                  {digit?.score_card}{" "}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 ">
                          <div className="row">
                            <div className="col-6 batter-info d-flex flex-column justify-content-center align-items-center mt-2 ">
                              <p className="mb-0">Target </p>
                              {}
                              <p className="mb-0">Required Run </p>
                            </div>
                            <div className="col-6 batter-info d-flex flex-column justify-content-center align-items-center mt-2 ">
                              <p className="mb-0">{score?.target || 0}</p>
                              <p className="mb-0">{score?.runNeeded || 0}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {score && score.teams && score.teams.length > 0 && (
                      <div className="col-12 batter-info d-flex flex-column justify-content-center align-items-center mt-2 ">
                        <p className="mb-0">
                          {" "}
                          {parseFloat(score?.teams[0]?.score?.split("(")[0]) >
                            0 &&
                          parseFloat(score?.teams[1]?.score?.split("(")[0]) > 0
                            ? " 2nd Inning"
                            : parseFloat(
                                score?.teams[0]?.score?.split("(")[0]
                              ) > 0
                            ? "  1st Inning"
                            : parseFloat(
                                score?.teams[1]?.score?.split("(")[0]
                              ) > 0
                            ? " 2nd Inning"
                            : " "}
                        </p>
                      </div>
                    )}

                    {score && score?.msg && (
                      <div className="lead d-flex justify-content-center">
                        <p> {score?.msg}</p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            {/* added new design  */}
            {/* {match_id && match_id.length > 0 && ( */}
            <div className="row">
              {new Date(adjustedDate) >= new Date() ? (
                <div className="col-md-12">
                  <div className="bgssgreen dsi">
                    <div className="text-left mx-auto">
                      <div className="text-left">MATCH HAS NOT STARTED</div>
                      <div
                        className=""
                        style={{ fontSize: "12px", color: "#d4d4d4" }}
                      >
                        Starts at{" "}
                        {/* {moment(openDate).tz("Asia/Kolkata").format(" h:m A")}{" "}
                        IST */}
                        {formattedTime} IST
                      </div>
                    </div>
                  </div>
                  <div className="bgsegreen">
                    <div className="text-center">
                      <div className="" style={{ padding: "16px" }}>
                        <div className="" style={{ color: "#b5ffc9" }}>
                          COUNTDOWN
                        </div>
                        <div className="d-flex justify-content-center">
                          <div className="">
                            <button className="count">
                              {" "}
                              {days < 10 ? `0${days}` : days}
                            </button>
                            <p className="fone">Days</p>
                          </div>

                          <div className="">
                            <button className="count">
                              {hours < 10 ? `0${hours}` : hours}
                            </button>
                            <p className="fone">Hrs</p>
                          </div>

                          <div className="">
                            <button className="count">
                              {minutes < 10 ? `0${minutes}` : minutes}
                            </button>
                            <p className="fone">Mins</p>
                          </div>
                          <div className="">
                            <button className="count">
                              {seconds < 10 ? `0${seconds}` : seconds}
                            </button>
                            <p className="fone">Secs</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : match_id && match_id.length > 0 ? (
                <div className="col-md-6">
                  <div className="bgssgreen dsi">
                    <div className="text-center mx-auto">
                      <h6>Recent Balls</h6>
                      <ul className="uls">
                        {score?.last24balls?.map((digit, index) => (
                          <li
                            key={index}
                            className={`overs ${
                              digit?.score_card === "ww" ||
                              digit?.score_card === "Wb"
                                ? "red-info"
                                : digit?.score_card === "6" ||
                                  digit?.score_card === "4"
                                ? "six1-info"
                                : ""
                            }`}
                          >
                            {digit?.score_card}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="bgsegreen">
                    <table>
                      <tr>
                        <th>Batter</th>
                        <th>R</th>
                        <th>B</th>
                        <th>4S</th>
                        <th>6S</th>
                      </tr>
                      {score?.currentPlayersScore?.Batsman?.map(
                        (bat, index) => (
                          <tr key={index}>
                            <td>{bat?.on_play}</td>
                            <td>{bat?.runs}</td>
                            <td>{bat?.balls}</td>
                            <td>{bat?.fours}</td>
                            <td>{bat?.sixes}</td>
                          </tr>
                        )
                      )}
                    </table>
                    <div className="mainss">
                      <div className="col-12 bor d-flex justify-content-between">
                        <h6 className="fon">Current Bowler</h6>
                        <h6 className="fon">
                          {score?.currentPlayersScore?.bowler?.player_name}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className=""></div>
                </div>
              ) : null}
              {match_id &&
                match_id.length > 0 &&
                new Date(adjustedDate) < new Date() && (
                  <div className="col-md-6">
                    <div className="bgsegreeen">
                      <div className="padss">
                        {score.runNeeded > 0 && (
                          <>
                            <div className="brd row d-flex justify-content-between">
                              <div className="col-5 mainss">
                                <h6 className="fon_1">Need Run</h6>
                                <h6 className="fon_1">{score?.runNeeded}</h6>
                              </div>
                              <div className="col-5 mainss ms-2">
                                <h6 className="fon_1">Remain Balls</h6>
                                <h6 className="fon_1">
                                  {score?.ballsRemaining}
                                </h6>
                              </div>
                            </div>
                          </>
                        )}
                        {score.requireRunRate && (
                          <div className="brd d-flex justify-content-between">
                            <h6 className="fon">R.R</h6>
                            <h6 className="fon">{score?.requireRunRate}</h6>
                          </div>
                        )}
                        {score.target > 0 && (
                          <div className="brd d-flex justify-content-between">
                            <h6 className="fon">Target</h6>
                            <h6 className="fon">{score?.target}</h6>
                          </div>
                        )}

                        <div className="brd d-flex justify-content-between">
                          <h6 className="fon">Current Runrate</h6>
                          <h6 className="fon">{score?.currentRunRate}</h6>
                        </div>
                        {score.remaining_overs > 0 && (
                          <div className=" brd d-flex justify-content-between">
                            <h6 className="fon">Remain Over</h6>
                            <h6 className="fon">{score?.remaining_overs}</h6>
                          </div>
                        )}

                        <div className="brd d-flex justify-content-between">
                          <h6 className="fon">Current Over</h6>
                          <h6 className="fon">{score?.current_over}</h6>
                        </div>
                        <div className="brd d-flex justify-content-between">
                          <h6 className="fon">Current Wickets</h6>
                          <h6 className="fon">{score?.current_wickets}</h6>
                        </div>
                        {score.match_format && (
                          <div className="brd d-flex justify-content-between">
                            <h6 className="fon">Match Format</h6>
                            <h6 className="fon">{score?.match_format}</h6>
                          </div>
                        )}

                        {/* <div className="brd d-flex justify-content-between">
                    <div className="col-5 mainss">
                      <h6 className="fon">Extra</h6>
                      <h6 className="fon">0</h6>
                    </div>
                    <div className="col-5 mainss ms-2">
                      <h6 className="fon">Innings State</h6>
                      <h6 className="fon">{score.current_inning}</h6>
                    </div>
                  </div> */}
                      </div>
                    </div>
                  </div>
                )}
            </div>
            {/* )} */}
            {/* added new design  */}
          </>
        )
      }
    </>
  );
};

export default ScoreCard;
