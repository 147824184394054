import React, { useState, useEffect } from "react";
import SliderH from "./SliderH";
import { FaPlay } from "react-icons/fa";
import "./MatchesDetail.css";
import cricket from "../../Media/5.png";
import { BsFillPersonFill } from "react-icons/bs";
import te from "../../Media/te.png";
import ft from "../../Media/ft.png";
import cri from "../../Media/cri.png";
import card4coin from "../../Media/card4coin.png";
import passa from "../../Media/passa.png";
import MatchData from "./MatchData";
import InPlayMatchData from "./InPlayMatchData";
import colorimg from "../../Media/output-onlinepngtools (8).png";
import aircraft from "../../Media/output-onlinepngtools (5).png";
import { NavLink } from "react-router-dom";
// import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { fetchMatchName } from "../../Redux/Slices/matchNameSlice";
// import { fetchData } from "../../Redux/Slices/apiSlices";

const MatchesDetail = () => {
  const [activeIndex, setActive] = useState(4);
  const [isVisible, setIsVisible] = useState(true);

  const dispatch = useDispatch();

  const userId = sessionStorage.getItem("userData");
  const mobile = "0010" + userId;
  // console.log("uid", mobile);
  const gameid = 22001;
  const token = sessionStorage.getItem("token");

  const { matchName, loading, error } =
    useSelector((state) => state.matchInfo) || [];

  useEffect(() => {
    dispatch(fetchMatchName({ id: activeIndex, token })).unwrap();
  }, [dispatch, activeIndex]);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsVisible((prevVisible) => !prevVisible);
    }, 500);
    return () => clearInterval(interval);
  }, []);
  
  return (
    <div className=" col-lg-8 p-2 center-div">
      <SliderH />
      <div className="MainArea ">
        <div className="sport-div bg-dark row">
          <div className="mainflex sports-horiz-row d-flex">
            <div
              onClick={() => {
                setActive(4);
              }}
              className={`single-sport-div ${
                activeIndex === 4 && "is-active"
              }`}>
              <div className="row">
                <div className="col-10 d-flex flex-column ms-1 justify-content-end align-items-center ">
                  <div
                    className={`round-sport-div d-flex justify-content-center align-items-center ms-1 mt-2 ${
                      activeIndex === 4 && "is-activ"
                    }`}>
                    <img className=" sport-img ms-1" src={cri} alt="cricket" />
                  </div>
                  <p
                    className={`ms-1 sport-name mt-1 ${
                      activeIndex === 4 && "is-act"
                    }`}>
                    CRICKET
                  </p>
                </div>
                {/* <div className="col-2 ">
                  <div className="num-div d-flex justify-content-center ">
                    13
                  </div>
                </div> */}
              </div>
            </div>
            <div
              onClick={() => {
                setActive(1);
              }}
              className={`single-sport-div ${
                activeIndex === 1 && "is-active"
              }`}>
              <div className="row">
                <div className="col-10 d-flex flex-column justify-content-end align-items-center ">
                  <div
                    className={`round-sport-div d-flex justify-content-center align-items-center ms-1 mt-2 ${
                      activeIndex === 1 && "is-activ"
                    }`}>
                    <img className=" sport-img " src={ft} alt="cricket" />
                  </div>
                  <p
                    className={`ms-1 sport-name mt-1 ${
                      activeIndex === 1 && "is-act"
                    }`}>
                    SOCCER
                  </p>
                </div>
                {/* <div className="col-2">
                  <div className="num-div d-flex justify-content-center">
                    13
                  </div>
                </div> */}
              </div>
            </div>
            <div
              onClick={() => {
                setActive(2);
              }}
              className={`single-sport-div ${
                activeIndex === 2 && "is-active"
              }`}>
              <div className="row">
                <div className="col-10 d-flex flex-column justify-content-center align-items-center ">
                  <div
                    className={`round-sport-div d-flex justify-content-center align-items-center ms-1 mt-2 ${
                      activeIndex === 2 && "is-activ"
                    }`}>
                    <img className=" sport-img ms-1" src={te} alt="cricket" />
                  </div>
                  <p
                    className={`ms-1 sport-name mt-1 ${
                      activeIndex === 2 && "is-act"
                    }`}>
                    TENNIS
                  </p>
                </div>
                {/* <div className="col-2">
                  <div className="num-div d-flex justify-content-center align-items-center">
                    13
                  </div>
                </div> */}
              </div>
            </div>

            {/* <div className="single-sport-div ms-1">
              <a
                href={`https://sxm.in.net/jdb/post?iv=05bae19880a21dd1&key=3467d9770b6bdd41&uid=${mobile}&serverUrl=https://kingclubapi.xyz&parent=sourav&gType=22&mType=${gameid}`}
                target="_blank"
                rel="noopener noreferrer"
                className="nav-link">
                <div className=" avitorimage">
                  <img
                    className=" a-image-size img-fluid mt-2 iiinerimage"
                    src={aircraft}
                    alt="a"
                  />
                </div>
                <p className="ms-1 sport-name mt-2">AVIATOR</p>
              </a>
            </div> */}
            {/* <div className="single-sport-div ms-1 mt-2">
              <NavLink 
              to="/ColorGame" 
              className="nav-link">
                <div className="blink-img-div1">
                  {isVisible && (
                    <img className="sport-img ms-1 " src={colorimg} alt="a" />
                  )}
                </div>
                <p className="ms-1 sport-name mt-1">COLOR</p>
             </NavLink>
            </div> */}
            {/* <div className="single-sport-div ms-1 mt-2">
              <NavLink 
              // to="/TRXGame" 
              className="nav-link">
                <div className="blink-img-div1">
                  {isVisible && (
                    <img className="sport-img ms-1 " src={card4coin} alt="a" />
                  )}
                </div>
                <p className="ms-1 sport-name mt-1">TRX</p>
              </NavLink>
            </div> */}
            {/* <div className="single-sport-div ms-1 mt-2">
              <NavLink 
              to="/K3Game" 
              className="nav-link">
                <div className="blink-img-div1">
                  {isVisible && (
                    <img className="sport-img ms-1 " src={passa} alt="a" />
                  )}
                </div>
                <p className="ms-1 sport-name mt-1">K3</p>
              </NavLink>
            </div> */}
          </div>

          {/* <----------------------MatchData-----------------------> */}
          {matchName?.map((item, index) => {
            return new Date(item.openDate) >= new Date() ? (
              <MatchData key={index} data={item} activeIndex={activeIndex} />
            ) : (
              <InPlayMatchData
                key={index}
                data={item}
                activeIndex={activeIndex}
              />
            );
          })}

          {/* <InPlayMatchData /> */}
          {/* <MatchData />
          <MatchData />
          <MatchData /> */}
        </div>
      </div>
    </div>
  );
};

export default MatchesDetail;
