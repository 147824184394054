import React, { useEffect, useState } from "react";
import RightSidebar from "../RightSidebar/RIghtSidebar";
import a from "../../Media/10SS.png";
import b from "../../Media/3SS.png";
import { MdCancel } from "react-icons/md";
import { FaTrophy } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { io } from "socket.io-client";
import Modal from "../TeenPatti/Modal";
import { fetchThirtyTwoCardStack } from "../../Redux/Slices/thirtyTwoCardGameSlice";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../API/API";
// import card52Images from "../../Utility/52CardImages.js";
import { card52War } from "../../Utility/52CardImages.js";
import cardBlank from "../../Media/DTL2020/backcard.webp";

export default function OneDPoker() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const token = sessionStorage.getItem("token");
  const uid = sessionStorage.getItem("userData");
  const [showresultModal, setShowresultModal] = useState(false);
  const [name, setName] = useState("");
  const [results, setResults] = useState([]);
  const [reqRes, setreqRes] = useState({ t1: [], t2: [] });
  const [rulesModal, setRulesModal] = useState(false);
  const [realTime, setRealTimeData] = useState(false);
  const [betIndex, setBetIndex] = useState("");
  const [trophy, setTrophy] = useState("");
  const { thirtyTwoCardStack } = useSelector(
    (state) => state.thirtyTwoCardGameStack
  );

  const [betno, setBetNum] = useState(0);
  const [betDetails, setbetDetails] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [midRound, setMidRound] = useState(0);
  const [casinoGameResult, setCasinoGameResult] = useState([]);
  // console.log("results", results);
  const resultMapping = {
    11: "A",
    22: "B",
    // 3: "C",
  };

  const CasinoGameResult_API = async (mid) => {
    try {
      const response = await API.post("/GetCasinoGameResultData", {
        mid: mid,
        gameid: id,
      });
      // console.log("CasinoGameResult 1dpoker:", response.data.data[0]);
      setCasinoGameResult(response.data.data[0]);
    } catch (error) {
      console.error("Error CasinoGameResult_API", error);
    }
  };

  const ResultsModal = () => {
    return (
      <>
        <div
          onClick={() => {
            setShowresultModal(false);
          }}
          className="Results-modal-wrapper"
        ></div>
        <div className="lucky-results-modal-container bg-dark">
          <div className="results-modal-h d-flex justify-content-between pt-2">
            <div>Round ID: {midRound}</div>
            <MdCancel
              className="cross-icon-result me-2"
              onClick={() => {
                setShowresultModal(false);
              }}
            />
          </div>
          <div>
            <div className="container d-flex">
              <div className="row">
                <div className="d-flex justify-content-center mt-2">
                  <div className="player-a-div">
                    <div className="d-flex justify-content-between">
                      <div className="text-center">Player A</div>
                      {trophy === "A" && <FaTrophy className="trophy-icon" />}
                    </div>

                    <div className="d-flex">
                      {casinoGameResult[0] && (
                        <div
                          className="gamecard-div mx-1 "
                          key={casinoGameResult[0].id}
                        >
                          <img
                            src={casinoGameResult[0].image}
                            className="img-fluid"
                            alt={`Player A Card 0`}
                          />
                        </div>
                      )}
                      {casinoGameResult[1] && (
                        <div
                          className="gamecard-div mx-1"
                          key={casinoGameResult[1].id}
                        >
                          <img
                            src={casinoGameResult[1].image}
                            className="img-fluid"
                            alt={`Player A Card 0`}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="d-flex justify-content-center mt-10 "
                style={{ marginLeft: "20px" }}
              >
                <div className="player-a-div ">
                  <div className="text-center">Board</div>
                  <div className="d-flex">
                    {" "}
                    {casinoGameResult[4] && (
                      <div
                        className="gamecard-div mx-1 "
                        key={casinoGameResult[4].id}
                      >
                        <img
                          src={casinoGameResult[4].image}
                          className="img-fluid"
                          alt={`Player A Card 0`}
                        />
                      </div>
                    )}
                    {casinoGameResult[5] && (
                      <div
                        className="gamecard-div mx-1"
                        key={casinoGameResult[5].id}
                      >
                        <img
                          src={casinoGameResult[5].image}
                          className="img-fluid"
                          alt={`Player A Card 0`}
                        />
                      </div>
                    )}
                    {casinoGameResult[6] && (
                      <div
                        className="gamecard-div mx-1 "
                        key={casinoGameResult[6].id}
                      >
                        <img
                          src={casinoGameResult[6].image}
                          className="img-fluid"
                          alt={`Player A Card 0`}
                        />
                      </div>
                    )}
                    {casinoGameResult[7] && (
                      <div
                        className="gamecard-div mx-1"
                        key={casinoGameResult[7].id}
                      >
                        <img
                          src={casinoGameResult[7].image}
                          className="img-fluid"
                          alt={`Player A Card 0`}
                        />
                      </div>
                    )}
                    {casinoGameResult[8] && (
                      <div
                        className="gamecard-div mx-1"
                        key={casinoGameResult[8].id}
                      >
                        <img
                          src={casinoGameResult[8].image}
                          className="img-fluid"
                          alt={`Player A Card 0`}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div
                  className="d-flex justify-content-center mt-2"
                  style={{ marginLeft: "20px" }}
                >
                  <div className="player-a-div  ">
                    <div className="d-flex justify-content-between">
                      <div className="text-center">Player B</div>
                      {trophy === "B" && <FaTrophy className="trophy-icon" />}
                    </div>

                    <div className="d-flex">
                      {casinoGameResult[2] && (
                        <div
                          className="gamecard-div mx-1 "
                          key={casinoGameResult[2].id}
                        >
                          <img
                            src={casinoGameResult[2].image}
                            className="img-fluid"
                            alt={`Player A Card 0`}
                          />
                        </div>
                      )}
                      {casinoGameResult[3] && (
                        <div
                          className="gamecard-div mx-1"
                          key={casinoGameResult[3].id}
                        >
                          <img
                            src={casinoGameResult[3].image}
                            className="img-fluid"
                            alt={`Player A Card 0`}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const SOCKET_URL = "https://dreamwin-socket.nakshtech.info/";

  useEffect(() => {
    // console.log("Odd socket before");
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoGameSocket", id);

    cricketMatchesSocket.on("GetCasinoGameSocket_FromAPI", (data) => {
      // console.log("lucky7B socket1==>", data);
      if (data && data.length > 0) {
        const parsedData = data.map((item) => ({
          ...item,
          reqResponse: JSON.parse(item.reqResponse),
          resultUrlResponse: JSON.parse(item.resultUrlResponse),
        }));

        console.log("parsed", parsedData[0].reqResponse);
        // console.log("parsed_result", parsedData[0].resultUrlResponse);
        setResults(parsedData[0].reqResponse);
        setreqRes(parsedData[0].reqResponse);
        setName(parsedData[0].name);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  const handleModal = (value, nation, no) => {
    setbetDetails({
      ...betDetails,
      gameid: id,
      multipler: value,
      period: 0,
      nation: nation,
      number: no,
    });
    setShowModal(true);
    setRealTimeData(true);
  };

  async function ResultScheduler_API() {
    try {
      let res = await API.post(
        "/Schulder_7CareGameLive",
        {
          gameid: id,
          username: uid,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      // console.log("scheduler res", res.data);
    } catch (e) {
      console.log("Error", e);
    }
  }

  const [history, setHistory] = useState([]);

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoBetReportSocket", uid, id);

    cricketMatchesSocket.on("GetCasinoBetReportSocket_FromAPI", (data) => {
      if (data && data[0].length > 0) {
        setHistory(data[0]);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    ResultScheduler_API();
    dispatch(fetchThirtyTwoCardStack({ uid, gameId: id, token }));
  }, [dispatch, reqRes?.data?.data?.t1[0]?.mid]);

  const HistoryTable = (
    <>
      <div className="table-h-div row table-responsive">
        <table>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Period</th>
              <th>Multiplier</th>
              <th>Amount</th>
              <th>Date</th>
              <th>Remark</th>
              <th>Card</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {/* {loading ? (
              <tr>
                <td colSpan="6">Loading ...</td>
              </tr>
            ) : */}
            {history.length > 0 ? (
              history.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.period}</td>
                  <td>{item.multipler}</td>
                  <td>{item.amount}</td>
                  <td>{item.dd}</td>
                  <td className={item.remark === "Loss" ? "zero" : "success-1"}>
                    {item.remark}
                  </td>
                  <td>{item.card}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  No Data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );

  // function checkValid() {
  //   if (showModal && realTime) {
  //     let filteredData = reqRes?.data?.data?.t2.filter(
  //       (item) => item.sid === betIndex
  //     );

  //     // console.log("validcheck", filteredData);
  //     console.log("filterpokert2", filteredData, betDetails);
  //     filteredData[0]?.gstatus === "ACTIVE" &&
  //     (betDetails?.multipler === filteredData[0].l1 ||
  //       betDetails?.multipler === filteredData[0].b1)
  //       ? setRealTimeData(true)
  //       : setRealTimeData(false);
  //   }
  // }

  function checkValidthree() {
    if (showModal && realTime) {
      let filteredData = reqRes?.data?.data?.t3.filter(
        (item) => item.sid === betIndex
      );

      // console.log("validcheck", filteredData);
      // console.log("filterpoker3", filteredData, betDetails);
      filteredData[0]?.gstatus === "ACTIVE" &&
      betDetails?.multipler === filteredData[0].nation
        ? setRealTimeData(true)
        : setRealTimeData(false);
    }
  }

  useEffect(() => {
    if (reqRes?.data?.data?.t2) {
      // checkValid();
    } else if (reqRes?.data?.data?.t3) {
      checkValidthree();
    }
  }, [showModal, realTime, reqRes?.data?.data, betIndex]);

  const findCardImage = (cardId) => {
    const card = card52War.find((card) => card.id === cardId);
    return card ? card.src : null;
  };

  // const findCardImage = (cardId) => {
  //   const card = card52Images.find((card) => card.id === cardId);
  //   return card ? card.src : null;
  // };

  return (
    <>
      <div className="col-lg-10 ">
        <div className="row home mainhomeDev">
          <div className="col-lg-8 p-1">
            <div className="teenpatti-game-div pt-2 p-2">
              <div className="teenpatti-game-h d-flex justify-content-between  ">
                <div className=" px-2">
                  <p className=" mt-1 ">{name}</p>
                </div>
                <div>
                  <p className="mt-1 px-2">
                    Round ID: {reqRes?.data?.data?.t1[0]?.mid || 0.0} | Left
                    Time: {reqRes?.data?.data?.t1[0]?.autotime || 0} |
                  </p>
                </div>
              </div>
              <div className="card  teenpatti-game-card p-1 mt-1">
                <div className="row">
                  <div className="col-10">
                    <div className="" style={{ height: "170%", width: "120%" }}>
                      <iframe
                        height="100%"
                        width="100%"
                        src={`https://battlemoney.live/iframe/${id}`}
                        title=""
                      ></iframe>
                    </div>
                  </div>

                  <div className="col-2 d-flex justify-content-end">
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round"></span>
                    </label>
                  </div>
                  <div className="timer ">
                    <div
                      className="container"
                      style={{
                        color: "white",
                        marginLeft: "-3px",
                        marginTop: "-150px",
                      }}
                    >
                      {/* <div className="timer_container position-absolute"  > */}
                      <span>Player A</span>
                      <div>
                        {["C1", "C2"].map((key, index) => {
                          const cardId = reqRes?.data?.data?.t1[0]?.[key];
                          return cardId ? (
                            <span key={index}>
                              <img
                                src={findCardImage(cardId) || cardBlank}
                                alt={cardId}
                                style={{ width: "20px", height: "auto" }}
                              />
                            </span>
                          ) : null;
                        })}
                      </div>
                      <span>
                        <span>Player B</span>
                        <div>
                          {["C3", "C4"].map((key, index) => {
                            const cardId = reqRes?.data?.data?.t1[0]?.[key];
                            return cardId ? (
                              <span key={index}>
                                <img
                                  src={findCardImage(cardId) || cardBlank}
                                  alt={cardId}
                                  style={{ width: "20px", height: "auto" }}
                                />
                              </span>
                            ) : null;
                          })}
                        </div>
                      </span>

                      <span>
                        <span>BOARD</span>
                        <div>
                          {["C5", "C6", "C7", "C8", "C9"].map((key, index) => {
                            const cardId = reqRes?.data?.data?.t1[0]?.[key];
                            return cardId ? (
                              <span key={index}>
                                <img
                                  src={findCardImage(cardId) || cardBlank}
                                  alt={cardId}
                                  style={{ width: "20px", height: "auto" }}
                                />
                              </span>
                            ) : null;
                          })}
                        </div>
                      </span>

                      {/* </div> */}
                    </div>
                  </div>

                  <div
                    className="timer "
                    style={{ marginLeft: "550px", marginTop: "20px" }}
                  >
                    <div className="container">
                      <div
                        className="timer_container position-absolute"
                        style={{ right: "36px", bottom: "15px" }}
                      >
                        <button
                          class="btn btn-secondary"
                          style={{ cursor: "default", background: "#6f42c1" }}
                        >
                          <span>
                            {" "}
                            {reqRes?.data?.data?.t1[0]?.autotime || 0}{" "}
                          </span>
                        </button>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <-------------------------------Back-lay-table----------> */}
              <>
                <div className="">
                  <div className="table-h-div row">
                    <div className="col-6 d-flex align-items-center ps-2"></div>
                    <div className="col-3 d-flex align-items-center justify-content-center px-2">
                      Back
                    </div>
                    <div className="col-3 d-flex align-items-center justify-content-center ">
                      Lay
                    </div>
                  </div>
                  <div className="card-table-inner-row row">
                    <div className="col-6 d-flex align-items-center ps-2">
                      {reqRes?.data?.data?.t2[0]?.nation}
                      <span className="success-o ms-1">
                        {" "}
                        {thirtyTwoCardStack.map(
                          (ele) =>
                            ele.number === 1 && (
                              <span
                                className={`${
                                  ele.number == 1 && ele.amount >= 0
                                    ? "success-1"
                                    : "zero"
                                }`}
                              >
                                {ele.number == 1 && ele.amount}
                              </span>
                            )
                        )}
                      </span>
                    </div>
                    <div className="col-3 d-flex align-items-center justify-content-center px-2">
                      {reqRes?.data?.data?.t2[0]?.gstatus == "SUSPENDED" ||
                      reqRes?.data?.data?.t2[0]?.gstatus == "CLOSED" ? (
                        <button className="back-btn suspended-box">
                          {reqRes?.data?.data?.t2[0]?.b1}
                        </button>
                      ) : (
                        <button
                          className="back-btn"
                          onClick={() => {
                            handleModal(
                              reqRes?.data?.data?.t2[0]?.b1,
                              reqRes?.data?.data?.t2[0]?.nation,
                              1
                            );
                            setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                          }}
                        >
                          {reqRes?.data?.data?.t2[0]?.b1}
                          <p>0</p>
                        </button>
                      )}
                    </div>
                    <div className="col-3 d-flex align-items-center justify-content-center ">
                      {reqRes?.data?.data?.t2[0]?.gstatus == "SUSPENDED" ||
                      reqRes?.data?.data?.t2[0]?.gstatus == "CLOSED" ? (
                        <button className="lay-btn suspended-box">
                          {reqRes?.data?.data?.t2[0]?.l1}
                        </button>
                      ) : (
                        <button
                          className="lay-btn"
                          onClick={() => {
                            handleModal(
                              reqRes?.data?.data?.t2[0]?.l1,
                              reqRes?.data?.data?.t2[0]?.nation,
                              3
                            );
                            setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                          }}
                        >
                          {reqRes?.data?.data?.t2[0]?.l1}
                          <p>0</p>
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="card-table-inner-row row">
                    <div className="col-6 d-flex align-items-center ps-2">
                      {reqRes?.data?.data?.t2[1]?.nation}
                      <span className="success-o ms-1">
                        {" "}
                        {thirtyTwoCardStack.map(
                          (ele) =>
                            ele.number === 2 && (
                              <span
                                className={`${
                                  ele.number == 2 && ele.amount >= 0
                                    ? "success-1"
                                    : "zero"
                                }`}
                              >
                                {ele.number == 2 && ele.amount}
                              </span>
                            )
                        )}
                      </span>
                    </div>
                    <div className="col-3 d-flex align-items-center justify-content-center px-2">
                      {reqRes?.data?.data?.t2[1]?.gstatus == "SUSPENDED" ||
                      reqRes?.data?.data?.t2[1]?.gstatus == "CLOSED" ? (
                        <button className="back-btn suspended-box">
                          {reqRes?.data?.data?.t2[1]?.b1}
                        </button>
                      ) : (
                        <button
                          className="back-btn"
                          onClick={() => {
                            handleModal(
                              reqRes?.data?.data?.t2[1]?.b1,
                              reqRes?.data?.data?.t2[1]?.nation,
                              2
                            );
                            setBetIndex(reqRes?.data?.data?.t2[1]?.sid);
                          }}
                        >
                          {reqRes?.data?.data?.t2[1]?.b1}
                          <p>0</p>
                        </button>
                      )}
                    </div>
                    <div className="col-3 d-flex align-items-center justify-content-center ">
                      {reqRes?.data?.data?.t2[1]?.gstatus == "SUSPENDED" ||
                      reqRes?.data?.data?.t2[1]?.gstatus == "CLOSED" ? (
                        <button className="lay-btn suspended-box">
                          {reqRes?.data?.data?.t2[1]?.l1}
                        </button>
                      ) : (
                        <button
                          className="lay-btn"
                          onClick={() => {
                            handleModal(
                              reqRes?.data?.data?.t2[1]?.l1,
                              reqRes?.data?.data?.t2[1]?.nation,
                              4
                            );
                            setBetIndex(reqRes?.data?.data?.t2[1]?.sid);
                          }}
                        >
                          {reqRes?.data?.data?.t2[1]?.l1}
                          <p>0</p>
                        </button>
                      )}
                    </div>
                  </div>
                </div>

                <div className="">
                  <div className="table-h-div row">
                    <div className="col-6 d-flex align-items-center ps-2"></div>
                    <div className="col-3 d-flex align-items-center justify-content-center px-2">
                      Back
                    </div>
                    <div className="col-3 d-flex align-items-center justify-content-center px-2">
                      Back
                    </div>
                  </div>
                  <div className="card-table-inner-row row">
                    <div className="col-6 d-flex align-items-center ps-2">
                      Player A
                    </div>
                    <div className="col-3 d-flex align-items-center justify-content-center px-2">
                      {reqRes?.data?.data?.t3[0]?.gstatus == "SUSPENDED" ||
                      reqRes?.data?.data?.t3[0]?.gstatus == "CLOSED" ? (
                        <button
                          className="back-btn suspended-box"
                          style={{ width: "150px" }}
                        >
                          {reqRes?.data?.data?.t3[0]?.nation}
                        </button>
                      ) : (
                        <button
                          className="back-btn"
                          onClick={() => {
                            handleModal(
                              reqRes?.data?.data?.t3[0]?.b1,
                              reqRes?.data?.data?.t3[0]?.nation,
                              5
                            );
                            setBetIndex(reqRes?.data?.data?.t3[0]?.sid);
                          }}
                          style={{ width: "150px" }}
                        >
                          {reqRes?.data?.data?.t3[0]?.nation}
                          <p>
                            {" "}
                            {thirtyTwoCardStack.map(
                              (ele) =>
                                ele.number === 3 && (
                                  <span
                                    className={`${
                                      ele.number == 3 && ele.amount >= 0
                                        ? "success-1"
                                        : "zero"
                                    }`}
                                  >
                                    {ele.number == 3 && ele.amount}
                                  </span>
                                )
                            )}
                          </p>
                        </button>
                      )}
                    </div>
                    <div className="col-3 d-flex align-items-center justify-content-center ">
                      {reqRes?.data?.data?.t3[1]?.gstatus == "SUSPENDED" ||
                      reqRes?.data?.data?.t3[1]?.gstatus == "CLOSED" ? (
                        <button
                          className="back-btn suspended-box"
                          style={{ width: "150px" }}
                        >
                          {reqRes?.data?.data?.t3[1]?.nation}
                        </button>
                      ) : (
                        <button
                          className="back-btn"
                          onClick={() => {
                            handleModal(
                              reqRes?.data?.data?.t3[1]?.b1,
                              reqRes?.data?.data?.t3[1]?.nation,
                              6
                            );
                            setBetIndex(reqRes?.data?.data?.t3[1]?.sid);
                          }}
                          style={{ width: "150px" }}
                        >
                          {reqRes?.data?.data?.t3[1]?.nation}
                          <p>
                            {" "}
                            {thirtyTwoCardStack.map(
                              (ele) =>
                                ele.number === 4 && (
                                  <span
                                    className={`${
                                      ele.number == 4 && ele.amount >= 0
                                        ? "success-1"
                                        : "zero"
                                    }`}
                                  >
                                    {ele.number == 4 && ele.amount}
                                  </span>
                                )
                            )}
                          </p>
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="card-table-inner-row row">
                    <div className="col-6 d-flex align-items-center ps-2">
                      Player B
                    </div>
                    <div className="col-3 d-flex align-items-center justify-content-center px-2">
                      {reqRes?.data?.data?.t3[2]?.gstatus == "SUSPENDED" ||
                      reqRes?.data?.data?.t3[2]?.gstatus == "CLOSED" ? (
                        <button
                          className="back-btn suspended-box"
                          style={{ width: "150px" }}
                        >
                          {reqRes?.data?.data?.t3[2]?.nation}
                        </button>
                      ) : (
                        <button
                          className="back-btn "
                          onClick={() => {
                            handleModal(
                              reqRes?.data?.data?.t3[2]?.b1,
                              reqRes?.data?.data?.t3[2]?.nation,
                              7
                            );
                            setBetIndex(reqRes?.data?.data?.t3[2]?.sid);
                          }}
                          style={{ width: "150px" }}
                        >
                          {reqRes?.data?.data?.t3[2]?.nation}
                          <p>
                            {" "}
                            {thirtyTwoCardStack.map(
                              (ele) =>
                                ele.number === 5 && (
                                  <span
                                    className={`${
                                      ele?.number == 5 && ele?.amount >= 0
                                        ? "success-1"
                                        : "zero"
                                    }`}
                                  >
                                    {ele?.number == 5 && ele?.amount}
                                  </span>
                                )
                            )}
                          </p>
                        </button>
                      )}
                    </div>
                    <div className="col-3 d-flex align-items-center justify-content-center ">
                      {reqRes?.data?.data?.t3[3]?.gstatus == "SUSPENDED" ||
                      reqRes?.data?.data?.t3[3]?.gstatus == "CLOSED" ? (
                        <button
                          className="back-btn suspended-box"
                          style={{ width: "150px" }}
                        >
                          {reqRes?.data?.data?.t3[3]?.nation}
                        </button>
                      ) : (
                        <button
                          className="back-btn"
                          onClick={() => {
                            handleModal(
                              reqRes?.data?.data?.t3[3]?.b1,
                              reqRes?.data?.data?.t3[3]?.nation,
                              8
                            );
                            setBetIndex(reqRes?.data?.data?.t3[3]?.sid);
                          }}
                          style={{ width: "150px" }}
                        >
                          {reqRes?.data?.data?.t3[3]?.nation}
                          <p>
                            {" "}
                            {thirtyTwoCardStack.map(
                              (ele) =>
                                ele.number === 6 && (
                                  <span
                                    className={`${
                                      ele.number == 6 && ele.amount >= 0
                                        ? "success-1"
                                        : "zero"
                                    }`}
                                  >
                                    {ele.number == 6 && ele.amount}
                                  </span>
                                )
                            )}
                          </p>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </>

              <div className="Results-table">
                <div className="color-game-h mt-2">
                  <div className="px-2 pt-1">
                    <p>Last 10 Results</p>
                  </div>
                </div>
                <div className="card rst-card py-1 d-flex">
                  <div className="rst-row d-flex">
                    {results?.data?.result?.map((result, index) => {
                      return (
                        <div
                          onClick={() => {
                            setMidRound(result.mid);
                            CasinoGameResult_API(result.mid);
                            setShowresultModal(true);
                            setTrophy(resultMapping[result.result]);
                          }}
                          className="rst-btn ms-1"
                          key={index}
                        >
                          {<>{resultMapping[result.result]}</>}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              {/* <<<<<<<---------My-Bets-table-------------->>>>>>>>>> */}
              <div className="My-Bets-table">
                <div className="color-game-h mt-2">
                  <div className="px-2 pt-1">
                    <p>Open Bets</p>
                  </div>
                </div>
                {HistoryTable}
                {/* <div className="card-table-inner-row row"></div> */}
              </div>
            </div>
          </div>

          <RightSidebar />
          {showModal && (
            <Modal
              id={id}
              name={name}
              betDetails={betDetails}
              setbetDetails={setbetDetails}
              setShowModal={setShowModal}
              realTime={realTime}
              setRealTimeData={setRealTimeData}
            />
          )}
          {showresultModal && <ResultsModal />}
          {/* {showDataModal && <DataModal />} */}
        </div>
      </div>
    </>
  );
}
