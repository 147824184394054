import React, { useEffect, useState } from "react";
import RightSidebar from "../RightSidebar/RIghtSidebar";
import "./K3Game.css";
import { FaRegPlusSquare } from "react-icons/fa";
import { FaRegMinusSquare } from "react-icons/fa";
import io from "socket.io-client";
import K3Modal from "./K3Modal";
import { useDispatch } from "react-redux";
import { API } from "../../API/API";
// import { fetchuserBalance } from "../../Redux/Slices/userBalanceSlice";
import { logoutUser } from "../../Redux/Slices/authSlice";
import { useNavigate } from "react-router-dom";
import {
  threeDifferentButton,
  twoSameOneDifferentButton,
  twoDifferentButton,
  tabs,
  threesameButton,
  twosameButton,
  bigSmallButton,
  numberButton,
  getColorBetComponent,
} from "./K3Data";
import Dice1 from "../../Media/K3/res1.png";
import Dice2 from "../../Media/K3/res2.png";
import Dice3 from "../../Media/K3/res3.png";
import Dice4 from "../../Media/K3/res4.png";
import Dice5 from "../../Media/K3/res5.png";
import Dice6 from "../../Media/K3/res6.png";
import K3TimeDisplay from "./K3TimeDisplay";
// const API = axios.create({
//   baseURL: "https://bigkolkata-api.nakshtech.info/",
//   //baseURL: "http://localhost:1355/",
// });

const diceImages = {
  0: Dice1,
  1: Dice2,
  2: Dice3,
  3: Dice4,
  4: Dice5,
  5: Dice6,
};

const K3Game = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId = sessionStorage.getItem("userData");
  const jwt_token = sessionStorage.getItem("token");
  // const { userId, jwt_token } = useSelector((state) => state.Auth);
  const [Getperiod, setGetperiod] = useState("");

  const [activeTab, setActiveTab] = useState("home-tab");
  const [tabIndex, setTabIndex] = useState(0);
  const [topData, setTopData] = useState(0);
  const [toggle, setToggle] = useState(false);
  const [modal, setModal] = useState();
  const [Betdata, setBetData] = useState(null);
  const [roundDetails, setRoundDetails] = useState({});
  const [historyData, setHistoryData] = useState([]);
  const [historyDataAdmin, sethistoryDataAdmin] = useState([]);
  const [loader, setloader] = useState(false);
  const [historyBoolean, setHistoryBoolean] = useState(false);
  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const [selectedTwoNumbers, setSelectedTwoNumbers] = useState([]);
  const [selectedFirstRow, setSelectedFirstRow] = useState(null);
  const [selectedSecondRow, setSelectedSecondRow] = useState(null);
  const [shuffledNumbers, setShuffledNumbers] = useState({
    Num1: 0,
    Num2: 0,
    Num3: 0,
  });
  const [currentValue, setCurrentValue] = useState(0);

  const [isShuffling, setIsShuffling] = useState(false);

  useEffect(() => {
    if (currentValue == 1) {
      setIsShuffling(true);

      let shuffleInterval = setInterval(() => {
        setShuffledNumbers({
          Num1: Math.floor(Math.random() * 6) + 1,
          Num2: Math.floor(Math.random() * 6) + 1,
          Num3: Math.floor(Math.random() * 6) + 1,
        });
      }, 100);

      setTimeout(() => {
        clearInterval(shuffleInterval);
        setIsShuffling(false);
      }, 3000);
    }
  }, [currentValue]);

  const handleTabClick = (id, tabIndex) => {
    setActiveTab(id);
    setTabIndex(tabIndex);
    setSelectedNumbers([]);
    setSelectedTwoNumbers([]);
    setSelectedSecondRow(null);
    setSelectedFirstRow(null);
  };

  const tabGame = [
    { title: "Total", tabIndex: 0 },
    { title: "2 Same", tabIndex: 1 },
    { title: "3 Same", tabIndex: 2 },
    { title: "Different", tabIndex: 3 },
  ];

  const currentTab = tabGame[tabIndex];

  const handleSelectFirstRow = (num) => {
    if (
      selectedSecondRow &&
      selectedSecondRow.toString() === num.toString()[0]
    ) {
      // Prevent selecting the same number from both rows
      setSelectedSecondRow(null);
    }
    setSelectedFirstRow(num);
    checkAndOpenModal(num, selectedSecondRow);
  };

  const handleSelectSecondRow = (num) => {
    if (selectedFirstRow && selectedFirstRow.toString()[0] === num.toString()) {
      // Prevent selecting the same number from both rows
      setSelectedFirstRow(null);
    }
    setSelectedSecondRow(num);
    checkAndOpenModal(selectedFirstRow, num);
  };

  const checkAndOpenModal = (firstRowNum, secondRowNum) => {
    if (firstRowNum && secondRowNum) {
      const combinedNumber = `${firstRowNum}${secondRowNum}`;
      const selectedData = twoSameOneDifferentButton.find(
        (item) => item.number === parseInt(combinedNumber)
      );
      if (selectedData) {
        handleBetButton(selectedData);
      }
    }
  };

  const handleThreeNumberClick = (number) => {
    let updatedNumbers = [...selectedNumbers];
    if (updatedNumbers.includes(number)) {
      updatedNumbers = updatedNumbers.filter((num) => num !== number);
    } else {
      updatedNumbers.push(number);
    }

    setSelectedNumbers(updatedNumbers);

    // Check if exactly 3 numbers are selected
    if (updatedNumbers.length === 3) {
      const selectedCombination = updatedNumbers.sort().join("");
      const matchedButton = threeDifferentButton.find(
        (item) => item.number === parseInt(selectedCombination)
      );

      if (matchedButton) {
        handleBetButton({
          number: matchedButton.number,
          value: matchedButton.value,
          color: matchedButton.color,
        });
      }
    }
  };

  const handleTwoNumberClick = (number) => {
    let updatedNumbers = [...selectedTwoNumbers];
    if (updatedNumbers.includes(number)) {
      updatedNumbers = updatedNumbers.filter((num) => num !== number);
    } else if (updatedNumbers.length < 2) {
      updatedNumbers.push(number);
    }

    setSelectedTwoNumbers(updatedNumbers);

    // Check if exactly 2 numbers are selected
    if (updatedNumbers.length === 2) {
      const selectedCombination = updatedNumbers.sort().join("");
      const matchedButton = twoDifferentButton.find(
        (item) => item.number === parseInt(selectedCombination)
      );

      if (matchedButton) {
        handleBetButton({
          number: matchedButton.number,
          value: matchedButton.value,
          color: matchedButton.color,
        });
      }
    }
  };

  const K3Result_API = async () => {
    try {
      // alert(userId);
      let responseHashkey = await API.post("getTopOneK3Result", {
        gameid: 1,
      });

      console.log("responseHashkey", responseHashkey?.data?.data[0]);
      setTopData(responseHashkey?.data?.data[0]);
      // setNumber(responseHashkey?.data?.data[0]?.number);
    } catch (e) {
      console.log("Error While Fetch hashkey_API", e);
    }
  };

  useEffect(() => {
    K3Result_API();
  }, [Getperiod]);

  const ColorResult_API = async () => {
    try {
      // alert(userId);
      let responseHistory = await API.post(
        "K3BetandUserHistory",
        {
          uid: userId,
          username: userId,
          GameType: 1,
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setHistoryData(responseHistory?.data?.data[0] || []);
      console.log("historyUser", responseHistory?.data?.data[0] );

      let responseHistoryAdmin = await API.post(
        "K3BetandUserHistory",
        {
          uid: 0,
          username: +userId,
          GameType: 1,
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      sethistoryDataAdmin(responseHistoryAdmin.data.data[0]);
      const gamesno = responseHistoryAdmin?.data?.data[0][0].gamesno;
      // setCurrentPeriodpop(gamesno);

      const incrementedGamesno = (parseInt(gamesno, 10) + 1).toString();
      setGetperiod(incrementedGamesno);
      // dispatch(fetchuserBalance({ id: userId, token: jwt_token }));
    } catch (e) {
      console.log("Error While Fetch AvbetCancle_API", e);
      // if (
      //   e.response.data.showableMessage ===
      //   "Please login to access this resource"
      // ) {
      //   sessionStorage.clear();
      //   dispatch(logoutUser());
      //   navigate("/");
      // }
      // console.log("Error While calling userinfomyReferrals API", e);
      // if(e.status==401){
      //   dispatch(logoutUser())
      //   sessionStorage.clear()
      // }
    }
  };

  useEffect(() => {
    ColorResult_API();
  }, [loader, Betdata]);

  
  useEffect(() => {
    if (currentValue == 0) {
      setloader(true);
    } else {
      setloader(false);
    }

  }, [currentValue]);

  // const SOCKET_URL = "https://dreamwin-socket.nakshtech.info/";

  // useEffect(() => {
  //   const cricketMatchesSocket = io(SOCKET_URL);
  //   cricketMatchesSocket.emit("GetInsertRound");

  //   cricketMatchesSocket.on("GetInsertRound_FromAPI", (data) => {
  //     // console.log("colorPlay", data[0]);

  //     if (data[0]) {
  //       setRoundDetails(data[0]);
  //     }

  //     const startRoundTime = new Date(data[0].startround).getTime();
  //     const endRoundTime = new Date(data[0].endround).getTime();
  //     const currentTime = new Date(data[0].currentDate).getTime();
  //     // console.log("Current Round ", currentTime);

  //     const timeDifferenceInSeconds = Math.floor(
  //       (endRoundTime - currentTime) / 1000
  //     );
  //     // console.log("diff", timeDifferenceInSeconds);

  //     const remainingCount1 = Math.max(0, timeDifferenceInSeconds);

  //     setcurrentValue(remainingCount1);
  //     // console.log("remainingCount1", remainingCount1);
  //     if (remainingCount1 === 0) {
  //       setloader(true);
  //     } else {
  //       setloader(false);
  //     }
  //   });

  //   return () => {
  //     cricketMatchesSocket.disconnect();
  //   };
  // }, []);

  function handleBetButton(btn) {
    if (currentValue > 10 && Getperiod) {
      // console.log("btn", btn, roundDetails);
      let newData = { Getperiod, ...btn };
      // console.log("round", newData);
      setBetData(newData);
      setModal(true);
    }
  }

  const handleBetModalClose = () => {
    setModal(false);
    setBetData(null);
    setSelectedNumbers([]);
    setSelectedTwoNumbers([]);
    setSelectedSecondRow(null);
    setSelectedFirstRow(null);
  };

  return (
    <>
      <div className="col-lg-10 home">
        <div className="row home">
          <div className="col-lg-8 p-2 center-div">
            <div className="color-game-div pt-3 p-2">
              <div className="color-game-h d-flex justify-content-between  ">
                <div className=" px-2">
                  <p className=" mt-1 ">K3</p>
                </div>
                <div>
                  <p className="mt-1 px-2">
                    Round ID:
                    {Getperiod ? Getperiod : 0} |
                    Left Time: {currentValue} | Rules
                  </p>
                </div>
              </div>
              <div className="col-12">
                <div className="K3TL__C-l3">
                  <div className="box">
                    <div
                      className={`numb num${
                        isShuffling ? shuffledNumbers.Num1 : topData.Num1
                      }`}
                    ></div>
                    <div
                      className={`numb num${
                        isShuffling ? shuffledNumbers.Num2 : topData.Num2
                      }`}
                    ></div>
                    <div
                      className={`numb num${
                        isShuffling ? shuffledNumbers.Num3 : topData.Num3
                      }`}
                    ></div>
                  </div>
                </div>
              </div>

              <div className="main-load">
                {loader && (
                  <div className="loader-container-k3">
                    <div className="loader"></div>
                    <div className="loading-text-k3">Loading...</div>
                  </div>
                )}
                <ul
                  className="nav nav-tabs navtabssss tabsmanin border-bottom-0 mb-3"
                  id="myTab"
                  role="tablist"
                >
                  {tabs.map((tab, index) => (
                    <li className="nav-item" role="presentation" key={index}>
                      <button
                        className={`nav-link border-0 ${
                          index === 0 ? "active" : ""
                        }`}
                        id={tab.id}
                        data-bs-toggle="tab"
                        data-bs-target={tab.target}
                        type="button"
                        role="tab"
                        aria-controls={tab.ariaControls}
                        aria-selected={tab.ariaSelected}
                        tabIndex={activeTab === tab.id ? 0 : -1}
                        onClick={() => handleTabClick(tab?.id, tab?.tabIndex)}
                      >
                        <div className="timetext">{tab.text}</div>
                      </button>
                    </li>
                  ))}
                </ul>

                <div
                  className="tab-content tabcontentsection"
                  id="myTabContent"
                >
                  <div
                    className="tab-pane fade active show"
                    id="home-tab-pane"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                    tabIndex={0}
                  >
                    <div className="card  color-game-card1">
                      <div className="row mt-4">
                        <div className="col-md-2"></div>
                        <div className="col-md-8 d-flex justify-content-between">
                          {numberButton.slice(0, 4).map((btn, index) => (
                            <div>
                              <div
                                className={`${btn.className} d-flex justify-content-center align-items-center c`}
                                value={btn.value}
                                disabled={currentValue > 10 ? false : true}
                                onClick={() => handleBetButton(btn)}
                                key={index}
                                readOnly
                              >
                                {btn.label}
                                {/* <div>2X</div> */}
                              </div>
                              <h6 className="ftttr text-white pt-1 mb-0">
                                {btn.multiplier}X
                              </h6>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-2"></div>
                        <div className="col-md-8 d-flex justify-content-between">
                          {numberButton.slice(4, 8).map((btn, index) => (
                            <div>
                              <div
                                className={`${btn.className} d-flex justify-content-center align-items-center`}
                                value={btn.value}
                                disabled={currentValue > 10 ? false : true}
                                onClick={() => handleBetButton(btn)}
                                key={index}
                              >
                                {btn.label}
                              </div>
                              <h6 className="ftttr text-white pt-1 mb-0">
                                {btn.multiplier}X
                              </h6>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-2"></div>
                        <div className="col-md-8 d-flex justify-content-between">
                          {numberButton.slice(8, 12).map((btn, index) => (
                            <div>
                              <div
                                className={`${btn.className} d-flex justify-content-center align-items-center`}
                                value={btn.value}
                                disabled={currentValue > 10 ? false : true}
                                onClick={() => handleBetButton(btn)}
                                key={index}
                              >
                                {btn.label}
                              </div>
                              <h6 className="ftttr text-white pt-1 mb-0">
                                {btn.multiplier}X
                              </h6>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-2"></div>
                        <div className="col-md-8 d-flex justify-content-between">
                          {numberButton.slice(12, 16).map((btn, index) => (
                            <div>
                              <div
                                className={`${btn.className} d-flex justify-content-center align-items-center`}
                                value={btn.value}
                                disabled={currentValue > 10 ? false : true}
                                onClick={() => handleBetButton(btn)}
                                key={index}
                              >
                                {btn.label}
                              </div>
                              <h6 className="ftttr text-white pt-1 mb-0">
                                {btn.multiplier}X
                              </h6>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="row mt-3 juti">
                        {" "}
                        {bigSmallButton.map((btn, index) => (
                          <div className="col-3" key={index}>
                            <button
                              className={btn.className}
                              disabled={currentValue > 10 ? false : true}
                              onClick={() => handleBetButton(btn)}
                              readOnly
                            >
                              {btn.label}
                              <h6 className=" ftttr text-white pt-0 mb-0">
                                1.92X
                              </h6>
                            </button>
                          </div>
                        ))}
                        {/* <div className="container">
                          <div
                            className="timer_container position-absolute"
                            style={{ right: "25px", bottom: "0", top: "33px" }}
                          >
                            <button
                              class="btn btn-secondary"
                              style={{
                                cursor: "default",
                                background: "#6f42c1",
                              }}
                            >
                              <K3TimeDisplay
                                setCurrentValue={setCurrentValue}
                                currentValue={currentValue}
                                ColorResult_API={ColorResult_API}
                              />
                            </button>{" "}
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="profile-tab-pane"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                    tabIndex={0}
                  >
                    <div className="row mx-0">
                      <div className="col-12">
                        <div className="matchingnumber mb-2">
                          2 matching numbers: odds <span>(13.83)</span>
                          <i className="fa-regular fa-circle-question text-theme1 ms-2"></i>
                        </div>
                        <ul className="list-unstyled ps-0 d-flex justify-content-center gap-1 rediolist">
                          {twosameButton?.map((item) => (
                            <li key={item.number}>
                              <button
                                className="k3buttonss"
                                disabled={currentValue > 10 ? false : true}
                                onClick={() => handleBetButton(item)}
                              >
                                {item.number}
                              </button>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="col-12">
                        <div className="matchingnumber mb-2">
                          A pair of unique numbers: odds <span>(69.12)</span>
                          <i className="fa-regular fa-circle-question text-theme1 ms-2"></i>
                        </div>
                        <ul className="list-unstyled ps-0 d-flex align-items-center justify-content-center gap-1 rediolist listcolor2">
                          {[11, 22, 33, 44, 55, 66].map((num) => (
                            <li key={num}>
                              <button
                                className={`k3buttonsss ${
                                  selectedFirstRow === num ? "selected" : ""
                                }`}
                                disabled={currentValue > 10 ? false : true}
                                onClick={() => handleSelectFirstRow(num)}
                              >
                                {num}
                                {selectedFirstRow == num && (
                                  <i className="fa fa-check-circle text-blue position-absolute "></i>
                                )}
                              </button>
                            </li>
                          ))}
                        </ul>
                        <ul className="list-unstyled ps-0 d-flex align-items-center justify-content-center gap-1 rediolist listcolor3">
                          {[1, 2, 3, 4, 5, 6].map((num) => (
                            <li key={num}>
                              <button
                                className={`k3buttonssss ${
                                  selectedSecondRow == num ? "selected" : ""
                                }`}
                                disabled={currentValue > 10 ? false : true}
                                onClick={() => handleSelectSecondRow(num)}
                              >
                                {num}
                                {selectedSecondRow == num && (
                                  <i className="fa fa-check-circle text-blue position-absolute "></i>
                                )}
                              </button>
                            </li>
                          ))}
                        </ul>
                      </div>
                      {/* <div className="container">
                          <div
                            className="timer_container position-absolute"
                            style={{ right: "25px", bottom: "0", top: "33px" }}
                          >
                            <button
                              class="btn btn-secondary"
                              style={{
                                cursor: "default",
                                background: "#6f42c1",
                              }}
                            >
                              <K3TimeDisplay
                                setCurrentValue={setCurrentValue}
                                currentValue={currentValue}
                                ColorResult_API={ColorResult_API}
                              />
                            </button>{" "}
                          </div>
                        </div> */}
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="contact-tab-pane"
                    role="tabpanel"
                    aria-labelledby="contact-tab"
                    tabIndex={0}
                  >
                    <div className="row mx-0">
                      <div className="col-12">
                        <div className="matchingnumber mb-2">
                          3 of the same number: odds <span>(207.36)</span>
                          <i className="fa-regular fa-circle-question text-theme1 ms-2"></i>
                        </div>
                        <ul className="list-unstyled ps-0 d-flex align-items-center justify-content-center gap-1 rediolist">
                          {threesameButton.map((item) => (
                            <li key={item.number}>
                              <button
                                className="k3buttonss"
                                disabled={currentValue > 10 ? false : true}
                                onClick={() => handleBetButton(item)}
                              >
                                {item.number}
                              </button>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="col-12">
                        <div className="matchingnumber mb-2">
                          Any 3 of the same number: odds<span>(34.56)</span>
                          <i className="fa-regular fa-circle-question text-theme1 ms-2"></i>
                        </div>
                        <div className="list-unstyled ps-0 d-flex align-items-center justify-content-center gap-1 rediolist listcolor2">
                          <button
                            className="k3buttonsss1"
                            disabled={currentValue > 10 ? false : true}
                            onClick={() =>
                              handleBetButton({
                                number: "Any 3 of the same number: odds",
                                value: 43,
                                color: "Red",
                                btn: false,
                              })
                            }
                          >
                            Any 3 of the same number: odds
                          </button>
                        </div>
                      </div>
                      {/* <div className="container">
                          <div
                            className="timer_container position-absolute"
                            style={{ right: "25px", bottom: "0", top: "33px" }}
                          >
                            <button
                              class="btn btn-secondary"
                              style={{
                                cursor: "default",
                                background: "#6f42c1",
                              }}
                            >
                              <K3TimeDisplay
                                setCurrentValue={setCurrentValue}
                                currentValue={currentValue}
                                ColorResult_API={ColorResult_API}
                              />
                            </button>{" "}
                          </div>
                        </div> */}
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="disabled-tab-pane"
                    role="tabpanel"
                    aria-labelledby="disabled-tab"
                    tabIndex={0}
                  >
                    <div className="row mx-0">
                      <div className="col-12">
                        <div className="matchingnumber mb-2">
                          3 different numbers: odds <span>(34.56)</span>
                          <i className="fa-regular fa-circle-question text-theme1 ms-2"></i>
                        </div>
                        <ul className="list-unstyled ps-0 d-flex justify-content-center gap-1 rediolist">
                          {[1, 2, 3, 4, 5, 6].map((number) => (
                            <li key={number}>
                              <button
                                className={`k3buttonss ${
                                  selectedNumbers.includes(number)
                                    ? "selected"
                                    : ""
                                }`}
                                disabled={currentValue > 10 ? false : true}
                                onClick={() => handleThreeNumberClick(number)}
                              >
                                {number}
                                {selectedNumbers.includes(number) && (
                                  <i className="fa fa-check-circle text-blue position-absolute "></i>
                                )}
                              </button>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="col-12">
                        <div className="matchingnumber mb-2">
                          3 continuous numbers: odds <span>(8.64)</span>
                          <i className="fa-regular fa-circle-question text-theme1 ms-2"></i>
                        </div>
                        <div className="list-unstyled ps-0 d-flex justify-content-center gap-1 rediolist listcolor2">
                          <button
                            className="k3buttonsss1"
                            disabled={currentValue > 10 ? false : true}
                            onClick={() =>
                              handleBetButton({
                                number: "3 continuous numbers: odds",
                                value: 85,
                                color: "Red",
                                btn: false,
                              })
                            }
                          >
                            3 continuous numbers
                          </button>
                        </div>

                        <div className="matchingnumber mb-2">
                          2 different numbers: odds <span>(6.91)</span>
                          <i className="fa-regular fa-circle-question text-theme1 ms-2"></i>
                        </div>

                        <ul className="list-unstyled ps-0 d-flex align-items-center justify-content-center gap-1 rediolist">
                          {[1, 2, 3, 4, 5, 6].map((number) => (
                            <li key={number}>
                              <button
                                className={`k3buttonss ${
                                  selectedTwoNumbers.includes(number)
                                    ? "selected"
                                    : ""
                                }`}
                                disabled={currentValue > 10 ? false : true}
                                onClick={() => handleTwoNumberClick(number)}
                              >
                                {number}
                                {selectedTwoNumbers.includes(number) && (
                                  <i className="fa fa-check-circle text-blue position-absolute "></i>
                                )}
                              </button>
                            </li>
                          ))}
                        </ul>
                      </div>
                      {/* <div className="container">
                          <div
                            className="timer_container position-absolute"
                            style={{ right: "25px", bottom: "0", top: "33px" }}
                          >
                            <button
                              class="btn btn-secondary"
                              style={{
                                cursor: "default",
                                background: "#6f42c1",
                              }}
                            >
                              <K3TimeDisplay
                                setCurrentValue={setCurrentValue}
                                currentValue={currentValue}
                                ColorResult_API={ColorResult_API}
                              />
                            </button>{" "}
                          </div>
                        </div> */}
                    </div>
                  </div>
                </div>
              </div>

              {/* <-------------------------------Previous-ROund-Results-table----------> */}
              <div className="heighted">
                <div className="color-game-h mt-2">
                  <div className="px-2 pt-1 text-center">
                    <p>Previous Rounds Results</p>
                  </div>
                </div>
                <div className="tablek3-h-div k3table row">
                  <table className="tableColor table-responsive bg-white">
                    <tr className="tablheader">
                      <th>Period</th>
                      <th>Sum</th>
                      <th>Odd/Even</th>
                      <th>B/S</th>
                      <th>Result</th>
                    </tr>
                    <tbody>
                      {historyDataAdmin.length == 0 && (
                        <tr>
                          <td colSpan={7}>Data Not found</td>
                        </tr>
                      )}

                    {historyDataAdmin.map((item, index) => {
                      const nums = [item.Num1, item.Num2, item.Num3];
                      return (
                        <tr key={index}>
                          <td className="align-items-center ps-2">
                            {" "}
                            {item.gamesno}
                          </td>
                          <td className="align-items-center ps-2">
                            {item.number}
                          </td>
                          <td className="align-items-center ps-2">
                            {item.Even_Odd}
                          </td>
                          <td className="align-items-center ps-2">
                            {item.Big_Small}
                          </td>
                          <td className="lodo align-items-center ps-2">
                            {nums.map((num, idx) => (
                              <img
                                key={idx}
                                className="lodo-img"
                                src={diceImages[num - 1]}
                                alt={`Lodo${num}`}
                              />
                            ))}
               
                          </td>
                        </tr>
                      );
                    })}
                    </tbody>
                    {/* // ))} */}
                  </table>
                </div>
              </div>

              {/* <<<<<<<<<<<<<<<<<<<<<<<-----------------------My-Bets-table-------------->>>>>>>>>> */}

              <div className="My-Bets-table">
                <div className="color-game-h mt-2">
                  <div className="px-2 pt-1 text-center">
                    <p>My Bets</p>
                  </div>
                </div>  

                <div className="tablek3-h-div k3table row" style={{ overflow: "auto" }}>
                  <table className="colbg tableColor table-responsive">
                    <tr className="tablheader">
                      <th>Period</th>
                      <th>Select</th>
                      <th>Result</th>
                      <th>Amount</th>
                      <th>Details</th>
                    </tr>
                    <tbody>
                    {historyData?.length == 0 && (
                        <tr>
                          <td colSpan={7}>Data Not found</td>
                        </tr>
                      )}
                    {historyData.map((item) => (
                      <>
                        <tr key={item?.BetID}>
                          <td className="align-items-center ps-2">
                            {item?.gamesno}
                          </td>
                          <td className="align-items-center ps-2">
                          {item?.ColorBet != null && item?.ColorBet !== ""
                              ? getColorBetComponent(
                                  item?.ColorBet,
                                  item?.number
                                )
                              : null}
                          </td>
                          {item?.status == 0 && (
                            <>
                              <td className="align-items-center fail">
                                Pending
                              </td>
                              <td className="align-items-center ps-2">
                                {item?.stake < 100
                                  ? parseFloat(item?.stake).toFixed(2)
                                  : item?.stake < 1000
                                  ? parseFloat(item?.stake).toFixed(1)
                                  : `${(parseFloat(item?.stake) / 1000).toFixed(
                                      2
                                    )}K`}
                              </td>
                            </>
                          )}
                          {item?.status != 0 && (
                            <>
                              <td
                                className={`align-items-center ${
                                  item?.status == 1 ? "success" : "fail"
                                }`}
                              >
                                {item?.status == 1 ? "Win" : "Loss"}
                              </td>
                              <td
                                className={`align-items-center ${
                                  item?.WIN > 0 ? "success" : "fail"
                                }`}
                              >
                                 ₹{item?.WIN > 0 ? Number(item?.odds).toFixed(2) : Number(item?.stake).toFixed(2)}
                                {/* {item?.odds < 100
                                  ? parseFloat(item?.odds).toFixed(2)
                                  : item?.odds < 1000
                                  ? parseFloat(item?.odds).toFixed(1)
                                  : `${(parseFloat(item?.odds) / 1000).toFixed(
                                      2
                                    )}K`} */}
                              </td>
                            </>
                          )}
                          <td className="align-items-center ps-2">
                            <div
                              onClick={() => {
                                toggle === item?.BetID
                                  ? setToggle(null)
                                  : setToggle(item?.BetID);
                              }}
                            >
                              {toggle === item?.BetID ? (
                                <FaRegMinusSquare className="plus-icon" />
                              ) : (
                                <FaRegPlusSquare className="plus-icon" />
                              )}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="5">
                            {toggle === item?.BetID && (
                              <>
                                <table className="table-responsive">
                                  <tr>
                                    <th colSpan="2">Period Detail</th>
                                  </tr>
                                  <tr>
                                    <td>Period</td>
                                    <td>{item?.gamesno}</td>
                                  </tr>
                                  <tr>
                                    <td>Contract Money</td>
                                    <td>{item?.stake}</td>
                                  </tr>
                                  <tr>
                                    <td>Delivery</td>
                                    <td>{item?.odds}</td>
                                  </tr>
                                  <tr>
                                    <td>Fee</td>
                                    <td>{item?.Charge}</td>
                                  </tr>
                                  <tr>
                                    <td>Result</td>
                                    <td>
                                      {item?.status === 1
                                        ? "Win"
                                        : item?.status === 2
                                        ? "Loss"
                                        : ""}

                                      {item?.resultColor === "Green" ? (
                                        <div className="green-dot ms-2"></div>
                                      ) : item?.resultColor === "Red" ? (
                                        <div className="red-dot ms-2"></div>
                                      ) : item?.resultColor === "Violet" ? (
                                        <>
                                          {" "}
                                          <div className="violet-dot ms-2"></div>
                                          {/* <div className="red-dot ms-2"></div> */}
                                        </>
                                      ) : item?.resultColor === "Violet" ? (
                                        <>
                                          {" "}
                                          <div className="violet-dot ms-2"></div>
                                          {/* <div className="green-dot ms-2"></div> */}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Select</td>
                                    <td>{item?.ColorBet}</td>
                                  </tr>
                                  <tr>
                                    <td>Amount</td>
                                    <td
                                      className={`col-6 pt-1  ${
                                        item?.resultStatus == 0
                                          ? "fail"
                                          : "success"
                                      }`}
                                    >
                                      {item?.stake}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Create Time</td>
                                    <td>{item?.dd}</td>
                                  </tr>
                                </table>
                              </>
                            )}
                          </td>
                        </tr>
                      </>
                    ))}
                   </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {Getperiod && (
            <div className="d-none">
              <K3TimeDisplay
                    setCurrentValue={setCurrentValue}
                    currentValue={currentValue}
                    ColorResult_API={ColorResult_API}
                
              />
            </div>
          )}

          <RightSidebar />
          {modal && (
            <K3Modal
              historyBoolean={historyBoolean}
              setHistoryBoolean={setHistoryBoolean}
              open={setModal}
              data={Betdata}
              onClose={handleBetModalClose}
              title={currentTab.title}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default K3Game;
