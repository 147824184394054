import axios from "axios";
import { API } from "../../API/API";

const lossProfitAPI = API;

export const fetchLossProfit = async (
  uid,
  fromDate,
  toDate,
  token,
  username
) => {
  try {
    // let response;
    // if (username.includes("BT")) {
    //   response = await API.get(
    //     `Loss_Profit?uid=${uid}&fromDate=${fromDate}&toDate=${toDate}`,
    //     {
    //       headers: {
    //         Authorization: token,
    //       },
    //     }
    //   );
    // } else {
    //   response = await lossProfitAPI.get(
    //     `Loss_Profit_MLM?uid=${uid}&fromDate=${fromDate}&toDate=${toDate}`,
    //     {
    //       headers: {
    //         Authorization: token,
    //       },
    //     }
    //   );
    // }
    // const response = await lossProfitAPI.get(`Loss_Profit?uid=${uid}&fromdate=${fromDate}&toDate=${toDate}`, {
    //   headers: {
    //     Authorization: token,
    //   },
    // });
    const response = await lossProfitAPI.post(
      `Loss_Profit_New`,
      {
        uid: uid,
        fromDate: fromDate,
        toDate: toDate,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    console.log("profitLoss", response);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export default lossProfitAPI;
