import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { logoutUser } from "../../Redux/Slices/authSlice";

export default function Pagenotfound() {
  const dispatch = useDispatch();

  const handleLogout = () => {
    sessionStorage.clear();
    dispatch(logoutUser());
  };
  return (
    <>
      <div className="col-lg-10 home mainHome p-5">
        <div className="text-center ">
          <h1 className="text-danger"> Page not Found !</h1>
          <h1 className="text-success"> ↓↓ Please click back ↓↓</h1>
        </div>
        <div className="container text-center" style={{ marginTop: "5rem" }}>
          <Link
            className="btn btn-lg btn-success"
            onClick={handleLogout}
            to="/">
            ⌘ Back➲
          </Link>
        </div>
      </div>
    </>
  );
}
