import React from "react";
import "./ChangePassword.css";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updatePasswordData } from "../../Redux/Slices/updatePassSlice";
import { toast } from "react-toastify";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newCPassword, setNewCPassword] = useState("");
  const { isLoading, error } = useSelector((state) => state.updatePass);
  const token = sessionStorage.getItem("token");
  // const uid = '727681';
  const uid = sessionStorage.getItem("userData");

  // Replace with the user's UID

  const handleSubmit = async (e) => {
    e.preventDefault();
    let res = await dispatch(
      updatePasswordData({ uid, oldPassword, newPassword, token })
    );

    console.log("Submit_res", res.payload.result);

    if (res.payload.result === "Update Successfull") {
      toast.success("password changed successfully");
      setOldPassword("");
      setNewPassword("");
      setNewCPassword("");
    } else {
      toast.info(res.payload.result);
    }
  };
  if (isLoading) {
    // return <div>Loading...</div>;
  }

  // if (isSuccess) {
  //   console.log("password changed successfully", isSuccess);
  //   toast.success("password changed successfully");
  // }

  // if (error) {
  //   // return <div>Error: {error.message}</div>;
  // }
  return (
    // <div className="col-lg-10">
    <div className="col-lg-10 com-h mt-5 pt-3">
      <div className="row">
        <div className="col-12 p-1 px-2">
          <div className="funds-transfer-main-div ">
            <div className="F-h p-2 pb-0">
              <p className="ms-3">Change Password</p>
            </div>

            <div className="form-area d-flex justify-content-center align-items-center">
              <div className="card cp-card mt-4 py-2 col-lg-8">
                <div className="px-4">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group mt-2">
                      <label htmlFor="exampleInputPassword1">
                        Current Password*
                      </label>
                      <input
                        type="password"
                        className="form-control mt-2"
                        id="exampleInputPassword1"
                        placeholder="Current Password"
                        maxLength={20}
                        onChange={(e) => {
                          setOldPassword(e.target.value);
                        }}
                      />
                    </div>
                    <div className="form-group mt-2">
                      <label htmlFor="exampleInputPassword1">
                        New Password
                      </label>
                      <input
                        type="password"
                        className="form-control mt-2"
                        id="exampleInputPassword1"
                        placeholder="New Password"
                        maxLength={20}
                        onChange={(e) => {
                          setNewPassword(e.target.value);
                        }}
                      />
                    </div>
                    <div className="form-group mt-2">
                      <label htmlFor="exampleInputPassword1">
                        Confirm Password
                      </label>
                      <input
                        type="password"
                        className="form-control mt-2"
                        id="exampleInputPassword1"
                        placeholder=" Confirm Password"
                        maxLength={20}
                        onChange={(e) => {
                          setNewCPassword(e.target.value);
                        }}
                      />
                    </div>

                    <button type="submit" className="btn transfer-btn mt-4">
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
