import React, { useEffect, useState } from "react";
import { fetchUserdepositHistoryData } from "../../Redux/Slices/depositHistorySlice";
import Table_Buttons from "../Table_Buttons/Table_Button";
import Table from "../Table/Table";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "../../Redux/Slices/authSlice";
import { API } from "../../API/API";

export default function ActDepositHistory() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [leftreferralApi, setleftreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(20);

  const userId = sessionStorage.getItem("userData");
  const token = sessionStorage.getItem("token");
  const referral_API = async () => {
    try {
      // console.log("userId-->", userId);
      let responceRight = await API?.get(
        `Activation_history?id=${userId}`,

        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log("LeaderShip-->", responceRight?.data?.data);

      responceRight = responceRight?.data?.data;
      setleftreferralApi([]);
      let arrayLeft = [];
      // 	UserId	Event	EventType	Match	Yes	No
      Array.isArray(responceRight) &&
        responceRight.forEach((item, index) => {
          arrayLeft.push({
            sr: index + 1,
            user_id: item.user_id,
            Amount: item.Amount,
            dd: item.Request_date,
            PointAmount: item.RequestToken,
            remark:item.remark,
            Txn: (
              <a
                className="link-light"
                href={`https://bscscan.com/tx/${item?.pinnumber}`}
                target="_blank">
                View txn
              </a>
            ),
          });

          setleftreferralApi(arrayLeft);
        });
    } catch (error) {
      console.log("Error While calling Myteam API", error);
      if (
        error.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };

  useEffect(() => {
    referral_API();
  }, []);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = leftreferralApi.slice(indexOfFirstPage, indexOfLastPost);
  var [matching_income, set_matching_income] = useState({
    cols: [
      { Header: "S.No.", accessor: "sr" },
      { Header: "UserId", accessor: "user_id" },
      { Header: "Amount", accessor: "Amount" },
      { Header: "Date", accessor: "dd" },
      { Header: "Txn", accessor: "Txn" },
      { Header: "Remark", accessor: "remark" },
    ],
  });
  return (
    // <div className="col-lg-10 com-h">
    <div className="col-lg-10 com-h mt-5 pt-3">
      <div className="row">
        <div className="col-12 p-3">
          <div className="bet-h ">
            <h4 className="p-2 ms-2">Activate / Upgrade History</h4>

            <Table data={[...currentPost]} columns={matching_income.cols} />
            <Table_Buttons
              setcurrentPage={setcurrentPage}
              currentPage={currentPage}
              totalData={leftreferralApi.length}
              listPerpage={listPerpage}
              handleRowsPerPageChange={setlistPerpage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
