import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";
import { API } from "../../API/API";
import { logoutUser } from "../../Redux/Slices/authSlice";

const MyDownline = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [leftreferralApi, setleftreferralApi] = useState([]);

  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);
  const [todate, settodate] = useState("");
  const [fromdate, setfromdate] = useState("");
  const [status, setstatus] = useState(2);
  const [position, setposition] = useState(0);

  const Username = sessionStorage.getItem("userName");
  const UserId = sessionStorage.getItem("userData");
  const token = sessionStorage.getItem("token");
  const referral_API = async () => {
    try {
      let responceRight = await API?.post(
        `MyDownline`,
        {
          uid: UserId,
          fdate: "",
          tdate: "",
          status: status,
          position: position,
          username: sessionStorage.getItem("userName"),
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log("GetIncomeReport-->", responceRight?.data?.data[0]);

      responceRight = responceRight?.data?.data[0];
      setleftreferralApi([]);
      let arrayLeft = [];
      Array.isArray(responceRight) &&
        responceRight.forEach((item, index) => {
          arrayLeft.push({
            sr: item.RowNumber,
            uid: item.uid,
            USER_ID: item.USER_ID,
            f_name: item.f_name,
            level: item.Leveltype,
            email: item.email,
            mobile: item.mobile,
            RegDate: item.registrationDate,
            TotalBet: item.TotalBet,
            TotalDeposit: item.TotalDeposit,
            position: item.position,
            actDate: item.actDate,
            TotalGameDeposit: item.TotalGameDeposit,
            NetGameDeposit: item.NetGameDeposit,
          });

          setleftreferralApi(arrayLeft);
        });
    } catch (error) {
      console.log("Error While calling Myteam API", error);
      if (
        error.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };

  useEffect(() => {
    referral_API();
  }, []);
  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = leftreferralApi.slice(indexOfFirstPage, indexOfLastPost);

  var [matching_income, set_matching_income] = useState({
    cols: [
      { Header: "S.No", accessor: "sr" },
      { Header: "User Id", accessor: "uid" },
      // { Header: "Login Id", accessor: "USER_ID" },
      { Header: "Name", accessor: "f_name" },

      { Header: "Registration Date", accessor: "RegDate" },
      { Header: "Position", accessor: "position" },
      { Header: "Investment", accessor: "TotalDeposit" },
      { Header: "Activation Date", accessor: "actDate" },
      { Header: "Total Game Deposit", accessor: "TotalGameDeposit" },
      { Header: "Total Betting Amount", accessor: "TotalBet" },
      { Header: "Net Game Balance", accessor: "NetGameDeposit" },
    ],
  });

  // const handleSearch = () => {
  //   const data = {
  //     uid: userId,
  //     fromDate: fromdate,
  //     toDate: todate,
  //   };
  //   dispatch(fetchAccountStatement({ data, token }));
  // };

  return (
    // <div className="col-lg-10 com-h">
    <div className="col-lg-10 com-h mt-5 pt-3">
      <div className="row">
        <div className="col-12 p-3">
          <div className="bet-h ">
            <h4 className="p-2 ms-2">My Downline</h4>

            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-2">
                <select
                  className="form-control"
                  onChange={(e) => setposition(e.target.value)}
                >
                  <option value="0">All Position</option>
                  <option value="1">Left</option>
                  <option value="2">Right</option>
                </select>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-2">
                <select
                  className="form-control"
                  onChange={(e) => setstatus(e.target.value)}
                >
                  <option value="2">All Status</option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
              </div>
              <div className="col-md-2 mt-1">
                <button
                  className="btn btn-success "
                  onClick={() => referral_API()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-search"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                  </svg>{" "}
                  Search
                </button>
              </div>
            </div>
            <div className="">
              <Table data={[...currentPost]} columns={matching_income.cols} />
              <Table_Buttons
                setcurrentPage={setcurrentPage}
                currentPage={currentPage}
                totalData={leftreferralApi.length}
                listPerpage={listPerpage}
                handleRowsPerPageChange={setlistPerpage}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyDownline;
