import { useEffect, useState } from "react";

const TRXTimeDisplay = ({
  setCurrentValueT,
  ColorResult_API,
}) => {
  const [remainingTime, setRemainingTime] = useState(0);

  const calculateRemainingTime = () => {
    const date = new Date();
    const options = {
      timeZone: "Asia/Kolkata",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };
    const formatter = new Intl.DateTimeFormat([], options);
    const showTime = formatter.format(date);

    let [hour, finalminutes, finalseconds] = showTime.split(":").map(Number);

    // Calculate total seconds passed in the current hour
    const totalSecondsPassed = finalminutes * 60 + finalseconds;

    // Determine the timer duration based on the game type
    let timerDuration = 60;

    // Calculate the time remaining until the next interval
    const remainingTimeInSeconds =
      timerDuration - (totalSecondsPassed % timerDuration);
    // console.log("1234", remainingTimeInSeconds);
    ColorResult_API();
    return remainingTimeInSeconds;
  };

  useEffect(() => {
    // Initial calculation of remaining time
    setRemainingTime(calculateRemainingTime());

    const interval = setInterval(() => {
      setRemainingTime((prevRemainingTime) => {
        if (prevRemainingTime > 0) {
          const newTime = prevRemainingTime - 1;
          setCurrentValueT(newTime);
          return newTime;
        } else {
          const newTime = calculateRemainingTime();
          setCurrentValueT(newTime);
          return newTime;
        }
      });
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [setCurrentValueT]);

  // Calculate minutes and seconds from remainingTime
  const minutes = Math.floor(remainingTime / 60);
  const seconds = remainingTime % 60;
  const secondsStr = String(seconds).padStart(2, "0");

  const timeStr = `${secondsStr}`;

  return timeStr;
};

export default TRXTimeDisplay;
