import React from "react";
import { useState, useEffect } from "react";
import "./Login.css";
import { fetchIP, selectIP } from "../../Redux/Slices/ipSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  AdminloginUser,
  Authenticate,
  loginUser,
  logoutUser,
} from "../../Redux/Slices/authSlice";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import logo1 from "../../Media/cricketlogo.png";

import DataModal from "./PswdModal";
const AdminLogin = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [user_id, setuser_id] = useState(searchParams.get("user_id"));
  const [enpass, setenpass] = useState(searchParams.get("enpass"));

  const [UserName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [modalData, setModalData] = useState({});
  const [pswdModal, setPswdModal] = useState(false);
  const [InfoModal, setInfoModal] = useState(false);
  const [Txnpswd, setTxnPswd] = useState("");
  const [newpswd, setNewPswd] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ip = useSelector(selectIP);
  const { user, isLoading } = useSelector((state) => state.auth);
  // console.log(ip);

  const UserInfoModal = () => {
    return (
      <>
        <div
          onClick={() => {
            handleInfoModal();
          }}
          className="Info-modal-wrapper"
        ></div>
        <div className="Info-modal-container">
          <div className="head-border d-flex align-items-center">
            <p className="my-auto mx-2">Account Information</p>
          </div>
          <div className="container-body ">
            <div className="ms-3">
              <p className="mt-2">User Id :{UserName}</p>
              <p className="mt-2">Login Password:{newpswd}</p>
              <p className="mt-2">Transaction Password :{Txnpswd}</p>
            </div>
          </div>
          <div className="modal-footer row mt-5">
            <div className="col-5"></div>
            <div className="col-7 py-2">
              <button
                onClick={() => {
                  handleInfoModal();
                }}
                className="mbb "
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  const handlePswdModal = () => {
    setPswdModal(false);
  };

  useEffect(() => {
    dispatch(fetchIP());
  }, [dispatch]);

  const submitLogin = () => {
    dispatch(
      AdminloginUser({
        isAuth: false,
        username: user_id,
        apassword: enpass,
        ipaddress: ip,
      })
    );

  };

  // console.log("123",pswdModal)

  useEffect(() => {
    submitLogin();

    if (user) {
      let responce = user.data.result;
      if (responce === "Successfull") {
        if (user.data.loginstatus === "0") {
          setPswdModal(true);
          setModalData({ ...user.data, token: user?.token });
        } else {
          toast.success("Logged In Successfully");
          let userdata = user.data.resultid;
          let token = user.token;
          let username = user.data.resultusername;
          // console.log("token", token);
          sessionStorage.setItem("token", token);
          dispatch(Authenticate());
          // console.log("userdataID", userdata);
          sessionStorage.setItem("userData", user.data.resultid);
          // console.log("userdataname", username);
          sessionStorage.setItem("userName", user.data.resultusername);
          // dispatch(fetchuserBalance({ id: user.data.resultid, token }));
          // dispatch(fetchBetButton({ id: user.data.resultid, token }));

          navigate("/");
        }
        // console.log("loggedin");
      } else {
        // alert(responce);
        toast.error(responce);
        dispatch(logoutUser());
      }
    }
  });

  const handleInfoModal = () => {
    setInfoModal(false);
    setNewPswd("");
    setTxnPswd("");
    navigate("/");
  };
  return (
    <>
    </>


  );
};

export default AdminLogin;