import React, { useEffect, useState } from "react";
import RightSidebar from "../RightSidebar/RIghtSidebar";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import "./Baccarat.css";
import a from "../../Media/10SS.png";
import b from "../../Media/3SS.png";
import card from "../../Media/DTL2020/backcard.webp";
import { MdCancel } from "react-icons/md";
import { FaTrophy } from "react-icons/fa";
import { PieChart } from "@mui/x-charts/PieChart";
import { useParams } from "react-router-dom";
import Rules_Modal from "../Rules_Modal"; 
import Modal from "../TeenPatti/Modal";
import { API } from "../../API/API";
import { fetchThirtyTwoCardStack } from "../../Redux/Slices/thirtyTwoCardGameSlice";
import { card52War } from "../../Utility/52CardImages.js";
import cardBlank from "../../Media/DTL2020/backcard.webp";

export default function Baccarat1() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const token = sessionStorage.getItem("token");
  const uid = sessionStorage.getItem("userData");
  const [name, setName] = useState("");
  const [results, setResults] = useState([]);
  const [reqRes, setreqRes] = useState({ t1: [], t2: [] });
  const [rulesModal, setRulesModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
const [graphData, setGraphData]= useState([])
  const [betDetails, setbetDetails] = useState({});
  const [showresultModal, setShowresultModal] = useState(false);
  const [showDataModal, setShowdataModal] = useState(false);
  const [trophy, setTrophy] = useState("");
  const [realTime, setRealTimeData] = useState(false);
  const [betIndex, setBetIndex] = useState("");
  const { thirtyTwoCardStack } = useSelector(
    (state) => state.thirtyTwoCardGameStack
  );
  const [midRound, setMidRound] = useState(0);
  const [casinoGameResult, setCasinoGameResult] = useState([]);

  // console.log("thirtyTwoCardStack", thirtyTwoCardStack);
  // console.log("results", results);

  const CasinoGameResult_API = async (mid) => {
    try {
      const response = await API.post("/GetCasinoGameResultData", {
        mid: mid,
        gameid: id,
      });
      console.log("CasinoGameResult dragon:", response.data.data[0]);
      setCasinoGameResult(response.data.data[0]);
    } catch (error) {
      console.error("Error CasinoGameResult_API", error);
    }
  };

  const ResultsModal = () => {
    return (
      <>
        <div
          onClick={() => {
            setShowresultModal(false);
          }}
          className="Results-modal-wrapper"
        ></div>
        <div className="results-modal-container bg-dark">
          <div className="results-modal-h d-flex justify-content-between pt-2">
            <div>Round ID: {midRound}</div>
            <MdCancel
              className="cross-icon-result me-2"
              onClick={() => {
                setShowresultModal(false);
              }}
            />
          </div>
          <div>
            <div className="d-flex justify-content-center mt-2">
              <div className="player-a-div ">
                <div className="text-center">
                  Player
                  {trophy == "P" && <FaTrophy className="trophy-icon" />}
                </div>
                <div className="d-flex">
                  {casinoGameResult?.map(
                    (item, index) =>
                      index % 2 === 0 && (
                        <div className="gamecard-div mx-1" key={index}>
                          <img
                            src={item.image}
                            className="img-fluid"
                            alt={`Player A Card ${index}`}
                          />
                        </div>
                      )
                  )}
                  {/* <div className="gamecard-div mx-1">
                    <img src={b} className="img-fluid" alt="a" />
                  </div>
                  <div className="gamecard-div mx-1">
                    <img src={a} className="img-fluid" alt="a" />
                  </div>
                  <div className="gamecard-div mx-1">
                    <img src={b} className="img-fluid" alt="a" />
                  </div> */}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-2">
              <div className="player-a-div  ">
                <div className="d-flex justify-content-between">
                  <div className="text-center">Banker</div>
                  {trophy == "B" && <FaTrophy className="trophy-icon" />}
                </div>

                <div className="d-flex">
                  {casinoGameResult?.map(
                    (item, index) =>
                      index % 2 !== 0 && (
                        <div className="gamecard-div mx-1" key={index}>
                          <img
                            src={item.image}
                            className="img-fluid"
                            alt={`Player A Card ${index}`}
                          />
                        </div>
                      )
                  )}
                  {/* <div className="gamecard-div mx-1">
                    <img src={b} className="img-fluid" alt="a" />
                  </div>
                  <div className="gamecard-div mx-1">
                    <img src={a} className="img-fluid" alt="a" />
                  </div>
                  <div className="gamecard-div mx-1">
                    <img src={b} className="img-fluid" alt="a" />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const SOCKET_URL = "https://dreamwin-socket.nakshtech.info/";

  useEffect(() => {
    // console.log("Odd socket before");
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoGameSocket", id);

    cricketMatchesSocket.on("GetCasinoGameSocket_FromAPI", (data) => {
      // console.log("TeenPatti socket1==>", data);
      if (data && data.length > 0) {
        const parsedData = data.map((item) => ({
          ...item,
          reqResponse: JSON.parse(item.reqResponse),
          resultUrlResponse: JSON.parse(item.resultUrlResponse),
        }));

        console.log("parsed", parsedData[0]);
        // console.log("parsed_result", parsedData[0].resultUrlResponse);
        setGraphData(parsedData[0].resultUrlResponse)
        setResults(parsedData[0].reqResponse);
        setreqRes(parsedData[0].reqResponse);
        setName(parsedData[0].name);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  async function ResultScheduler_API() {
    try {
      let res = await API.post(
        "/Schulder_7CareGameLive",
        {
          gameid: id,
          username: uid,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      // console.log("scheduler res", res.data);
    } catch (e) {
      console.log("Error", e);
    }
  }

  const [history, setHistory] = useState([]);
  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoBetReportSocket", uid, id);
    cricketMatchesSocket.on("GetCasinoBetReportSocket_FromAPI", (data) => {
      if (data && data[0].length > 0) {
        setHistory(data[0]);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, [id]);

  useEffect(() => {
    ResultScheduler_API();
    dispatch(fetchThirtyTwoCardStack({ uid, gameId: id, token }));
  }, [dispatch, reqRes?.data?.data?.t1[0]?.mid]);

  const handleModal = (value, nat, no) => {
    setbetDetails({
      ...betDetails,
      gameid: id,
      multipler: value,
      period: 0,
      nat: nat,
      number: no,
    });
    setShowModal(true);
    setRealTimeData(true);
  };

  function checkValid() {
    if (showModal && realTime) {
      let filteredData = reqRes?.data?.data?.t2.filter(
        (item) => item.nat === betDetails.nat
      );
      // console.log("filter", filteredData, betDetails);
      filteredData[0]?.gstatus !== "0" &&
      betDetails?.nat === filteredData[0]?.nat &&
      betDetails.multipler === filteredData[0].b1
        ? setRealTimeData(true)
        : setRealTimeData(false);
    }
  }

  useEffect(() => {
    checkValid();
  }, [showModal, realTime, reqRes?.data?.data?.t2, betDetails]);

  const findCardImage = (cardId) => {
    const card = card52War.find((card) => card.id === cardId);
    return card ? card.src : null;
  };

  const HistoryTable = (
    <>
      <div className="table-h-div trxtable row table-responsive">
        <table>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Period</th>
              <th>Multiplier</th>
              <th>Amount</th>
              <th>Date</th>
              <th>Remark</th>
              <th>Card</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {history.length > 0 ? (
              history.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.period}</td>
                  <td>{item.multipler}</td>
                  <td>{item.amount}</td>
                  <td>{item.dd}</td>
                  <td className={item.remark === "Loss" ? "zero" : "success-1"}>
                    {item.remark}
                  </td>
                  <td>{item.card}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  No Data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );

  return (
    <>
      <div className="col-lg-10 ">
        <div className="row home mainhomeDev">
          <div className="col-lg-8 p-1">
            <div className="teenpatti-game-div pt-2 p-2">
              <div className="teenpatti-game-h d-flex justify-content-between  ">
                <div className=" px-2">
                  <p className=" mt-1 ">{name}</p>
                </div>
                <div>
                  <p className="mt-1 px-2">
                    Round ID: {reqRes?.data?.data?.t1[0]?.mid || 0.0} | Left
                    Time: {reqRes?.data?.data?.t1[0]?.autotime || 0} |{" "}
                    <span onClick={() => setRulesModal(true)}>Rules</span>
                  </p>
                </div>
              </div>
              <div className="card  teenpatti-game-card p-1 mt-1">
                <div className="row">
                  <div className="col-10">
                    <div className="" style={{ height: "170%", width: "120%" }}>
                      <iframe
                        height="100%"
                        width="100%"
                        src={`https://battlemoney.live/iframe/${id}`}
                        title=""
                      ></iframe>
                    </div>
                  </div>
                  <div className="col-2 d-flex justify-content-end">
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round"></span>
                    </label>
                  </div>
                  <div
                    className="timer "
                    style={{ marginLeft: "550px", marginTop: "20px" }}
                  >
                    <div className="container">
                    <div
                      className="timer_container position-absolute"
                      style={{ right: "36px", bottom: "15px" }}
                    >
                      <button
                        class="btn btn-secondary"
                        style={{ cursor: "default", background: "#6f42c1" }}
                      >
                        <span>
                          {" "}
                          {reqRes?.data?.data?.t1[0]?.autotime || 0}{" "}
                        </span>
                      </button>{" "}
                    </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <-------------------------------Back-lay-table----------> */}
              <div className="row bg-light">
                <div className="col-md-4">
                  <PieChart
                    series={[
                      {
                        data: [
                          {
                            id: 0,
                            value: graphData?.data?.graphdata?.T,
                            label: "Tie",
                          },
                          {
                            id: 1,
                            value: graphData?.data?.graphdata?.P,
                            label: "Player",
                          },
                          {
                            id: 2,
                            value: graphData?.data?.graphdata?.B,
                            label: "Banker",
                          },
                        ],
                      },
                    ]}
                    width={200}
                    height={140}
                  />
                </div>
                <div className="col-md-8">
                  <div className="row text-center mx-0">
                    <div className="col-md-3 col-3">
                      {reqRes?.data?.data?.t2[5]?.gstatus == 0 ? (
                        <div
                          className="btn border-0 text-white suspended-box"
                          style={{ background: "#2c3e50", fontSize: "10px" }}
                        >
                          {reqRes?.data?.data?.t2[5]?.nat}{" "}
                          {reqRes?.data?.data?.t2[5]?.b1}
                        </div>
                      ) : (
                        <button
                          onClick={() => {
                            setShowdataModal(true);
                            handleModal(
                              reqRes?.data?.data?.t2[5]?.b1,
                              reqRes?.data?.data?.t2[5]?.nat,
                              1
                            );
                          }}
                          className="btn border-0 text-white"
                          style={{ background: "#2c3e50", fontSize: "10px" }}
                        >
                          {reqRes?.data?.data?.t2[5]?.nat}{" "}
                          <span>{reqRes?.data?.data?.t2[5]?.b1}</span>
                        </button>
                      )}

                      <p className="text-center text-success">
                        {thirtyTwoCardStack.map(
                          (ele) =>
                            ele.number === 1 && (
                              <span
                                className={`${
                                  ele.number == 1 && ele.amount >= 0
                                    ? "success-1"
                                    : "zero"
                                }`}
                              >
                                {ele.number == 1 && ele.amount}
                              </span>
                            )
                        )}
                      </p>
                    </div>
                    <div className="col-md-3 col-3">
                      {reqRes?.data?.data?.t2[6]?.gstatus == 0 ? (
                        <div
                          className="btn border-0 text-white suspended-box"
                          style={{ background: "#2c3e50", fontSize: "10px" }}
                        >
                          {reqRes?.data?.data?.t2[6]?.nat}{" "}
                          {reqRes?.data?.data?.t2[6]?.b1}
                        </div>
                      ) : (
                        <button
                          onClick={() => {
                            setShowdataModal(true);
                            handleModal(
                              reqRes?.data?.data?.t2[6]?.b1,
                              reqRes?.data?.data?.t2[6]?.nat,
                              2
                            );
                          }}
                          className="btn border-0 text-white"
                          style={{ background: "#2c3e50", fontSize: "10px" }}
                        >
                          {reqRes?.data?.data?.t2[6]?.nat}{" "}
                          <span>{reqRes?.data?.data?.t2[6]?.b1}</span>
                        </button>
                      )}

                      <p className="text-center text-success">
                        {" "}
                        {thirtyTwoCardStack.map(
                          (ele) =>
                            ele.number === 2 && (
                              <span
                                className={`${
                                  ele.number == 2 && ele.amount >= 0
                                    ? "success-1"
                                    : "zero"
                                }`}
                              >
                                {ele.number == 2 && ele.amount}
                              </span>
                            )
                        )}
                      </p>
                    </div>
                    <div className="col-md-3 col-3">
                      {reqRes?.data?.data?.t2[7]?.gstatus == 0 ? (
                        <div
                          className="btn border-0 text-white suspended-box"
                          style={{ background: "#2c3e50", fontSize: "10px" }}
                        >
                          {reqRes?.data?.data?.t2[7]?.nat}{" "}
                          {reqRes?.data?.data?.t2[7]?.b1}
                        </div>
                      ) : (
                        <button
                          onClick={() => {
                            setShowdataModal(true);
                            handleModal(
                              reqRes?.data?.data?.t2[7]?.b1,
                              reqRes?.data?.data?.t2[7]?.nat,
                              3
                            );
                          }}
                          className="btn border-0 text-white"
                          style={{ background: "#2c3e50", fontSize: "10px" }}
                        >
                          {reqRes?.data?.data?.t2[7]?.nat}{" "}
                          <span>{reqRes?.data?.data?.t2[7]?.b1}</span>
                        </button>
                      )}

                      <p className="text-center text-success">
                        {thirtyTwoCardStack.map(
                          (ele) =>
                            ele.number === 3 && (
                              <span
                                className={`${
                                  ele.number == 3 && ele.amount >= 0
                                    ? "success-1"
                                    : "zero"
                                }`}
                              >
                                {ele.number == 3 && ele.amount}
                              </span>
                            )
                        )}
                      </p>
                    </div>
                    <div className="col-md-3 col-3">
                      {reqRes?.data?.data?.t2[8]?.gstatus == 0 ? (
                        // <div className=" btn border-0  suspended-box"> </div>
                        <div
                          className="btn border-0 text-white suspended-box"
                          style={{ background: "#2c3e50", fontSize: "10px" }}
                        >
                          {reqRes?.data?.data?.t2[8]?.nat}{" "}
                          {reqRes?.data?.data?.t2[8]?.b1}
                        </div>
                      ) : (
                        <button
                          onClick={() => {
                            setShowdataModal(true);
                            handleModal(
                              reqRes?.data?.data?.t2[8]?.b1,
                              reqRes?.data?.data?.t2[8]?.nat,
                              4
                            );
                          }}
                          className="btn border-0 text-white"
                          style={{ background: "#2c3e50", fontSize: "10px" }}
                        >
                          {reqRes?.data?.data?.t2[8]?.nat}{" "}
                          <span>{reqRes?.data?.data?.t2[8]?.b1}</span>
                        </button>
                      )}
                      {/* <button
                        className="btn border-0 text-white"
                        style={{ background: "#2c3e50", fontSize: "12px" }}
                      >
                        Either Pair <span>26:1</span>
                      </button> */}
                      <p className="text-center text-success">
                        {thirtyTwoCardStack.map(
                          (ele) =>
                            ele.number === 4 && (
                              <span
                                className={`${
                                  ele.number == 4 && ele.amount >= 0
                                    ? "success-1"
                                    : "zero"
                                }`}
                              >
                                {ele.number == 4 && ele.amount}
                              </span>
                            )
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="row mx-0 fivecard">
                    <div className="col-12 d-flex">
                      <div className="firstcol">
                        {/* <div className="cardinne"> */}
                        {reqRes?.data?.data?.t2[3]?.gstatus == 0 ? (
                          <div className="cardinne suspended-box-first">
                            <div className="pair">
                              {reqRes?.data?.data?.t2[3]?.nat}
                            </div>
                            <div className="numberone">
                              {reqRes?.data?.data?.t2[3]?.b1}
                            </div>
                          </div>
                        ) : (
                          <div
                            onClick={() => {
                              setShowdataModal(true);
                              handleModal(
                                reqRes?.data?.data?.t2[3]?.b1,
                                reqRes?.data?.data?.t2[3]?.nat,
                                5
                              );
                            }}
                            className="cardinne"
                          >
                            <div className="pair">
                              {reqRes?.data?.data?.t2[3]?.nat}
                            </div>
                            <div className="numberone">
                              {reqRes?.data?.data?.t2[3]?.b1}
                            </div>
                          </div>
                        )}

                        <p className="text-black text-center">
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 5 && (
                                <span
                                  className={`${
                                    ele.number == 5 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}
                                >
                                  {ele.number == 5 && ele.amount}
                                </span>
                              )
                          )}
                        </p>
                      </div>
                      <div className="middlecol">
                 
                        <div className="w-100">
                          {reqRes?.data?.data?.t2[0]?.gstatus == 0 ? (
                            <div className="col-first suspended-box">
                              <div className="middletext ">
                                {reqRes?.data?.data?.t2[0]?.nat}
                                <br />
                                {reqRes?.data?.data?.t2[0]?.b1}
                                <div className="d-flex align-items-center ">
                                  {reqRes?.data?.data?.t1[0].C5 !== "1" && (
                                    <div
                                      className="imagecard"
                                      style={{ marginTop: "-11px" }}
                                    >
                                      <img
                                        src={
                                          findCardImage(
                                            reqRes?.data?.data?.t1[0].C5
                                          ) || cardBlank
                                        }
                                        alt={reqRes?.data?.data?.t1[0].C5}
                                        style={{
                                          width: "20px",
                                          height: "auto",
                                          transform: "rotate(-90deg)", // Rotate the image 90 degrees
                                        }}
                                      />
                                    </div>
                                  )}

                                   {["C3", "C1"].map((key, index) => {
                                    const cardId =
                                      reqRes?.data?.data?.t1[0]?.[key];
                                    return cardId ? ( 
                                      <div
                                        className="imagecard"
                                        style={{ marginTop: "-11px" }}
                                        key={index}
                                      >
                                        <img
                                          src={
                                            findCardImage(cardId) || cardBlank
                                          }
                                          alt={cardId}
                                          style={{
                                            width: "20px",
                                            height: "auto",
                                          }}
                                        />
                                      </div>
                                    ) : null;
                                  })}

                                  {/* <div className="imagecard">
                                  <img
                                    className="w-100 h-100"
                                    src={card}
                                    alt=""
                                  />
                                </div>
                                <div className="imagecard">
                                  <img
                                    className="w-100 h-100"
                                    src={card}
                                    alt=""
                                  />
                                </div> */}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="col-first">
                              <div
                                onClick={() => {
                                  setShowdataModal(true);
                                  handleModal(
                                    reqRes?.data?.data?.t2[0]?.b1,
                                    reqRes?.data?.data?.t2[0]?.nat,
                                    6
                                  );
                                }}
                                className="middletext"
                              >
                                {reqRes?.data?.data?.t2[0]?.nat}
                                <br />
                                {reqRes?.data?.data?.t2[0]?.b1}
                                <div className="d-flex align-items-center gap-2">
                                  <div className="imagecard">
                                    <img
                                      className="w-100 h-100"
                                      src={card}
                                      alt=""
                                    />
                                  </div>
                                  <div className="imagecard">
                                    <img
                                      className="w-100 h-100"
                                      src={card}
                                      alt=""
                                    />
                                  </div>
                                </div>
                                {/* <p className="text-black">text</p> */}
                              </div>
                            </div>
                          )}
                          <p className="text-black text-center">
                            {thirtyTwoCardStack.map(
                              (ele) =>
                                ele.number === 6 && (
                                  <span
                                    className={`${
                                      ele.number == 6 && ele.amount >= 0
                                        ? "success-1"
                                        : "zero"
                                    }`}
                                  >
                                    {ele.number == 6 && ele.amount}
                                  </span>
                                )
                            )}
                          </p>
                        </div>

                        {/* {" "}
                            Player <br />
                            2:1 */}
                        {/* </div> */}
                        {/* <div className="d-flex align-items-center gap-2">
                            <div className="imagecard">
                              <img className="w-100 h-100" src={card} alt="" />
                            </div>
                            <div className="imagecard">
                              <img className="w-100 h-100" src={card} alt="" />
                            </div>
                          </div> */}
                        {/* <p className="text-black">text</p> */}
                        {/* </div> */}
                        {/* <div className="col-second"> */}
                        {/* <div className="middletext text-center"> */}
                        <div className="w-100">
                          {reqRes?.data?.data?.t2[2]?.gstatus == 0 ? (
                            <div className="col-second suspended-box">
                              <div className="middletext text-center ">
                                {reqRes?.data?.data?.t2[2]?.nat}
                                <br />
                                {reqRes?.data?.data?.t2[2]?.b1}
                                <br />
                              </div>
                            </div>
                          ) : (
                            <div className="col-second">
                              <div
                                onClick={() => {
                                  setShowdataModal(true);
                                  handleModal(
                                    reqRes?.data?.data?.t2[2]?.b1,
                                    reqRes?.data?.data?.t2[2]?.nat,
                                    7
                                  );
                                }}
                                className="middletext text-center"
                                // style={{ background: "#2c3e50", fontSize: "11px" }}
                              >
                                {reqRes?.data?.data?.t2[2]?.nat}
                                <br />
                                {reqRes?.data?.data?.t2[2]?.b1}
                                <br />
                                {/* <p className="text-black">text</p> */}
                              </div>
                            </div>
                          )}
                          <p className="text-black text-center">
                            {thirtyTwoCardStack.map(
                              (ele) =>
                                ele.number === 7 && (
                                  <span
                                    className={`${
                                      ele.number == 7 && ele.amount >= 0
                                        ? "success-1"
                                        : "zero"
                                    }`}
                                  >
                                    {ele.number == 7 && ele.amount}
                                  </span>
                                )
                            )}
                          </p>
                        </div>

                        <div className="w-100">
                          {reqRes?.data?.data?.t2[1]?.gstatus == 0 ? (
                            <div className="col-thierd suspended-box">
                              <div className="middletext">
                                {reqRes?.data?.data?.t2[1]?.nat}
                                <br />
                                {reqRes?.data?.data?.t2[1]?.b1}
                                <div className="d-flex align-items-center">
                                 

                                {["C2", "C4"].map((key, index) => {
                                    const cardId =
                                      reqRes?.data?.data?.t1[0]?.[key];
                                    return cardId ? (
                                      <div
                                        className="imagecard"
                                        style={{ marginTop: "-11px" }}
                                        key={index}
                                      >
                                        <img
                                          src={
                                            findCardImage(cardId) || cardBlank
                                          }
                                          alt={cardId}
                                          style={{
                                            width: "20px",
                                            height: "auto",
                                          }}
                                        />
                                      </div>
                                    ) : null;
                                  })}
                                   {reqRes?.data?.data?.t1[0].C6 !== "1" && (
                                    <div
                                      className="imagecard"
                                      style={{ marginTop: "-11px" }}
                                    >
                                      <img
                                        src={
                                          findCardImage(
                                            reqRes?.data?.data?.t1[0].C6
                                          ) || cardBlank
                                        }
                                        alt={reqRes?.data?.data?.t1[0].C6}
                                        style={{
                                          width: "20px",
                                          height: "auto",
                                          transform: "rotate(90deg)", // Rotate the image 90 degrees
                                        }}
                                      />
                                    </div>
                                  )}

                                  {/* <div className="imagecard">
                                  <img
                                    className="w-100 h-100"
                                    src={card}
                                    alt=""
                                  />
                                </div>
                                <div className="imagecard">
                                  <img
                                    className="w-100 h-100"
                                    src={card}
                                    alt=""
                                  />
                                </div> */}
                                </div>
                              </div>
                            </div>
                          ) : (
                            // <div className="middletext">
                            //   <div className="pair">Banker</div>
                            // </div>

                            <div className="col-thierd">
                              <div
                                onClick={() => {
                                  setShowdataModal(true);
                                  handleModal(
                                    reqRes?.data?.data?.t2[1]?.b1,
                                    reqRes?.data?.data?.t2[1]?.nat,
                                    8
                                  );
                                }}
                                className="middletext"
                                // style={{ background: "#2c3e50", fontSize: "11px" }}
                              >
                                {reqRes?.data?.data?.t2[1]?.nat}
                                <br />
                                {reqRes?.data?.data?.t2[1]?.b1}
                                <div className="d-flex align-items-center gap-2">
                                  <div className="imagecard">
                                    <img
                                      className="w-100 h-100"
                                      src={card}
                                      alt=""
                                    />
                                  </div>
                                  <div className="imagecard">
                                    <img
                                      className="w-100 h-100"
                                      src={card}
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          <p className="text-black text-center">
                            {thirtyTwoCardStack.map(
                              (ele) =>
                                ele.number === 8 && (
                                  <span
                                    className={`${
                                      ele.number == 8 && ele.amount >= 0
                                        ? "success-1"
                                        : "zero"
                                    }`}
                                  >
                                    {ele.number == 8 && ele.amount}
                                  </span>
                                )
                            )}
                          </p>
                        </div>
                        {/* {" "}
                            Player <br />
                            2:1 */}
                        {/* </div> */}

                        {/* </div> */}
                      </div>

                      <div className="lastcol">
                        {reqRes?.data?.data?.t2[4]?.gstatus == 0 ? (
                          <div className="cardinne suspended-box-last">
                            {reqRes?.data?.data?.t2[4]?.nat}
                            <br />
                            {reqRes?.data?.data?.t2[4]?.b1}
                          </div>
                        ) : (
                          <div
                            onClick={() => {
                              setShowdataModal(true);
                              handleModal(
                                reqRes?.data?.data?.t2[4]?.b1,
                                reqRes?.data?.data?.t2[4]?.nat,
                                9
                              );
                            }}
                            className="cardinne"
                          >
                            {reqRes?.data?.data?.t2[4]?.nat}
                            <br />
                            {reqRes?.data?.data?.t2[4]?.b1}
                          </div>
                        )}
                        {/* <div className="cardinne">
                          <div className="pair">Player Pair</div>
                          <div className="number">8:11</div>
                        </div> */}
                        <p className="text-black">
                          {" "}
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 9 && (
                                <span
                                  className={`${
                                    ele.number == 9 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}
                                >
                                  {ele.number == 9 && ele.amount}
                                </span>
                              )
                          )}
                        </p>
                      </div>
                    </div>
                    {/* <div className="text-center textas">
                      <p className="text-black">text</p>
                      <p className="text-black">text</p>
                      <p className="text-black">text</p>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="Results-table">
                <div className="color-game-h mt-2">
                  <div className="px-2 pt-1">
                    <p>Last 10 Results</p>
                  </div>
                </div>
                <div className="card rst-card py-1 d-flex">
                  <div className="rst-row d-flex">
                    {results?.data?.result?.map((result, index) => {
                      const item = reqRes?.data?.data?.t2.find(
                        (item) => item?.sid === result?.result
                      );

                      return (
                        <div
                          onClick={() => {
                            setMidRound(result.mid);
                            CasinoGameResult_API(result.mid);
                            setShowresultModal(true);
                            setTrophy(item.nat[0]);
                          }}
                          className="rst-btn ms-1"
                          key={index}
                        >
                          {item && <>{item.nat[0]}</>}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              {/* <<<<<<<---------My-Bets-table-------------->>>>>>>>>> */}
              <div className="My-Bets-table">
                <div className="color-game-h mt-2">
                  <div className="px-2 pt-1">
                    <p>Open Bets</p>
                  </div>
                </div>
                {HistoryTable}
                {/* <div className="card-table-inner-row row"></div> */}
              </div>
            </div>
          </div>

          <RightSidebar />
          {showModal && (
            <Modal
              id={id}
              name={name}
              betDetails={betDetails}
              setbetDetails={setbetDetails}
              setShowModal={setShowModal}
              realTime={realTime}
              setRealTimeData={setRealTimeData}
            />
          )}
          {showresultModal && <ResultsModal />}
          {/* {showDataModal && <DataModal />} */}
          {rulesModal && <Rules_Modal closeFunction={setRulesModal} />}
        </div>
      </div>
    </>
  );
}
