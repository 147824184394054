export const numberButton = [
  {
    className: "small-red-btn1",
    value: 3,
    label: "3",
    color: "Red",
    multiplier: 207.36,
    btn: false,
  },
  {
    className: "small-green-btn1",
    value: 4,
    label: "4",
    color: "Green",
    multiplier: 69.12,
    btn: false,
  },
  {
    className: "small-red-btn1",
    value: 5,
    label: "5",
    color: "Red",
    multiplier: 34.56,
    btn: false,
  },
  {
    className: "small-green-btn1",
    value: 6,
    label: "6",
    color: "Green",
    multiplier: 20.74,
    btn: false,
  },
  {
    className: "small-red-btn1",
    value: 7,
    label: "7",
    color: "Red",
    multiplier: 13.83,
    btn: false,
  },
  {
    className: "small-green-btn1",
    value: 8,
    label: "8",
    color: "Green",
    multiplier: 9.88,
    btn: false,
  },
  {
    className: "small-red-btn1",
    value: 9,
    label: "9",
    color: "Red",
    multiplier: 8.3,
    btn: false,
  },
  {
    className: "small-green-btn1",
    value: 10,
    label: "10",
    color: "Green",
    multiplier: 7.68,

    btn: false,
  },
  {
    className: "small-red-btn1",
    value: 11,
    label: "11",
    color: "Red",
    multiplier: 7.68,

    btn: false,
  },
  {
    className: "small-green-btn1",
    value: 12,
    label: "12",
    color: "Green",
    multiplier: 8.3,
    btn: false,
  },
  {
    className: "small-red-btn1",
    value: 13,
    label: "13",
    color: "Red",
    multiplier: 9.88,

    btn: false,
  },
  {
    className: "small-green-btn1",
    value: 14,
    label: "14",
    color: "Green",
    multiplier: 13.83,

    btn: false,
  },
  {
    className: "small-red-btn1",
    value: 15,
    label: "15",
    color: "Red",
    multiplier: 20.74,

    btn: false,
  },
  {
    className: "small-green-btn1",
    value: 16,
    label: "16",
    color: "Green",
    multiplier: 34.56,

    btn: false,
  },
  {
    className: "small-red-btn1",
    value: 17,
    label: "17",
    color: "Red",
    multiplier: 69.12,

    btn: false,
  },
  {
    className: "small-green-btn1",
    value: 18,
    label: "18",
    color: "Green",
    multiplier: 207.36,

    btn: false,
  },
];

export const bigSmallButton = [
  {
    className: "btas2",
    color: "Big",
    label: "Big",
    value: 25,
    btn: true,
  },
  {
    className: "btas3",
    color: "Small",
    label: "Small",
    value: 20,
    btn: true,
  },
  {
    className: "btas2",
    color: "Odd",
    label: "Odd",
    value: 35,
    btn: true,
  },
  {
    className: "btas3 ",
    color: "Even",
    label: "Even",
    value: 30,
    btn: true,
  },
];
export const twosameButton = [
  { number: 11, value: 36, color: "Red", btn: false },
  { number: 22, value: 37, color: "Red", btn: false },
  { number: 33, value: 38, color: "Red", btn: false },
  { number: 44, value: 39, color: "Red", btn: false },
  { number: 55, value: 40, color: "Red", btn: false },
  { number: 66, value: 41, color: "Red", btn: false },
];

export const threesameButton = [
  { number: 111, value: 43, color: "Red", btn: false },
  { number: 222, value: 44, color: "Red", btn: false },
  { number: 333, value: 45, color: "Red", btn: false },
  { number: 444, value: 46, color: "Red", btn: false },
  { number: 555, value: 47, color: "Red", btn: false },
  { number: 666, value: 48, color: "Red", btn: false },
];

export const tabs = [
  {
    id: "home-tab",
    target: "#home-tab-pane",
    text: "Total",
    ariaControls: "home-tab-pane",
    ariaSelected: "false",
    tabIndex: 0,
  },
  {
    id: "profile-tab",
    target: "#profile-tab-pane",
    text: "2 same",
    ariaControls: "profile-tab-pane",
    ariaSelected: "false",
    tabIndex: 1,
  },
  {
    id: "contact-tab",
    target: "#contact-tab-pane",
    text: "3 same",
    ariaControls: "contact-tab-pane",
    ariaSelected: "false",
    tabIndex: 2,
  },
  {
    id: "disabled-tab",
    target: "#disabled-tab-pane",
    text: "Different",
    ariaControls: "disabled-tab-pane",
    ariaSelected: "true",
    tabIndex: 3,
  },
];

export const threeDifferentButton = [
  { number: 123, value: 89, color: "Red", btn: false },
  { number: 134, value: 90, color: "Red", btn: false },
  { number: 145, value: 91, color: "Red", btn: false },
  { number: 156, value: 92, color: "Red", btn: false },
  { number: 213, value: 93, color: "Red", btn: false },
  { number: 234, value: 94, color: "Red", btn: false },
  { number: 245, value: 95, color: "Red", btn: false },
  { number: 256, value: 96, color: "Red", btn: false },
  { number: 312, value: 97, color: "Red", btn: false },
  { number: 324, value: 98, color: "Red", btn: false },
  { number: 345, value: 99, color: "Red", btn: false },
  { number: 356, value: 100, color: "Red", btn: false },
  { number: 412, value: 101, color: "Red", btn: false },
  { number: 423, value: 102, color: "Red", btn: false },
  { number: 435, value: 103, color: "Red", btn: false },
  { number: 456, value: 104, color: "Red", btn: false },
  { number: 512, value: 105, color: "Red", btn: false },
  { number: 523, value: 106, color: "Red", btn: false },
  { number: 534, value: 107, color: "Red", btn: false },
  { number: 546, value: 108, color: "Red", btn: false },
  { number: 612, value: 109, color: "Red", btn: false },
  { number: 623, value: 110, color: "Red", btn: false },
  { number: 634, value: 111, color: "Red", btn: false },
  { number: 645, value: 112, color: "Red", btn: false },
];

export const twoDifferentButton = [
  { number: 12, value: 113, color: "Red", btn: false },
  { number: 13, value: 114, color: "Red", btn: false },
  { number: 14, value: 115, color: "Red", btn: false },
  { number: 15, value: 116, color: "Red", btn: false },
  { number: 16, value: 117, color: "Red", btn: false },
  { number: 21, value: 118, color: "Red", btn: false },
  { number: 23, value: 119, color: "Red", btn: false },
  { number: 24, value: 200, color: "Red", btn: false },
  { number: 25, value: 201, color: "Red", btn: false },
  { number: 26, value: 202, color: "Red", btn: false },
  { number: 31, value: 203, color: "Red", btn: false },
  { number: 32, value: 204, color: "Red", btn: false },
  { number: 34, value: 205, color: "Red", btn: false },
  { number: 35, value: 206, color: "Red", btn: false },
  { number: 36, value: 207, color: "Red", btn: false },
  { number: 41, value: 208, color: "Red", btn: false },
  { number: 42, value: 209, color: "Red", btn: false },
  { number: 43, value: 210, color: "Red", btn: false },
  { number: 45, value: 211, color: "Red", btn: false },
  { number: 46, value: 212, color: "Red", btn: false },
  { number: 51, value: 213, color: "Red", btn: false },
  { number: 52, value: 214, color: "Red", btn: false },
  { number: 53, value: 215, color: "Red", btn: false },
  { number: 54, value: 216, color: "Red", btn: false },
  { number: 56, value: 228, color: "Red", btn: false },
  { number: 61, value: 217, color: "Red", btn: false },
  { number: 62, value: 218, color: "Red", btn: false },
  { number: 63, value: 219, color: "Red", btn: false },
  { number: 64, value: 220, color: "Red", btn: false },
  { number: 65, value: 221, color: "Red", btn: false },
];

export const twoSameOneDifferentButton = [
  { number: 112, value: 50, color: "Red", btn: false },
  { number: 113, value: 51, color: "Red", btn: false },
  { number: 114, value: 52, color: "Red", btn: false },
  { number: 115, value: 53, color: "Red", btn: false },
  { number: 116, value: 54, color: "Red", btn: false },

  { number: 221, value: 55, color: "Red", btn: false },
  { number: 223, value: 57, color: "Red", btn: false },
  { number: 224, value: 58, color: "Red", btn: false },
  { number: 225, value: 59, color: "Red", btn: false },
  { number: 226, value: 60, color: "Red", btn: false },

  { number: 331, value: 61, color: "Red", btn: false },
  { number: 332, value: 62, color: "Red", btn: false },
  { number: 334, value: 64, color: "Red", btn: false },
  { number: 335, value: 65, color: "Red", btn: false },
  { number: 336, value: 66, color: "Red", btn: false },

  { number: 441, value: 67, color: "Red", btn: false },
  { number: 442, value: 68, color: "Red", btn: false },
  { number: 443, value: 69, color: "Red", btn: false },
  { number: 445, value: 71, color: "Red", btn: false },
  { number: 446, value: 72, color: "Red", btn: false },

  { number: 551, value: 73, color: "Red", btn: false },
  { number: 552, value: 74, color: "Red", btn: false },
  { number: 553, value: 75, color: "Red", btn: false },
  { number: 554, value: 76, color: "Red", btn: false },
  { number: 556, value: 77, color: "Red", btn: false },

  { number: 661, value: 78, color: "Red", btn: false },
  { number: 662, value: 79, color: "Red", btn: false },
  { number: 663, value: 80, color: "Red", btn: false },
  { number: 664, value: 81, color: "Red", btn: false },
  { number: 665, value: 82, color: "Red", btn: false },
];

export const getColorBetComponent = (colorBet, number) => {
  if (colorBet >= 0 && colorBet <= 18 && number <= 18) {
    if (colorBet % 2 !== 0) return <div className="numberR">{colorBet}</div>;
    return <div className="numberG">{colorBet}</div>;
  }

  if (number >= 222 && number <= 227) {
    return <div className="numberR">3=</div>;
  }

  if (number >= 85 && number <= 88) {
    return <div className="numberR">3≠</div>;
  }

  switch (colorBet) {
    case "Big":
      return <div className="numberB">Big</div>;
    case "Small":
      return <div className="numberS">Small</div>;
    case "Odd":
      return <div className="numberR">Odd</div>;
    case "Even":
      return <div className="numberG">Even</div>;
    case "11":
      return <div className="numberV">11</div>;
    case "12":
      return <div className="numberV">12</div>;
    case "13":
      return <div className="numberV">13</div>;
    case "14":
      return <div className="numberV">14</div>;
    case "15":
      return <div className="numberV">15</div>;
    case "16":
      return <div className="numberV">16</div>;
    case "22":
      return <div className="numberV">22</div>;
    case "23":
      return <div className="numberV">23</div>;
    case "24":
      return <div className="numberV">24</div>;
    case "25":
      return <div className="numberV">25</div>;
    case "26":
      return <div className="numberV">26</div>;
    case "33":
      return <div className="numberV">33</div>;
    case "34":
      return <div className="numberV">34</div>;
    case "35":
      return <div className="numberV">35</div>;
    case "36":
      return <div className="numberV">36</div>;

    case "44":
      return <div className="numberV">44</div>;
    case "45":
      return <div className="numberV">45</div>;
    case "46":
      return <div className="numberV">46</div>;
    case "55":
      return <div className="numberV">55</div>;
    case "56":
      return <div className="numberV">56</div>;
    case "66":
      return <div className="numberV">66</div>;
    case "111":
      return <div className="numberV">111</div>;
    case "123":
      return <div className="numberV">123</div>;
    case "124":
      return <div className="numberV">124</div>;
    case "125":
      return <div className="numberV">125</div>;
    case "126":
      return <div className="numberV">126</div>;
    case "134":
      return <div className="numberV">134</div>;
    case "135":
      return <div className="numberV">135</div>;
    case "136":
      return <div className="numberV">136</div>;
    case "145":
      return <div className="numberV">145</div>;
    case "146":
      return <div className="numberV">146</div>;
    case "156":
      return <div className="numberV">156</div>;
    case "222":
      return <div className="numberV">222</div>;
    case "234":
      return <div className="numberV">234</div>;
    case "235":
      return <div className="numberV">235</div>;
    case "236":
      return <div className="numberV">236</div>;
    case "245":
      return <div className="numberV">245</div>;
    case "246":
      return <div className="numberV">246</div>;
    case "256":
      return <div className="numberV">256</div>;
    case "333":
      return <div className="numberV">333</div>;
    case "345":
      return <div className="numberV">345</div>;
    case "346":
      return <div className="numberV">346</div>;
    case "356":
      return <div className="numberV">356</div>;
    case "444":
      return <div className="numberV">444</div>;
    case "456":
      return <div className="numberV">456</div>;

    case "555":
      return <div className="numberV">555</div>;
    case "666":
      return <div className="numberV">666</div>;
    case "112":
      return <div className="numberV">11|2</div>;
    case "113":
      return <div className="numberV">11|3</div>;
    case "114":
      return <div className="numberV">11|4</div>;
    case "115":
      return <div className="numberV">11|5</div>;
    case "116":
      return <div className="numberV">11|6</div>;
    case "221":
      return <div className="numberV">22|1</div>;
    case "223":
      return <div className="numberV">22|3</div>;
    case "224":
      return <div className="numberV">22|4</div>;
    case "225":
      return <div className="numberV">22|5</div>;
    case "226":
      return <div className="numberV">22|6</div>;
    case "331":
      return <div className="numberV">33|1</div>;
    case "332":
      return <div className="numberV">33|2</div>;
    case "334":
      return <div className="numberV">33|4</div>;
    case "335":
      return <div className="numberV">33|5</div>;
    case "336":
      return <div className="numberV">33|6</div>;
    case "441":
      return <div className="numberV">44|1</div>;
    case "442":
      return <div className="numberV">44|2</div>;
    case "443":
      return <div className="numberV">44|3</div>;
    case "445":
      return <div className="numberV">44|5</div>;
    case "446":
      return <div className="numberV">44|6</div>;
    case "551":
      return <div className="numberV">55|1</div>;
    case "552":
      return <div className="numberV">55|2</div>;
    case "553":
      return <div className="numberV">55|3</div>;
    case "554":
      return <div className="numberV">55|4</div>;
    case "556":
      return <div className="numberV">55|6</div>;
    case "661":
      return <div className="numberV">66|1</div>;
    case "662":
      return <div className="numberV">66|2</div>;
    case "663":
      return <div className="numberV">66|3</div>;
    case "664":
      return <div className="numberV">66|4</div>;
    case "665":
      return <div className="numberV">66|5</div>;
    default:
      return null;
  }
};
