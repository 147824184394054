import React from "react";
import relot from "../../Media/American-Roulette.png";
import { NavLink } from "react-router-dom";
import games from "../casino_game.json";
const SmallCards = () => {
  return (
    <>
      {games?.map((list, index) => (
        <div className="col-6" key={index}>
          <NavLink
          //  to={`${list.Route}`} 
           className="nav-link">
            <div className="card smallcard-div ms-2 mt-3">
              <img
                className=" small-card-img"
                src={list.images}
                alt={list.name}
              />
              <div className="overlaySmallCard d-flex justify-content-center">
                <p className="">{list.name}</p>
              </div>
            </div>
          </NavLink>
        </div>
      ))}
      {/* <div className="col-6">
        <NavLink to="/Roulette" className="nav-link">
          <div className="card smallcard-div ms-2 mt-3">
            <img className=" small-card-img" src={relot} alt="a" />
            <div className="overlaySmallCard d-flex justify-content-center">
              <p className="">Roulette</p>
            </div>
          </div>
        </NavLink>
      </div> */}
    </>
  );
};

export default SmallCards;
