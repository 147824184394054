import React, { useEffect, useState } from "react";
import RightSidebar from "../RightSidebar/RIghtSidebar";
import { useNavigate } from "react-router-dom";
import { API } from "../../API/API";
import { logoutUser } from "../../Redux/Slices/authSlice";
import { useDispatch } from "react-redux";
import { io } from "socket.io-client";
function Ipl2024() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [marketid, setMarketid] = useState(0);
  const [eventTypeid, setEventTypeid] = useState(0);
  const [showMatchOdds, setshowMatchOdds] = useState(false);
  const [setRes, setSetRes] = useState([]);
  const [DataForCash, setDataForCash] = useState([]);

  //   console.log("setRes", setRes);
  const token = sessionStorage.getItem("token");
  const Highlightmatch_API = async () => {
    try {
      let res = await API.get(`/Highlightmatch?username=${sessionStorage.getItem("userName")}`,
        null,
        {
          headers: {
            Authorization: token,
          },
        });

      //   console.log("eventID_API", res);
      const data = res?.data?.data[0][0];
      //   console.log("data", data);
      setMarketid(data.MarketID);
      setEventTypeid(data.eventTypeid);
    } catch (e) {
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };
  useEffect(() => {
    Highlightmatch_API();
  }, []);

  const SOCKET_URL = "https://dreamwin-socket.nakshtech.info/";

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("CricketOddMatchType", marketid, eventTypeid);
    cricketMatchesSocket.on("CricketOddMatchType_FromAPI", (data) => {
      //   console.log("IpL==>", data);

      if (Array.isArray(data) && data.length > 0 && data[0] !== null) {
        let res = JSON.parse(data);
        setshowMatchOdds(true);
        setSetRes(res);
        setDataForCash(res.runners);
      } else {
        setshowMatchOdds(false);
      }
    });
    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, [marketid]);

  return (
    <div className="col-lg-10  pt-5 pt-3  ">
      <div className="row px-1">
        <div className="col-lg-8  px-2 score-card">
          <div className="row  bg-dark mb-3">
            <div className="Match-ods-h d-flex justify-content-between align-items-center">
              <p className="mx-2 mt-3 d-flex">INDIAN PREMIER LEAGUE</p>
              <p className="mx-2 mt-3">3/1/2024 4:30:00 PM</p>
            </div>
          </div>

          <div className="row  bg-dark mb-3">
            <div className="Match-ods-h d-flex justify-content-between align-items-center">
              <p className="mx-2 mt-3 d-flex">Bookmaker IPL CUP market</p>
            </div>
            <div className="row back-lay-row  bg-dark ">
              <div className="col-5 ">
                <p className="ms-2 mt-1 mb-0 d-flex ">Min: 100 Max: 50K</p>
              </div>
              <div className="col-7 coleft ">
                <button className="back ">Back</button>
                <button className="lay ms-1">Lay</button>
              </div>
            </div>
            <div className="betting-team-info">
              <div className="row mt-1 bord">
                {DataForCash.slice()
                  .reverse()
                  .map((runner, index) => (
                    <>
                      <div className="col-md-5 betting-t-n" key={index}>
                        <p className="ms-2 mb-0 d-flex">{runner.runner}</p>
                        <p className="ms-2">{runner.lastPriceTraded}</p>
                      </div>
                      <div className="col-md-7 d-flex">
                        <div className="blue-bet-btn blue-bet-btn2 ms-1 mb-0"></div>
                        <div className="blue-bet-btn blue-bet-btn1 ms-1 mb-0"></div>
                        <div className="blue-bet-btn ms-1 mb-0">
                          {runner.ex.availableToBack?.map((item, index) => {
                            if (index === 0) {
                              return (
                                <div
                                  key={index}
                                  className="d-flex flex-column align-items-center mt-1">
                                  {item.price}
                                  <span className="fot">{item.size}</span>
                                </div>
                              );
                            }
                            return null;
                          })}
                          {/* <div className="d-flex flex-column align-items-center mt-1">
                        500
                        <span className="fot">50K</span>
                      </div> */}
                        </div>
                        <div className=" orange-bet-btn ms-1 mb-0">
                          {runner.ex.availableToLay?.map((item, index) => {
                            if (index === 0) {
                              return (
                                <div
                                  key={index}
                                  className="d-flex flex-column align-items-center mt-1">
                                  {item.price}
                                  <span className="fot">{item.size}</span>
                                </div>
                              );
                            }
                            return null;
                          })}
                          {/* <div className="d-flex flex-column align-items-center mt-1">
                            500
                            <span className="fot">50K</span>
                          </div> */}
                        </div>

                        <div className="orange-bet-btn  orange-bet-btn1 ms-1 mb-0"></div>
                        <div className="orange-bet-btn orange-bet-btn2 ms-1 mb-0"></div>
                      </div>
                    </>
                  ))}
                {/* <div className="col-md-5 betting-t-n ">
                  <p className="ms-2 mb-0 d-flex ">Chennai Super Kings</p>
                  <p className="ms-2">0</p>
                </div> */}
              </div>

              {/* <div className="row mt-1 bord">
                <div className="col-md-5 betting-t-n ">
                  <p className="ms-2 mb-0 d-flex ">Mumbai Indians</p>
                  <p className="ms-2">0</p>
                </div>
                <div className="col-md-7 d-flex">
                  <div className="blue-bet-btn blue-bet-btn2 ms-1 mb-0"></div>
                  <div className="blue-bet-btn blue-bet-btn1 ms-1 mb-0"></div>
                  <div className="blue-bet-btn ms-1 mb-0">
                    <div className="d-flex flex-column align-items-center mt-1">
                      650
                      <span className="fot">50K</span>
                    </div>
                  </div>
                  <div className=" orange-bet-btn ms-1 mb-0">
                    <div className="d-flex flex-column align-items-center mt-1">
                      750
                      <span className="fot">50K</span>
                    </div>
                  </div>

                  <div className="orange-bet-btn  orange-bet-btn1 ms-1 mb-0"></div>
                  <div className="orange-bet-btn orange-bet-btn2 ms-1 mb-0"></div>
                </div>
              </div> */}
            </div>
          </div>

          <div className="row  bg-dark ">
            <div className="Match-ods-h d-flex justify-content-between align-items-center">
              <p className="mx-2 mt-3 d-flex">TOURNAMENT_WINNER</p>
              <p className="mx-2 mt-3">Maximum Bet 1</p>
            </div>
            <div className="row back-lay-row  bg-dark ">
              <div className="col-5 "></div>
              <div className="col-7 coleft ">
                <button className="back ">Back</button>
                <button className="lay ms-1">Lay</button>
              </div>
            </div>
            <div className="betting-team-info">
              <div className="row mt-1 bord">
                {DataForCash.slice()
                  .reverse()
                  .map((runner, index) => (
                    <>
                      <div className="col-md-5 betting-t-n" key={index}>
                        <p className="ms-2 mb-0 d-flex">{runner.runner}</p>
                        <p className="ms-2">{runner.lastPriceTraded}</p>
                      </div>
                      {/* <div className="col-md-5 betting-t-n ">
                  <p className="ms-2 mb-0 d-flex ">Chennai Super Kings</p>
                  <p className="ms-2">0</p>
                </div> */}
                      <div className="col-md-7 d-flex">
                        {runner.ex.availableToBack
                          ?.reverse()
                          .map((item, index) => (
                            <div
                              key={index}
                              className={`blue-bet-btn ${index === 0
                                  ? `blue-bet-btn2`
                                  : index === 1
                                    ? `blue-bet-btn1`
                                    : ""
                                } ms-1 mb-0`}>
                              <div
                                key={index}
                                className="d-flex flex-column align-items-center mt-1">
                                {item.price}
                                <span className="fot">{item.size}</span>
                              </div>
                            </div>
                          ))}
                        {/* <div className="blue-bet-btn blue-bet-btn1 ms-1 mb-0">
                          <div className="d-flex flex-column align-items-center mt-1">
                            7.6
                            <span className="fot">20K</span>
                          </div>
                        </div> */}
                        {/* <div className="blue-bet-btn ms-1 mb-0">
                          <div className="d-flex flex-column align-items-center mt-1">
                            6.6
                            <span className="fot">50K</span>
                          </div>
                        </div> */}
                        {runner.ex.availableToLay
                          ?.reverse()
                          .map((item, index) => (
                            <div
                              key={index}
                              className={`orange-bet-btn ${index === 2
                                  ? `orange-bet-btn2`
                                  : index === 1
                                    ? `orange-bet-btn1`
                                    : ""
                                } ms-1 mb-0`}>
                              <div className="d-flex flex-column align-items-center mt-1">
                                {item.price}
                                <span className="fot">{item.size}</span>
                              </div>
                            </div>
                          ))}

                        {/* <div className="orange-bet-btn  orange-bet-btn1 ms-1 mb-0">
                          <div className="d-flex flex-column align-items-center mt-1">
                            98
                            <span className="fot">6.2</span>
                          </div>
                        </div>
                        <div className="orange-bet-btn orange-bet-btn2 ms-1 mb-0">
                          <div className="d-flex flex-column align-items-center mt-1">
                            5.7
                            <span className="fot">60K</span>
                          </div>
                        </div> */}
                      </div>
                    </>
                  ))}
              </div>

              {/* <div className="row mt-1 bord">
                <div className="col-md-5 betting-t-n ">
                  <p className="ms-2 mb-0 d-flex ">Mumbai Indians</p>
                  <p className="ms-2">0</p>
                </div>
                <div className="col-md-7 d-flex">
                  <div className="blue-bet-btn blue-bet-btn2 ms-1 mb-0">
                    <div className="d-flex flex-column align-items-center mt-1">
                      4.7
                      <span className="fot">0.5</span>
                    </div>
                  </div>
                  <div className="blue-bet-btn blue-bet-btn1 ms-1 mb-0">
                    <div className="d-flex flex-column align-items-center mt-1">
                      1.6
                      <span className="fot">2.0K</span>
                    </div>
                  </div>
                  <div className="blue-bet-btn ms-1 mb-0">
                    <div className="d-flex flex-column align-items-center mt-1">
                      3.6
                      <span className="fot">50</span>
                    </div>
                  </div>
                  <div className=" orange-bet-btn ms-1 mb-0">
                    <div className="d-flex flex-column align-items-center mt-1">
                      2.6
                      <span className="fot">10K</span>
                    </div>
                  </div>

                  <div className="orange-bet-btn  orange-bet-btn1 ms-1 mb-0">
                    <div className="d-flex flex-column align-items-center mt-1">
                      68
                      <span className="fot">2K</span>
                    </div>
                  </div>
                  <div className="orange-bet-btn orange-bet-btn2 ms-1 mb-0">
                    <div className="d-flex flex-column align-items-center mt-1">
                      8.7
                      <span className="fot">6</span>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <RightSidebar />
      </div>
    </div>
  );
}

export default Ipl2024;
