import React, { useState, useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import Footer from "../Footer/Footer";
import "./BinaryTree.css";
import Default from "../../Media/blackboy.png";
import Active from "../../Media/greenboy.png";
import user3red from "../../Media/redboy.png";
import { API } from "../../API/API";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DOMPurify from "dompurify";
import TreeHoverNode from "./TreeHoverNode";
import { logoutUser } from "../../Redux/Slices/authSlice";
var bol = true;

var bol = true;

function BinaryTree() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userId = sessionStorage.getItem("userData");
  const jwt_token = sessionStorage.getItem("token");
  const [showSidebar, setShowSidebar] = useState(false);
  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };
  const handleLinkClick = () => {
    // Close the sidebar when a link is clicked
    setShowSidebar(false);
  };
  //console.log("User", jwt_token);

  const [Idnumer, setIdnumer] = useState(userId);
  const [arrValue, setArrValue] = useState([]);
  const [getValue, setgetValue] = useState("");
  const [loader, setloader] = useState(false);
  const [searchvalue, setsearchvalue] = useState(0);

  const [userdata, setuserdata] = useState([
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "0",
      right_business: "",
      Sponsor: "",
      DepositAmount: "0",
      T_LeftB: "0",
      T_RightB: "0",
      T_package: "0",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
      DepositAmount: "0",
      T_LeftB: "0",
      T_RightB: "0",
      T_package: "0",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
      DepositAmount: "0",
      T_LeftB: "0",
      T_RightB: "0",
      T_package: "0",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
      DepositAmount: "0",
      T_LeftB: "0",
      T_RightB: "0",
      T_package: "0",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
      DepositAmount: "0",
      T_LeftB: "0",
      T_RightB: "0",
      T_package: "0",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
      DepositAmount: "0",
      T_LeftB: "0",
      T_RightB: "0",
      T_package: "0",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
      DepositAmount: "0",
      T_LeftB: "0",
      T_RightB: "0",
      T_package: "0",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
      DepositAmount: "0",
      T_LeftB: "0",
      T_RightB: "0",
      T_package: "0",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
      DepositAmount: "0",
      T_LeftB: "0",
      T_RightB: "0",
      T_package: "0",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
      DepositAmount: "0",
      T_LeftB: "0",
      T_RightB: "0",
      T_package: "0",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
      DepositAmount: "0",
      T_LeftB: "0",
      T_RightB: "0",
      T_package: "0",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
      DepositAmount: "0",
      T_LeftB: "0",
      T_RightB: "0",
      T_package: "0",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
      DepositAmount: "0",
      T_LeftB: "0",
      T_RightB: "0",
      T_package: "0",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
      DepositAmount: "0",
      T_LeftB: "0",
      T_RightB: "0",
      T_package: "0",
    },
    {
      name: "",
      id: "",
      registration_date: "",
      status: "",
      total_left: "",
      total_left_active: "",
      left_business: "",
      package_amount: "",
      Activation_date: "",
      package: "",
      total_right: "",
      total_right_active: "",
      right_business: "",
      Sponsor: "",
      DepositAmount: "0",
      T_LeftB: "0",
      T_RightB: "0",
      T_package: "0",
    },
  ]);
  const referral_API = async () => {
    try {
      let responce = await API?.post(
        "/binarytree",
        {
          did: Idnumer,
          usersession: userId,
        },
        {
          headers: {
            Authorization: `${jwt_token}`,
            "Content-Type": "application/json", // Adjust the content type as needed
          },
        }
      );

      responce = responce?.data?.data[0];
      console.log("Res_API_binaryTree", responce);
      if (responce == undefined) {
        toast.error("User ID Not Found");
        setloader(false);
      } else {
        setloader(true);

        let arr = [];
        responce.forEach((item, index) => {
          arr.push({
            name: item.fname,
            id: item.uid,
            registration_date: String(item?.Regdate).substring(0, 12),
            status: item.activationdate ? "Active" : "InActive",
            total_left: item.T_Left,
            total_left_active: item.totalleft,
            left_business: item.lbv,
            package_amount: item.T_package,
            Activation_date: String(item.activationdate).substring(0, 12),
            package: item.packageamount,
            total_right: item.T_Right,
            total_right_active: item.totalright,
            right_business: item.rbv,
            Sponsor: item.sid,
            date: item?.package,
            today_left_business: item.T_LeftB,
            today_right_business: item.T_RightB,
            DepositAmount: item.DepositAmount,
            T_LeftB: item.T_LeftB,
            T_RightB: item.T_RightB,
            T_package: item.T_package,
          });
        });
        // console.log("responce", arr);

        // setloader(false);

        setuserdata(arr);
        if (bol) {
          setArrValue([...arrValue, arr[0].id]);
          bol = false;
        }
      }
    } catch (e) {
      console.log("Error While calling Referrer API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };
  function addValue(value) {
    setArrValue([...arrValue, value]);
    // arrValue.push(value)
    // arrValue.push(value)
  }

  var a;
  function popoutvalue() {
    if (arrValue.length == 1) {
      // setloader(true)
      arrValue.pop();
      arrValue.unshift(userId);
      bol = true;
    } else {
      a = arrValue.splice(arrValue.length - 2, 1);
      setIdnumer(a[0]);
    }
  }

  useEffect(() => {
    referral_API();
    // let idinput = document.querySelector(".idinput");
    // idinput.value = Idnumer;
  }, [Idnumer]);

  const StyledNode = styled.div`
    padding: 5px;
    display: inline-block;
    position: relative;
    &:hover .HoverNodeItemMain {
      display: block;
    }
  `;
  const HoverNode = styled.div`
    /* other styles */
    &.marginLeft {
      margin-left: -300px;
    }
  `;
  return (
    <>
      {/* <div className="col-lg-10 home mt-2"> */}
      <div className="col-lg-10 com-h mt-5 pt-3">
        <div className="row">
          <div className="col-lg-6">
            <div className="card py-3">
              <div className="card-body bodies">
                <div className="form-wrapper">
                  <input
                    className="form-control idinput srch_frm bg-white shadow-none text-black"
                    id="ddluserid"
                    name="userId"
                    maxLength={8}
                    placeholder="Search"
                    Value={Idnumer}
                    onChange={(e) =>
                      setgetValue(
                        DOMPurify.sanitize(
                          e.target.value.replace(/[^0-9]/gi, "").substring(0, 8)
                        )
                      )
                    }
                  />
                  <button
                    id="#submit"
                    type="button"
                    onClick={() => (setIdnumer(getValue), addValue(getValue))}
                    className="btn btn-sm btn-danger topmargin submit_btn border-0 shadow-none">
                    Submit
                  </button>
                  <a
                    id="#home"
                    href="/mlmdashboard"
                    type="button"
                    onClick={() => setIdnumer(userId)}
                    className="btn btn-sm btn-danger border-0 shadow-none topmargin float_rt">
                    Home
                  </a>
                  <button
                    type="button"
                    onClick={popoutvalue}
                    className="btn btn-sm btn-danger shadow-none topmargin float_rt border-0">
                    BACK
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="card py-3">
              <div className="card-body bodies">
                <div className="tree_icon">
                  <div className="tree_name">
                    <img src={Active} width="40px" height="40px" />
                    Active
                  </div>
                  <div className="tree_name">
                    <img src={user3red} width="40px" height="40px" />
                    In-Active
                  </div>
                  <div className="tree_name">
                    <img src={Default} width="40px" height="40px" />
                    Not Registered
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="card">
              <div className="row pb-5">
                <div className="col-md-12 pb-5">
                  <div className="card card1 pb-5">
                    <div className="MintingHistory_Main Mobile_MintingHistory_Main">
                      <div className="TreeImgMian ">
                        <ul class="tree vertical">
                          <li className="kzsxjka">
                            <div class="popover__wrapper">
                              <a href="#">
                                <h2 class="popover__title">
                                  <img
                                    src={
                                      userdata[0].DepositAmount >= 1
                                        ? Active
                                        : userdata[0].id == ""
                                        ? Default
                                        : user3red
                                    }
                                    onClick={() => (
                                      setIdnumer(userdata[0].id),
                                      addValue(userdata[0].id)
                                    )}
                                    alt="Image description"
                                    className="img-fluid"
                                    style={{ width: 60 }}
                                  />
                                </h2>
                                <p style={{ fontSize: "13px" }}>
                                  {userdata[0]?.id}
                                </p>
                              </a>
                              <TreeHoverNode data={userdata[0]} />
                            </div>
                            <ul>
                              <li>
                                <div class="popover__wrapper">
                                  <a href="#">
                                    <h2 class="popover__title">
                                      <img
                                        src={
                                          userdata[1].DepositAmount >= 1
                                            ? Active
                                            : userdata[1].id == ""
                                            ? Default
                                            : user3red
                                        }
                                        onClick={() => (
                                          setIdnumer(userdata[1].id),
                                          addValue(userdata[1].id)
                                        )}
                                        alt="Image description"
                                        className="img-fluid"
                                        style={{ width: 60 }}
                                      />
                                    </h2>
                                    <p style={{ fontSize: "13px" }}>
                                      {userdata[1]?.id}
                                    </p>
                                  </a>
                                  <TreeHoverNode data={userdata[1]} />
                                </div>
                                <ul>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a href="#">
                                        <h2 class="popover__title">
                                          <img
                                            src={
                                              userdata[3].DepositAmount >= 1
                                                ? Active
                                                : userdata[3].id == ""
                                                ? Default
                                                : user3red
                                            }
                                            alt="Image description"
                                            className="img-fluid"
                                            style={{ width: 60 }}
                                            onClick={() => (
                                              setIdnumer(userdata[3].id),
                                              addValue(userdata[3].id)
                                            )}
                                          />
                                        </h2>
                                        <p style={{ fontSize: "13px" }}>
                                          {userdata[3]?.id}
                                        </p>
                                      </a>
                                      <TreeHoverNode data={userdata[3]} />
                                    </div>
                                    <ul>
                                      <li>
                                        <div class="popover__wrapper">
                                          <a href="#">
                                            <h2 class="popover__title">
                                              <img
                                                src={
                                                  userdata[7].DepositAmount >= 1
                                                    ? Active
                                                    : userdata[7].id == ""
                                                    ? Default
                                                    : user3red
                                                }
                                                alt="Image description"
                                                className="img-fluid"
                                                style={{ width: 60 }}
                                                onClick={() => (
                                                  setIdnumer(userdata[7].id),
                                                  addValue(userdata[7].id)
                                                )}
                                              />
                                            </h2>
                                            <p style={{ fontSize: "13px" }}>
                                              {userdata[7]?.id}
                                            </p>
                                          </a>
                                          <TreeHoverNode data={userdata[7]} />
                                        </div>
                                      </li>
                                      <li>
                                        <div class="popover__wrapper">
                                          <a href="#">
                                            <h2 class="popover__title">
                                              <img
                                                src={
                                                  userdata[8].DepositAmount >= 1
                                                    ? Active
                                                    : userdata[8].id == ""
                                                    ? Default
                                                    : user3red
                                                }
                                                alt="Image description"
                                                className="img-fluid"
                                                style={{ width: 50 }}
                                                onClick={() => (
                                                  setIdnumer(userdata[8].id),
                                                  addValue(userdata[8].id)
                                                )}
                                              />
                                            </h2>
                                            <p
                                              className="mb-0"
                                              style={{ fontSize: "13px" }}>
                                              {userdata[8]?.id}
                                            </p>
                                          </a>
                                          <TreeHoverNode data={userdata[8]} />
                                        </div>
                                      </li>
                                    </ul>
                                  </li>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a href="#">
                                        <h2 class="popover__title">
                                          <img
                                            src={
                                              userdata[4].DepositAmount >= 1
                                                ? Active
                                                : userdata[4].id == ""
                                                ? Default
                                                : user3red
                                            }
                                            alt="Image description"
                                            className="img-fluid"
                                            style={{ width: 60 }}
                                            onClick={() => (
                                              setIdnumer(userdata[4].id),
                                              addValue(userdata[4].id)
                                            )}
                                          />
                                        </h2>
                                        <p
                                          className="mb-0"
                                          style={{ fontSize: "13px" }}>
                                          {userdata[4]?.id}
                                        </p>
                                      </a>
                                      <TreeHoverNode data={userdata[4]} />
                                    </div>
                                    <ul>
                                      <li>
                                        <div class="popover__wrapper">
                                          <a href="#">
                                            <h2 class="popover__title">
                                              <img
                                                src={
                                                  userdata[9].DepositAmount >= 1
                                                    ? Active
                                                    : userdata[9].id == ""
                                                    ? Default
                                                    : user3red
                                                }
                                                alt="Image description"
                                                className="img-fluid"
                                                style={{ width: 60 }}
                                                onClick={() => (
                                                  setIdnumer(userdata[9].id),
                                                  addValue(userdata[9].id)
                                                )}
                                              />
                                            </h2>
                                            <p
                                              className="mb-0"
                                              style={{ fontSize: "13px" }}>
                                              {userdata[9]?.id}
                                            </p>
                                          </a>
                                          <TreeHoverNode data={userdata[9]} />
                                        </div>
                                      </li>
                                      <li>
                                        <div class="popover__wrapper">
                                          <a href="#">
                                            <h2 class="popover__title">
                                              <img
                                                src={
                                                  userdata[10].DepositAmount >=
                                                  1
                                                    ? Active
                                                    : userdata[10].id == ""
                                                    ? Default
                                                    : user3red
                                                }
                                                alt="Image description"
                                                className="img-fluid"
                                                style={{ width: 60 }}
                                              />
                                            </h2>
                                            <p
                                              className="mb-0"
                                              style={{ fontSize: "13px" }}>
                                              {userdata[10]?.id}
                                            </p>
                                          </a>
                                          <TreeHoverNode data={userdata[10]} />
                                        </div>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>

                              <li>
                                <div class="popover__wrapper">
                                  <a href="#">
                                    <h2 class="popover__title">
                                      <img
                                        src={
                                          userdata[2].DepositAmount >= 1
                                            ? Active
                                            : userdata[2].id == ""
                                            ? Default
                                            : user3red
                                        }
                                        alt="Image description"
                                        className="img-fluid"
                                        style={{ width: 60 }}
                                        onClick={() => (
                                          setIdnumer(userdata[2].id),
                                          addValue(userdata[2].id)
                                        )}
                                      />
                                    </h2>
                                    <p
                                      className="mb-0"
                                      style={{ fontSize: "13px" }}>
                                      {userdata[2]?.id}
                                    </p>
                                  </a>
                                  <TreeHoverNode data={userdata[2]} />
                                </div>
                                <ul>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a href="#">
                                        <h2 class="popover__title">
                                          <img
                                            src={
                                              userdata[5].DepositAmount >= 1
                                                ? Active
                                                : userdata[5].id == ""
                                                ? Default
                                                : user3red
                                            }
                                            alt="Image description"
                                            className="img-fluid"
                                            style={{ width: 60 }}
                                            onClick={() => (
                                              setIdnumer(userdata[5].id),
                                              addValue(userdata[5].id)
                                            )}
                                          />
                                        </h2>
                                        <p
                                          className="mb-0"
                                          style={{ fontSize: "13px" }}>
                                          {userdata[5]?.id}
                                        </p>
                                      </a>
                                      <TreeHoverNode data={userdata[5]} />
                                    </div>
                                    <ul>
                                      <li>
                                        <div class="popover__wrapper">
                                          <a href="#">
                                            <h2 class="popover__title">
                                              <img
                                                src={
                                                  userdata[11].DepositAmount >=
                                                  1
                                                    ? Active
                                                    : userdata[11].id == ""
                                                    ? Default
                                                    : user3red
                                                }
                                                alt="Image description"
                                                className="img-fluid"
                                                style={{ width: 60 }}
                                                onClick={() => (
                                                  setIdnumer(userdata[11].id),
                                                  addValue(userdata[11].id)
                                                )}
                                              />
                                            </h2>
                                            <p
                                              className="mb-0"
                                              style={{ fontSize: "13px" }}>
                                              {userdata[11]?.id}
                                            </p>
                                          </a>
                                          <TreeHoverNode data={userdata[11]} />
                                        </div>
                                      </li>
                                      <li>
                                        <div class="popover__wrapper">
                                          <a href="#">
                                            <h2 class="popover__title">
                                              <img
                                                src={
                                                  userdata[12].DepositAmount >=
                                                  1
                                                    ? Active
                                                    : userdata[12].id == ""
                                                    ? Default
                                                    : user3red
                                                }
                                                alt="Image description"
                                                className="img-fluid"
                                                style={{ width: 60 }}
                                                onClick={() => (
                                                  setIdnumer(userdata[12].id),
                                                  addValue(userdata[12].id)
                                                )}
                                              />
                                            </h2>
                                            <p
                                              className="mb-0"
                                              style={{ fontSize: "13px" }}>
                                              {userdata[12]?.id}
                                            </p>
                                          </a>
                                          <TreeHoverNode data={userdata[12]} />
                                        </div>
                                      </li>
                                    </ul>
                                  </li>
                                  <li>
                                    <div class="popover__wrapper">
                                      <a href="#">
                                        <h2 class="popover__title">
                                          <img
                                            src={
                                              userdata[6].DepositAmount >= 1
                                                ? Active
                                                : userdata[6].id == ""
                                                ? Default
                                                : user3red
                                            }
                                            alt="Image description"
                                            className="img-fluid"
                                            style={{ width: 60 }}
                                            onClick={() => (
                                              setIdnumer(userdata[6].id),
                                              addValue(userdata[6].id)
                                            )}
                                          />
                                        </h2>
                                        <p
                                          className="mb-0"
                                          style={{ fontSize: "13px" }}>
                                          {userdata[6]?.id}
                                        </p>
                                      </a>
                                      <TreeHoverNode data={userdata[6]} />
                                    </div>
                                    <ul>
                                      <li>
                                        <div class="popover__wrapper">
                                          <a href="#">
                                            <h2 class="popover__title">
                                              <img
                                                src={
                                                  userdata[13].DepositAmount >=
                                                  1
                                                    ? Active
                                                    : userdata[13].id == ""
                                                    ? Default
                                                    : user3red
                                                }
                                                alt="Image description"
                                                className="img-fluid"
                                                style={{ width: 60 }}
                                                onClick={() => (
                                                  setIdnumer(userdata[13].id),
                                                  addValue(userdata[13].id)
                                                )}
                                              />
                                            </h2>
                                            <p
                                              className="mb-0"
                                              style={{ fontSize: "13px" }}>
                                              {userdata[13]?.id}
                                            </p>
                                          </a>
                                          <TreeHoverNode data={userdata[13]} />
                                        </div>
                                      </li>
                                      <li>
                                        <div class="popover__wrapper">
                                          <a href="#">
                                            <h2 class="popover__title">
                                              <img
                                                src={
                                                  userdata[14].DepositAmount >=
                                                  1
                                                    ? Active
                                                    : userdata[14].id == ""
                                                    ? Default
                                                    : user3red
                                                }
                                                alt="Image description"
                                                onClick={() => (
                                                  setIdnumer(userdata[14].id),
                                                  addValue(userdata[14].id)
                                                )}
                                                className="img-fluid"
                                                style={{ width: 60 }}
                                              />
                                            </h2>
                                            <p
                                              className="mb-0"
                                              style={{ fontSize: "13px" }}>
                                              {userdata[14]?.id}
                                            </p>
                                          </a>
                                          <TreeHoverNode data={userdata[14]} />
                                        </div>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BinaryTree;
