import React, { useEffect } from "react";
import RightSidebar from "../RightSidebar/RIghtSidebar";
// import "./DragonTiger.css";
import a from "../../Media/10SS.png";
import b from "../../Media/3SS.png";
import c from "../../Media/2HH.png";
import d from "../../Media/AHH.png";
import e from "../../Media/KHH.png";
import { MdCancel } from "react-icons/md";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaFontAwesome, FaLock, FaTrophy } from "react-icons/fa";
import { useState } from "react";
import heart from "../../Media/heart-2.png";
import diamond from "../../Media/diamond-2.png";
import club from "../../Media/club-2.png";
import spade from "../../Media/spade-2.png";
import c1 from "../../Media/1.jpg";
import c2 from "../../Media/2.jpg";
import c3 from "../../Media/3.jpg";
import c4 from "../../Media/4.jpg";
import c5 from "../../Media/5.jpg";
import c6 from "../../Media/6.jpg";
import c7 from "../../Media/7.jpg";
import c8 from "../../Media/8.jpg";
import c9 from "../../Media/9.jpg";
import c10 from "../../Media/10.jpg";
import c11 from "../../Media/11.jpg";
import c12 from "../../Media/12.jpg";
import c13 from "../../Media/13.jpg";
import "./Lucky.css";
import { io } from "socket.io-client";
import { useParams } from "react-router-dom";
// import { fetchCasinobetHistory } from "../../Redux/Slices/casinobetHistory";
import { useDispatch, useSelector } from "react-redux";
import Rules_Modal from "../Rules_Modal";
import Modal from "../TeenPatti/Modal";
import { API } from "../../API/API";
// import { fetchuserBalance } from "../../Redux/Slices/userBalanceSlice";
import { fetchThirtyTwoCardStack } from "../../Redux/Slices/thirtyTwoCardGameSlice";
import { card52War } from "../../Utility/52CardImages.js";
import cardBlank from "../../Media/DTL2020/backcard.webp";

const Lucky = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const token = sessionStorage.getItem("token");
  const uid = sessionStorage.getItem("userData");
  const imageImports = [c1, c2, c3, c4, c5, c6, c7, c8, c9, c10, c11, c12, c13];
  const [showresultModal, setShowresultModal] = useState(false);
  const [showDataModal, setShowdataModal] = useState(false);
  const [name, setName] = useState("");
  const [results, setResults] = useState([]);
  const [reqRes, setreqRes] = useState({ t1: [], t2: [] });
  const [rulesModal, setRulesModal] = useState(false);
  const [realTime, setRealTimeData] = useState(false);

  // const { casinobetHistory, loading } = useSelector(
  //   (state) => state.casinobetHistory
  // );

  const { thirtyTwoCardStack } = useSelector(
    (state) => state.thirtyTwoCardGameStack
  );

  const [betno, setBetNum] = useState(0);
  const [betDetails, setbetDetails] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [midRound, setMidRound] = useState(0);
  const [casinoGameResult, setCasinoGameResult] = useState([]);

  const CasinoGameResult_API = async (mid) => {
    try {
      const response = await API.post("/GetCasinoGameResultData", {
        mid: mid,
        gameid: id,
      });
      // console.log("CasinoGameResult lucky:", response?.data?.data[0]);
      setCasinoGameResult(response?.data?.data[0]);
    } catch (error) {
      console.error("Error CasinoGameResult_API", error);
    }
  };

  const ResultsModal = () => {
    return (
      <>
        <div
          onClick={() => {
            setShowresultModal(false);
          }}
          className="Results-modal-wrapper"
        ></div>
        <div className="lucky-results-modal-container bg-dark">
          <div className="results-modal-h d-flex justify-content-between pt-2">
            <div>Round ID: {midRound}</div>
            <MdCancel
              className="cross-icon-result me-2"
              onClick={() => {
                setShowresultModal(false);
              }}
            />
          </div>
          <div>
            <div className="d-flex justify-content-center mt-2">
              <div className="player-a-div ">
                {/* <div className="text-center">Player 8:27</div> */}
                {casinoGameResult?.map((item, index) => (
                  <div className="gamecard-div mx-1" key={index}>
                    <img
                      src={item.image}
                      className="img-fluid"
                      alt={`Player B Card ${index}`}
                    />
                  </div>
                ))}
              </div>
            </div>
            {/* <div className="player-a-div mt-2 d-flex justify-content-center">
              Result : High Card | Black | Even | Card 10
            </div> */}
          </div>
        </div>
      </>
    );
  };

  const SOCKET_URL = "https://dreamwin-socket.nakshtech.info/";

  useEffect(() => {
    // console.log("Odd socket before");
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoGameSocket", id);

    cricketMatchesSocket.on("GetCasinoGameSocket_FromAPI", (data) => {
      // console.log("TeenPatti socket1==>", data);
      if (data && data.length > 0) {
        const parsedData = data.map((item) => ({
          ...item,
          reqResponse: JSON.parse(item.reqResponse),
          resultUrlResponse: item?.resultUrlResponse
            ? JSON.parse(item?.resultUrlResponse)
            : null,
        }));

        console.log("parsed", parsedData[0].reqResponse);
        // console.log("parsed_result", parsedData[0].resultUrlResponse);
        setResults(parsedData[0]?.reqResponse?.data);
        setreqRes(parsedData[0]?.reqResponse);
        setName(parsedData[0]?.name);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  async function ResultScheduler_API() {
    try {
      let res = await API.post(
        "/Schulder_7CareGameLive",
        {
          gameid: id,
          username: uid,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      // console.log("scheduler res", res.data);
    } catch (e) {
      console.log("Error", e);
    }
  }

  const [history, setHistory] = useState([]);

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoBetReportSocket", uid, id);

    cricketMatchesSocket.on("GetCasinoBetReportSocket_FromAPI", (data) => {
      if (data && data[0].length > 0) {
        setHistory(data[0]);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    ResultScheduler_API();
    dispatch(fetchThirtyTwoCardStack({ uid, gameId: id, token }));
  }, [dispatch, reqRes?.data?.data?.t1[0]?.mid]);

  const HistoryTable = (
    <>
      <div className="table-h-div row table-responsive">
        <table>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Period</th>
              <th>Multiplier</th>
              <th>Amount</th>
              <th>Date</th>
              <th>Remark</th>
              <th>Card</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {/* {loading ? (
              <tr>
                <td colSpan="6">Loading ...</td>
              </tr>
            ) : */}
            {history?.length > 0 ? (
              history?.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.period}</td>
                  <td>{item.multipler}</td>
                  <td>{item.amount}</td>
                  <td>{item.dd}</td>
                  <td className={item.remark === "Loss" ? "zero" : "success-1"}>
                    {item.remark}
                  </td>
                  <td>{item.card}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  No Data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );

  const handleModal = (value, nation, no) => {
    setbetDetails({
      ...betDetails,
      gameid: id,
      multipler: value,
      period: 0,
      nation: nation,
      number: no,
    });
    setShowModal(true);
    setRealTimeData(true);
  };

  function checkValid() {
    if (showModal && realTime) {
      let filteredData = reqRes?.data?.data?.t2.filter(
        (item) => item.nation === betDetails.nation
      );
      // console.log("filter", filteredData, betDetails);
      filteredData[0]?.gstatus !== "0" &&
      betDetails?.nation === filteredData[0]?.nation &&
      betDetails?.multipler === filteredData[0]?.rate
        ? setRealTimeData(true)
        : setRealTimeData(false);
    }
  }

  useEffect(() => {
    checkValid();
  }, [showModal, realTime, reqRes?.data?.data?.t2, betDetails]);

  const findCardImage = (cardId) => {
    const card = card52War.find((card) => card.id === cardId);
    return card ? card.src : null;
  };

  return (
    <div className="col-lg-10 ">
      <div className="row home mainhomeDev">
        <div className="col-lg-8 p-1">
          <div className="teenpatti-game-div pt-2 p-2">
            <div className="teenpatti-game-h d-flex justify-content-between  ">
              <div className=" px-2">
                <p className=" mt-1 ">{name}</p>
              </div>
              <div>
                <p className="mt-1 px-2">
                  Round ID: {reqRes?.data?.data?.t1[0]?.mid || 0.0} | Left Time:{" "}
                  {reqRes?.data?.data?.t1[0]?.autotime || 0} |{" "}
                  <span onClick={() => setRulesModal(true)}>Rules</span>
                </p>
              </div>
            </div>
            <div className="card  teenpatti-game-card p-1 mt-1">
              <div className="row">
                <div className="col-10">
                  <div className="" style={{ height: "170%", width: "120%" }}>
                    <iframe
                      height="100%"
                      width="100%"
                      src={`https://battlemoney.live/iframe/${id}`}
                      title=""
                    ></iframe>
                  </div>
                </div>
                <div className="col-2 d-flex justify-content-end">
                  <label className="switch">
                    <input type="checkbox" />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="timer ">
                  <div
                    className="container"
                    style={{
                      color: "white",
                      marginLeft: "-1px",
                      marginTop: "-150px",
                    }}
                  >
                    {/* <div className="timer_container position-absolute"  > */}
                    <span></span>
                    <div>
                      {["C1"].map((key, index) => {
                        const cardId = reqRes?.data?.data?.t1[0]?.[key];
                        return cardId ? (
                          <span key={index}>
                            <img
                              src={findCardImage(cardId) || cardBlank}
                              alt={cardId}
                              style={{ width: "30px", height: "auto" }}
                            />
                          </span>
                        ) : null;
                      })}   
                    </div>
                    {/* <span>
                      <span>Player B</span>
                      <div>
                        {["C3", "C4"].map((key, index) => {
                          const cardId = reqRes?.data?.data?.t1[0]?.[key];
                          return cardId ? (
                            <span key={index}>
                              <img
                                src={findCardImage(cardId)}
                                alt={cardId}
                                style={{ width: "20px", height: "auto" }}
                              />
                            </span>
                          ) : null;
                        })}
                      </div>
                      </span>  */}
                    {/* Board */}

                    {/* <span>
                      <span>Board</span>
                      <div>
                        {["C5","C6","C7","C8","C9"].map((key, index) => {
                          const cardId = reqRes?.data?.data?.t1[0]?.[key];
                          return cardId ? (
                            <span key={index}>
                              <img
                                src={findCardImage(cardId)}
                                alt={cardId}
                                style={{ width: "20px", height: "auto" }}
                              />
                            </span>
                          ) : null;
                        })}
                      </div>
                      </span>  */}

                    {/* </div> */}
                  </div>
                </div>
                <div
                  className="timer "
                  style={{ marginLeft: "550px", marginTop: "20px" }}
                >
                  <div className="container">
                    <div
                      className="timer_container position-absolute"
                      style={{ right: "36px", bottom: "15px" }}
                    >
                      <button
                        class="btn btn-secondary"
                        style={{ cursor: "default", background: "#6f42c1" }}
                      >
                        <span>
                          {" "}
                          {reqRes?.data?.data?.t1[0]?.autotime || 0}{" "}
                        </span>
                      </button>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <-------------------------------Orange-div----------> */}
            <div className="orange-row-div mt-2 row">
              <div className="col-10 mx-auto">
                <div className="row mt-2">
                  <div className="col-4 ">
                    <div className="  button-column pt-1">
                      <div className="d-flex justify-content-center">
                        {reqRes?.data?.data?.t2[0]?.rate}
                      </div>
                      <div>
                        {reqRes?.data?.data?.t2[0]?.gstatus == 0 ? (
                          <button className="blue-grey-btn mx-auto overs">
                            <i className="fa fa-lock"></i>
                          </button>
                        ) : (
                          <button
                            onClick={() => {
                              // setShowModal(true);
                              // setBetNum(1);

                              handleModal(
                                reqRes?.data?.data?.t2[0]?.rate,
                                reqRes?.data?.data?.t2[0]?.nation,
                                1
                              );
                            }}
                            className="blue-grey-btn mx-auto"
                          >
                            {reqRes?.data?.data?.t2[0]?.nation}
                          </button>
                        )}
                      </div>
                      <div className="d-flex justify-content-center dragon-heading">
                        {thirtyTwoCardStack.map(
                          (ele, index) =>
                            ele.number === 1 && (
                              <span
                                className={` ${
                                  ele.number == 1 && ele.amount >= 0
                                    ? "success-1"
                                    : "zero"
                                }`}
                                key={index}
                              >
                                {ele.number == 1 && ele.amount}
                              </span>
                            )
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-4 d-flex justify-content-center align-items-center">
                    <div className="seven-card-div">
                      <img className="img-fluid" src={c7} alt="" />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="  button-column pt-1">
                      <div className="d-flex justify-content-center">
                        {reqRes?.data?.data?.t2[1]?.rate}
                      </div>
                      <div>
                        {reqRes?.data?.data?.t2[1]?.gstatus == 0 ? (
                          <button className="blue-grey-btn mx-auto overs">
                            <i className="fa fa-lock"></i>
                          </button>
                        ) : (
                          <button
                            onClick={() => {
                              handleModal(
                                reqRes?.data?.data?.t2[1]?.rate,
                                reqRes?.data?.data?.t2[1]?.nation,
                                2
                              );
                            }}
                            className="blue-grey-btn mx-auto"
                          >
                            {reqRes?.data?.data?.t2[1]?.nation}
                          </button>
                        )}
                      </div>
                      <div className="d-flex justify-content-center dragon-heading">
                        {thirtyTwoCardStack.map(
                          (ele) =>
                            ele.number === 2 && (
                              <span
                                className={` ${
                                  ele.number == 2 && ele.amount >= 0
                                    ? "success-1"
                                    : "zero"
                                }`}
                              >
                                {ele.number == 2 && ele.amount}
                              </span>
                            )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <----------------------------Dragon-Tiger------------->>>>>>>>>>> */}

            <div className="row">
              <div className="col-12 py-2">
                <div className="dragon-column ">
                  <div className="row">
                    {reqRes?.data?.data?.t2.slice(2, 6).map((item, index) => (
                      <div className="col-3  " key={index}>
                        <div className="  button-column pt-1">
                          <div className="d-flex justify-content-center">
                            {" "}
                            {item?.rate}
                          </div>
                          <div className="">
                            {item.gstatus == 0 ? (
                              <button className="blue-grey-btn mx-auto overs">
                                <i className="fa fa-lock"></i>
                              </button>
                            ) : (
                              <button
                                onClick={() => {
                                  // setShowModal(true);
                                  // setBetNum(index + 1);

                                  handleModal(item?.rate, item?.nation, index + 3);
                                }}
                                className="blue-grey-btn mx-auto"
                              >
                                {item?.nation}
                              </button>
                            )}
                          </div>
                          <div className="d-flex justify-content-center dragon-heading">
                            {thirtyTwoCardStack?.map(
                              (ele) =>
                                ele.number === index + 3 && (
                                  <span
                                    className={` ${
                                      ele.number == index + 3 && ele.amount >= 0
                                        ? "success-1"
                                        : "zero"
                                    }`}
                                  >
                                    {ele.number == index + 3 && ele.amount}
                                  </span>
                                )
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="py-3">
                  <div className="d-flex justify-content-center">
                    {reqRes?.data?.data?.t2[6]?.rate}
                  </div>
                  <div className="cards-thirteen-div d-flex flex-wrap justify-content-center">
                    {reqRes?.data?.data?.t2
                      ?.slice(6, 19)
                      ?.map((item, index) => (
                        <div
                          className="card-column-img-div  d-flex flex-column mx-1"
                          key={index}
                        >
                          <div className="position-relative">
                            {item.gstatus == 0 && (
                              <div className="suspended__2">
                                <i className="fa fa-lock"></i>
                              </div>
                            )}
                            <img
                              className="card-column-img"
                              src={imageImports[index]}
                              alt={item?.nation}
                              onClick={() => {
                                // setShowModal(true);
                                // setBetNum(index + 1);
                                handleModal(item?.rate, item?.nation, index + 7);
                              }}
                            />
                          </div>

                          <div className="card-zero d-flex justify-content-center">
                            {thirtyTwoCardStack.map(
                              (ele) =>
                                ele.number === index + 7 && (
                                  <span
                                    className={` ${
                                      ele.number == index + 7 && ele.amount >= 0
                                        ? "success-1"
                                        : "zero"
                                    }`}
                                  >
                                    {ele.number == index + 7 && ele.amount}
                                  </span>
                                )
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>

            {/* <<<<<<<<<<<<<<<<<<<<<<<-----------------------My-Bets-table-------------->>>>>>>>>> */}

            <div className="Results-table">
              <div className="color-game-h mt-2">
                <div className="px-2 pt-1">
                  <p>Last 10 Results</p>
                </div>
              </div>
              <div className="card rst-card py-1 d-flex">
                <div className="rst-row d-flex">
                  {results?.result?.map((result, index) => {
                    const item = reqRes?.data?.data?.t2.find(
                      (item) => item.sid == result?.result
                    );

                    return (
                      <div
                        onClick={() => {
                          setMidRound(result.mid);
                          CasinoGameResult_API(result.mid);
                          setShowresultModal(true);
                        }}
                        className="rst-btn ms-1"
                        key={index}
                      >
                        {item ? <>{item?.nation[0]}</> : "T"}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="My-Bets-table">
              <div className="color-game-h mt-2">
                <div className="px-2 pt-1">
                  <p>Open Bets</p>
                </div>
              </div>
              {HistoryTable}
            </div>
          </div>
        </div>

        <RightSidebar />
        {showModal && (
          <Modal
            id="2"
            name={name}
            betDetails={betDetails}
            setbetDetails={setbetDetails}
            setShowModal={setShowModal}
            realTime={realTime}
            setRealTimeData={setRealTimeData}
          />
        )}
        {showresultModal && <ResultsModal />}
        {/* {showDataModal && <DataModal />} */}
        {rulesModal && <Rules_Modal closeFunction={setRulesModal} />}
      </div>
    </div>
  );
};

export default Lucky;
