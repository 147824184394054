import React, { useEffect, useState } from "react";
import RightSidebar from "../RightSidebar/RIghtSidebar";
import { MdCancel } from "react-icons/md";
import { FaTrophy } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { io } from "socket.io-client";
import Modal from "../TeenPatti/Modal";
import { useDispatch, useSelector } from "react-redux";
import { API } from "../../API/API";
import { fetchThirtyTwoCardStack } from "../../Redux/Slices/thirtyTwoCardGameSlice";
import { card52War } from "../../Utility/52CardImages.js";
import cardBlank from "../../Media/DTL2020/backcard.webp";

export default function PokerTwenty() {
  const { id } = useParams();
  const token = sessionStorage.getItem("token");
  const uid = sessionStorage.getItem("userData");
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [showresultModal, setShowresultModal] = useState(false);
  const [name, setName] = useState("");
  const [results, setResults] = useState([]);
  const [reqRes, setreqRes] = useState({ t1: [], t2: [] });
  const [realTime, setRealTimeData] = useState(false);
  const [betDetails, setbetDetails] = useState({});
  const [betIndex, setBetIndex] = useState("");
  const [history, setHistory] = useState([]);
  const [trophy, setTrophy] = useState("");
  const { thirtyTwoCardStack } = useSelector(
    (state) => state.thirtyTwoCardGameStack
  );

  const resultMapping = {
    11: "A",
    21: "B",
  };

  const SOCKET_URL = "https://dreamwin-socket.nakshtech.info/";

  useEffect(() => {
    // console.log("Odd socket before");
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoGameSocket", id);

    cricketMatchesSocket.on("GetCasinoGameSocket_FromAPI", (data) => {
      // console.log("poker socket1==>", data);
      if (data && data.length > 0) {
        const parsedData = data.map((item) => ({
          ...item,
          reqResponse: JSON.parse(item.reqResponse),
          resultUrlResponse: JSON.parse(item.resultUrlResponse),
        }));

        console.log("parsed", parsedData[0]);
        // console.log("parsed_result", parsedData[0].resultUrlResponse);
        setResults(parsedData[0].resultUrlResponse);
        setreqRes(parsedData[0].reqResponse);
        setName(parsedData[0].name);

        // console.log(
        //   "parsed_result parsed_result",
        //   parsedData[0].resultUrlResponse
        // );
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  const [midRound, setMidRound] = useState(0);
  const [casinoGameResult, setCasinoGameResult] = useState([]);
  console.log("casinoGameResult", casinoGameResult);

  const CasinoGameResult_API = async (mid) => {
    try {
      const response = await API.post("/GetCasinoGameResultData", {
        mid: mid,
        gameid: id,
      });
      // console.log("CasinoGameResult dragon:", response.data.data[0]);
      setCasinoGameResult(response.data.data[0]);
    } catch (error) {
      console.error("Error CasinoGameResult_API", error);
    }
  };

  async function ResultScheduler_API() {
    try {
      let res = await API.post("/Schulder_7CareGameLive", {
        gameid: id,
        username: uid
      },
        {
          headers: {
            Authorization: token,
          },
        });
      // console.log("scheduler res", res.data);
    } catch (e) {
      console.log("Error", e);
    }
  }

  useEffect(() => {
    ResultScheduler_API();
    dispatch(fetchThirtyTwoCardStack({ uid, gameId: id, token }));
  }, [dispatch, reqRes?.data?.data?.t1[0]?.mid]);

  const ResultsModal = () => {
    return (
      <>
        <div
          onClick={() => {
            setShowresultModal(false);
          }}
          className="Results-modal-wrapper"></div>
        <div className="results-modal-container bg-dark">
          <div className="results-modal-h d-flex justify-content-between pt-2">
            <div>Round ID: {midRound}</div>
            <MdCancel
              className="cross-icon-result me-2"
              onClick={() => {
                setShowresultModal(false);
              }}
            />
          </div>
          <div>
            <div className="container d-flex">
              <div className="row">
                <div className="d-flex justify-content-center mt-2">
                  <div className="player-a-div">
                    <div className="d-flex justify-content-between">
                      <div className="text-center">Player A</div>
                      {trophy === "A" && <FaTrophy className="trophy-icon" />}
                    </div>

                    <div className="d-flex">
                      {casinoGameResult[0] && (
                        <div
                          className="gamecard-div mx-1 "
                          key={casinoGameResult[0].id}>
                          <img
                            src={casinoGameResult[0].image}
                            className="img-fluid"
                            alt={`Player A Card 0`}
                          />
                        </div>
                      )}
                      {casinoGameResult[1] && (
                        <div
                          className="gamecard-div mx-1"
                          key={casinoGameResult[1].id}>
                          <img
                            src={casinoGameResult[1].image}
                            className="img-fluid"
                            alt={`Player A Card 0`}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="d-flex justify-content-center mt-10 "
                style={{ marginLeft: "20px" }}>
                <div className="player-a-div ">
                  <div className="text-center">Board</div>
                  <div className="d-flex">
                    {" "}
                    {casinoGameResult[4] && (
                      <div
                        className="gamecard-div mx-1 "
                        key={casinoGameResult[4].id}>
                        <img
                          src={casinoGameResult[4].image}
                          className="img-fluid"
                          alt={`Player A Card 0`}
                        />
                      </div>
                    )}
                    {casinoGameResult[5] && (
                      <div
                        className="gamecard-div mx-1"
                        key={casinoGameResult[5].id}>
                        <img
                          src={casinoGameResult[5].image}
                          className="img-fluid"
                          alt={`Player A Card 0`}
                        />
                      </div>
                    )}
                    {casinoGameResult[6] && (
                      <div
                        className="gamecard-div mx-1 "
                        key={casinoGameResult[6].id}>
                        <img
                          src={casinoGameResult[6].image}
                          className="img-fluid"
                          alt={`Player A Card 0`}
                        />
                      </div>
                    )}
                    {casinoGameResult[7] && (
                      <div
                        className="gamecard-div mx-1"
                        key={casinoGameResult[7].id}>
                        <img
                          src={casinoGameResult[7].image}
                          className="img-fluid"
                          alt={`Player A Card 0`}
                        />
                      </div>
                    )}
                    {casinoGameResult[8] && (
                      <div
                        className="gamecard-div mx-1"
                        key={casinoGameResult[8].id}>
                        <img
                          src={casinoGameResult[8].image}
                          className="img-fluid"
                          alt={`Player A Card 0`}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div
                  className="d-flex justify-content-center mt-2"
                  style={{ marginLeft: "20px" }}>
                  <div className="player-a-div  ">
                    <div className="d-flex justify-content-between">
                      <div className="text-center">Player B</div>
                      {trophy === "B" && <FaTrophy className="trophy-icon" />}
                    </div>

                    <div className="d-flex">
                      {casinoGameResult[2] && (
                        <div
                          className="gamecard-div mx-1 "
                          key={casinoGameResult[2].id}>
                          <img
                            src={casinoGameResult[2].image}
                            className="img-fluid"
                            alt={`Player A Card 0`}
                          />
                        </div>
                      )}
                      {casinoGameResult[3] && (
                        <div
                          className="gamecard-div mx-1"
                          key={casinoGameResult[3].id}>
                          <img
                            src={casinoGameResult[3].image}
                            className="img-fluid"
                            alt={`Player A Card 0`}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const handleModal = (value, nat, no) => {
    setbetDetails({
      ...betDetails,
      gameid: id,
      multipler: value,
      period: 0,
      nat: nat,
      number: no,
    });
    setShowModal(true);
    setRealTimeData(true);
  };

  function checkValid() {
    if (showModal && realTime) {
      let filteredData = reqRes?.data?.data?.t2.filter(
        (item) => item.sid === betIndex
      );
      // console.log("filter", filteredData, betDetails);
      filteredData[0]?.gstatus !== "0" &&
      betDetails.nat === filteredData[0].nat &&
      betDetails.multipler === filteredData[0].rate
        ? setRealTimeData(true)
        : setRealTimeData(false);
    }
  }

  useEffect(() => {
    checkValid();
  }, [showModal, realTime, reqRes?.data?.data?.t2, betDetails]);

  const findCardImage = (cardId) => {
    const card = card52War.find((card) => card.id === cardId);
    return card ? card.src : null;
  };

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoBetReportSocket", uid, id);
    cricketMatchesSocket.on("GetCasinoBetReportSocket_FromAPI", (data) => {
      if (data && data[0].length > 0) {
        setHistory(data[0]);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, [id]);

  const HistoryTable = (
    <>
      <div className="table-h-div row table-responsive">
        <table>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Period</th>
              <th>Multiplier</th>
              <th>Amount</th>
              <th>Date</th>
              <th>Remark</th>
              <th>Card</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {/* {loading ? (
              <tr>
                <td colSpan="6">Loading ...</td>
              </tr>
            ) : */}
            {history.length > 0 ? (
              history.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.period}</td>
                  <td>{item.multipler}</td>
                  <td>{item.amount}</td>
                  <td>{item.dd}</td>
                  <td className={item.remark === "Loss" ? "zero" : "success-1"}>
                    {item.remark}
                  </td>
                  <td>{item.card}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  No Data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
  return (
    <>
      <div className="col-lg-10 ">
        <div className="row home mainhomeDev">
          <div className="col-lg-8 p-1">
            <div className="teenpatti-game-div pt-2 p-2">
              <div className="teenpatti-game-h d-flex justify-content-between  ">
                <div className=" px-2">
                  <p className=" mt-1 ">20-20 POKER</p>
                </div>
                <div>
                  <p className="mt-1 px-2">
                    {/* Round ID: 2024021633648 | Left Time: 56 | Rules */}
                    Round ID: {reqRes?.data?.data?.t1[0]?.mid || 0.0} | Left
                    Time:{reqRes?.data?.data?.t1[0]?.autotime || 0} |{" "}
                  </p>
                </div>
              </div>
              <div className="card  teenpatti-game-card p-1 mt-1">
                <div className="row">
                  <div className="col-10">
                    <div className="" style={{ height: "170%", width: "120%" }}>
                      <iframe
                        height="100%"
                        width="100%"
                        src={`https://battlemoney.live/iframe/${id}`}
                        title=""></iframe>
                    </div>
                  </div>
                  <div className="col-2 d-flex justify-content-end">
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round"></span>
                    </label>
                  </div>
                  <div className="timer ">
                    <div
                      className="container"
                      style={{
                        color: "white",
                        marginLeft: "-1px",
                        marginTop: "-150px",
                      }}
                    >
                      {/* <div className="timer_container position-absolute"  > */}
                      <span>Player A</span>
                      <div>
                        {["C1", "C2"].map((key, index) => {
                          const cardId = reqRes?.data?.data?.t1[0]?.[key];
                          return cardId ? (
                            <span key={index}>
                              <img
                                src={findCardImage(cardId) || cardBlank}
                                alt={cardId}
                                style={{ width: "20px", height: "auto" }}
                              />
                            </span>
                          ) : null;
                        })}
                      </div>
                      <span>
                        <span>Player B</span>
                        <div>
                          {["C3", "C4"].map((key, index) => {
                            const cardId = reqRes?.data?.data?.t1[0]?.[key];
                            return cardId ? (
                              <span key={index}>
                                <img
                                  src={findCardImage(cardId) || cardBlank}
                                  alt={cardId}
                                  style={{ width: "20px", height: "auto" }}
                                />
                              </span>
                            ) : null;
                          })}
                        </div>
                      </span>
                      {/* Board */}

                      <span>
                        <span>Board</span>
                        <div>
                          {["C5", "C6", "C7", "C8", "C9"].map((key, index) => {
                            const cardId = reqRes?.data?.data?.t1[0]?.[key];
                            return cardId ? (
                              <span key={index}>
                                <img
                                  src={findCardImage(cardId) || cardBlank}
                                  alt={cardId}
                                  style={{ width: "20px", height: "auto" }}
                                />
                              </span>
                            ) : null;
                          })}
                        </div>
                      </span>

                      {/* </div> */}
                    </div>
                  </div>
                  <div
                    className="timer "
                    style={{ marginLeft: "550px", marginTop: "20px" }}>
                    <div className="container">
                    <div
                      className="timer_container position-absolute"
                      style={{ right: "36px", bottom: "15px" }}
                    >
                      <button
                        class="btn btn-secondary"
                        style={{ cursor: "default", background: "#6f42c1" }}
                      >
                        <span>
                          {" "}
                          {reqRes?.data?.data?.t1[0]?.autotime || 0}{" "}
                        </span>
                      </button>{" "} 
                    </div> 
                    </div> 
                  </div>
                </div>
              </div>

              {/* <-------------------------------Back-lay-table----------> */}
              <div>
                <div className="table-h-div row">
                  <div className="col-6 d-flex align-items-center ps-2" />
                </div>
                <div className="card-table-inner-row row">
                  <div className="col-6 d-flex align-items-center ps-2"></div>

                  <div className="col-3 d-flex align-items-center justify-content-center px-2 position-relative">
                    <button className="back-btn">Player A</button>
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center px-2 position-relative">
                    <button className="back-btn">Player B</button>
                  </div>
                </div>

                <div className="card-table-inner-row row">
                  <div className="col-6 d-flex align-items-center ps-2">
                    Winner
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center px-2 position-relative">
                    {reqRes?.data?.data?.t2[0]?.gstatus == 0 && (
                      <div className="col-3 suspended__1">
                        <i className="fa fa-lock"></i>
                      </div>
                    )}
                    <button
                      className="back-btn"
                      onClick={() => {
                        handleModal(
                          reqRes?.data?.data?.t2[0]?.rate,
                          reqRes?.data?.data?.t2[0]?.nat,
                          1
                        );
                        setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                      }}>
                      {reqRes?.data?.data?.t2[0]?.rate}
                      <p>
                        {" "}
                        {thirtyTwoCardStack.map(
                          (ele) =>
                            ele.number === 1 && (
                              <span
                                className={`${
                                  ele.number == 1 && ele.amount >= 0
                                    ? "success-1"
                                    : "zero"
                                }`}>
                                {ele.number == 1 && ele.amount}
                              </span>
                            )
                        )}
                      </p>
                    </button>
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center px-2 position-relative">
                    {reqRes?.data?.data?.t2[1]?.gstatus == 0 && (
                      <div className="col-3 suspended__1">
                        <i className="fa fa-lock"></i>
                      </div>
                    )}
                    <button
                      className="back-btn"
                      onClick={() => {
                        handleModal(
                          reqRes?.data?.data?.t2[1]?.rate,
                          reqRes?.data?.data?.t2[1]?.nat,
                          2
                        );
                        setBetIndex(reqRes?.data?.data?.t2[1]?.sid);
                      }}>
                      {reqRes?.data?.data?.t2[1]?.rate}
                      <p>
                        {thirtyTwoCardStack.map(
                          (ele) =>
                            ele.number === 2 && (
                              <span
                                className={`${
                                  ele.number == 2 && ele.amount >= 0
                                    ? "success-1"
                                    : "zero"
                                }`}>
                                {ele.number == 2 && ele.amount}
                              </span>
                            )
                        )}
                      </p>
                    </button>
                  </div>
                </div>
                <div className="card-table-inner-row row">
                  <div className="col-6 d-flex align-items-center ps-2">
                    One Pair{" "}
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center px-2 position-relative">
                    {reqRes?.data?.data?.t2[2]?.gstatus == 0 && (
                      <div className="col-3 suspended__1">
                        <i className="fa fa-lock"></i>
                      </div>
                    )}
                    <button
                      className="back-btn"
                      onClick={() => {
                        handleModal(
                          reqRes?.data?.data?.t2[2]?.rate,
                          reqRes?.data?.data?.t2[2]?.nat,
                          3
                        );
                        setBetIndex(reqRes?.data?.data?.t2[2]?.sid);
                      }}>
                      {reqRes?.data?.data?.t2[2]?.rate}
                      <p>
                        {thirtyTwoCardStack.map(
                          (ele) =>
                            ele.number === 3 && (
                              <span
                                className={`${
                                  ele.number == 3 && ele.amount >= 0
                                    ? "success-1"
                                    : "zero"
                                }`}>
                                {ele.number == 3 && ele.amount}
                              </span>
                            )
                        )}
                      </p>
                    </button>
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center px-2 position-relative">
                    {reqRes?.data?.data?.t2[3]?.gstatus == 0 && (
                      <div className="col-3 suspended__1">
                        <i className="fa fa-lock"></i>
                      </div>
                    )}
                    <button
                      className="back-btn"
                      onClick={() => {
                        handleModal(
                          reqRes?.data?.data?.t2[3]?.rate,
                          reqRes?.data?.data?.t2[3]?.nat,
                          4
                        );
                        setBetIndex(reqRes?.data?.data?.t2[3]?.sid);
                      }}>
                      {reqRes?.data?.data?.t2[3]?.rate}
                      <p>
                        {thirtyTwoCardStack.map(
                          (ele) =>
                            ele.number === 4 && (
                              <span
                                className={`${
                                  ele.number == 4 && ele.amount >= 0
                                    ? "success-1"
                                    : "zero"
                                }`}>
                                {ele.number == 4 && ele.amount}
                              </span>
                            )
                        )}
                      </p>
                    </button>
                  </div>
                </div>
                <div className="card-table-inner-row row">
                  <div className="col-6 d-flex align-items-center ps-2">
                    Two Pair{" "}
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center px-2 position-relative">
                    {reqRes?.data?.data?.t2[4]?.gstatus == 0 && (
                      <div className="col-3 suspended__1">
                        <i className="fa fa-lock"></i>
                      </div>
                    )}
                    <button
                      className="back-btn"
                      onClick={() => {
                        handleModal(
                          reqRes?.data?.data?.t2[4]?.rate,
                          reqRes?.data?.data?.t2[4]?.nat,
                          5
                        );
                        setBetIndex(reqRes?.data?.data?.t2[4]?.sid);
                      }}>
                      {reqRes?.data?.data?.t2[4]?.rate}
                      <p>
                        {thirtyTwoCardStack.map(
                          (ele) =>
                            ele.number === 5 && (
                              <span
                                className={`${
                                  ele.number == 5 && ele.amount >= 0
                                    ? "success-1"
                                    : "zero"
                                }`}>
                                {ele.number == 5 && ele.amount}
                              </span>
                            )
                        )}
                      </p>
                    </button>
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center px-2 position-relative">
                    {reqRes?.data?.data?.t2[5]?.gstatus == 0 && (
                      <div className="col-3 suspended__1">
                        <i className="fa fa-lock"></i>
                      </div>
                    )}
                    <button
                      className="back-btn"
                      onClick={() => {
                        handleModal(
                          reqRes?.data?.data?.t2[5]?.rate,
                          reqRes?.data?.data?.t2[5]?.nat,
                          6
                        );
                        setBetIndex(reqRes?.data?.data?.t2[5]?.sid);
                      }}>
                      {reqRes?.data?.data?.t2[5]?.rate}
                      <p>
                        {thirtyTwoCardStack.map(
                          (ele) =>
                            ele.number === 6 && (
                              <span
                                className={`${
                                  ele.number == 6 && ele.amount >= 0
                                    ? "success-1"
                                    : "zero"
                                }`}>
                                {ele.number == 6 && ele.amount}
                              </span>
                            )
                        )}
                      </p>
                    </button>
                  </div>
                </div>
                <div className="card-table-inner-row row">
                  <div className="col-6 d-flex align-items-center ps-2">
                    Three of a Kind{" "}
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center px-2 position-relative">
                    {reqRes?.data?.data?.t2[6]?.gstatus == 0 && (
                      <div className="col-3 suspended__1">
                        <i className="fa fa-lock"></i>
                      </div>
                    )}
                    <button
                      className="back-btn"
                      onClick={() => {
                        handleModal(
                          reqRes?.data?.data?.t2[6]?.rate,
                          reqRes?.data?.data?.t2[6]?.nat,
                          7
                        );
                        setBetIndex(reqRes?.data?.data?.t2[6]?.sid);
                      }}>
                      {reqRes?.data?.data?.t2[6]?.rate}
                      <p>
                        {thirtyTwoCardStack.map(
                          (ele) =>
                            ele.number === 7 && (
                              <span
                                className={`${
                                  ele.number == 7 && ele.amount >= 0
                                    ? "success-1"
                                    : "zero"
                                }`}>
                                {ele.number == 7 && ele.amount}
                              </span>
                            )
                        )}
                      </p>
                    </button>
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center px-2 position-relative">
                    {reqRes?.data?.data?.t2[7]?.gstatus == 0 && (
                      <div className="col-3 suspended__1">
                        <i className="fa fa-lock"></i>
                      </div>
                    )}
                    <button
                      className="back-btn"
                      onClick={() => {
                        handleModal(
                          reqRes?.data?.data?.t2[7]?.rate,
                          reqRes?.data?.data?.t2[7]?.nat,
                          8
                        );
                        setBetIndex(reqRes?.data?.data?.t2[7]?.sid);
                      }}>
                      {reqRes?.data?.data?.t2[7]?.rate}
                      <p>
                        {thirtyTwoCardStack.map(
                          (ele) =>
                            ele.number === 8 && (
                              <span
                                className={`${
                                  ele.number == 8 && ele.amount >= 0
                                    ? "success-1"
                                    : "zero"
                                }`}>
                                {ele.number == 8 && ele.amount}
                              </span>
                            )
                        )}
                      </p>
                    </button>
                  </div>
                </div>
                <div className="card-table-inner-row row">
                  <div className="col-6 d-flex align-items-center ps-2">
                    Staright{" "}
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center px-2 position-relative">
                    {reqRes?.data?.data?.t2[8]?.gstatus == 0 && (
                      <div className="col-3 suspended__1">
                        <i className="fa fa-lock"></i>
                      </div>
                    )}
                    <button
                      className="back-btn"
                      onClick={() => {
                        handleModal(
                          reqRes?.data?.data?.t2[8]?.rate,
                          reqRes?.data?.data?.t2[8]?.nat,
                          9
                        );
                        setBetIndex(reqRes?.data?.data?.t2[8]?.sid);
                      }}>
                      {reqRes?.data?.data?.t2[8]?.rate}
                      <p>
                        {thirtyTwoCardStack.map(
                          (ele) =>
                            ele.number === 9 && (
                              <span
                                className={`${
                                  ele.number == 9 && ele.amount >= 0
                                    ? "success-1"
                                    : "zero"
                                }`}>
                                {ele.number == 9 && ele.amount}
                              </span>
                            )
                        )}
                      </p>
                    </button>
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center px-2 position-relative">
                    {reqRes?.data?.data?.t2[9]?.gstatus == 0 && (
                      <div className="col-3 suspended__1">
                        <i className="fa fa-lock"></i>
                      </div>
                    )}
                    <button
                      className="back-btn"
                      onClick={() => {
                        handleModal(
                          reqRes?.data?.data?.t2[9]?.rate,
                          reqRes?.data?.data?.t2[9]?.nat,
                          10
                        );
                        setBetIndex(reqRes?.data?.data?.t2[9]?.sid);
                      }}>
                      {reqRes?.data?.data?.t2[9]?.rate}
                      <p>
                        {thirtyTwoCardStack.map(
                          (ele) =>
                            ele.number === 10 && (
                              <span
                                className={`${
                                  ele.number == 10 && ele.amount >= 0
                                    ? "success-1"
                                    : "zero"
                                }`}>
                                {ele.number == 10 && ele.amount}
                              </span>
                            )
                        )}
                      </p>
                    </button>
                  </div>
                </div>
                <div className="card-table-inner-row row">
                  <div className="col-6 d-flex align-items-center ps-2">
                    Flush{" "}
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center px-2 position-relative">
                    {reqRes?.data?.data?.t2[10]?.gstatus == 0 && (
                      <div className="col-3 suspended__1">
                        <i className="fa fa-lock"></i>
                      </div>
                    )}
                    <button
                      className="back-btn"
                      onClick={() => {
                        handleModal(
                          reqRes?.data?.data?.t2[10]?.rate,
                          reqRes?.data?.data?.t2[10]?.nat,
                          11
                        );
                        setBetIndex(reqRes?.data?.data?.t2[10]?.sid);
                      }}>
                      {reqRes?.data?.data?.t2[10]?.rate}
                      <p>
                        {thirtyTwoCardStack.map(
                          (ele) =>
                            ele.number === 11 && (
                              <span
                                className={`${
                                  ele.number == 11 && ele.amount >= 0
                                    ? "success-1"
                                    : "zero"
                                }`}>
                                {ele.number == 11 && ele.amount}
                              </span>
                            )
                        )}
                      </p>
                    </button>
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center px-2 position-relative">
                    {reqRes?.data?.data?.t2[11]?.gstatus == 0 && (
                      <div className="col-3 suspended__1">
                        <i className="fa fa-lock"></i>
                      </div>
                    )}
                    <button
                      className="back-btn"
                      onClick={() => {
                        handleModal(
                          reqRes?.data?.data?.t2[11]?.rate,
                          reqRes?.data?.data?.t2[11]?.nat,
                          12
                        );
                        setBetIndex(reqRes?.data?.data?.t2[11]?.sid);
                      }}>
                      {reqRes?.data?.data?.t2[11]?.rate}
                      <p>
                        {thirtyTwoCardStack.map(
                          (ele) =>
                            ele.number === 12 && (
                              <span
                                className={`${
                                  ele.number == 12 && ele.amount >= 0
                                    ? "success-1"
                                    : "zero"
                                }`}>
                                {ele.number == 12 && ele.amount}
                              </span>
                            )
                        )}
                      </p>
                    </button>
                  </div>
                </div>
                <div className="card-table-inner-row row">
                  <div className="col-6 d-flex align-items-center ps-2">
                    Full House{" "}
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center px-2 position-relative">
                    {reqRes?.data?.data?.t2[12]?.gstatus == 0 && (
                      <div className="col-3 suspended__1">
                        <i className="fa fa-lock"></i>
                      </div>
                    )}
                    <button
                      className="back-btn"
                      onClick={() => {
                        handleModal(
                          reqRes?.data?.data?.t2[12]?.rate,
                          reqRes?.data?.data?.t2[12]?.nat,
                          13
                        );
                        setBetIndex(reqRes?.data?.data?.t2[12]?.sid);
                      }}>
                      {reqRes?.data?.data?.t2[12]?.rate}
                      <p>
                        {thirtyTwoCardStack.map(
                          (ele) =>
                            ele.number === 13 && (
                              <span
                                className={`${
                                  ele.number == 13 && ele.amount >= 0
                                    ? "success-1"
                                    : "zero"
                                }`}>
                                {ele.number == 13 && ele.amount}
                              </span>
                            )
                        )}
                      </p>
                    </button>
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center px-2 position-relative">
                    {reqRes?.data?.data?.t2[13]?.gstatus == 0 && (
                      <div className="col-3 suspended__1">
                        <i className="fa fa-lock"></i>
                      </div>
                    )}
                    <button
                      className="back-btn"
                      onClick={() => {
                        handleModal(
                          reqRes?.data?.data?.t2[13]?.rate,
                          reqRes?.data?.data?.t2[13]?.nat,
                          14
                        );
                        setBetIndex(reqRes?.data?.data?.t2[13]?.sid);
                      }}>
                      {reqRes?.data?.data?.t2[13]?.rate}
                      <p>
                        {thirtyTwoCardStack.map(
                          (ele) =>
                            ele.number === 14 && (
                              <span
                                className={`${
                                  ele.number == 14 && ele.amount >= 0
                                    ? "success-1"
                                    : "zero"
                                }`}>
                                {ele.number == 14 && ele.amount}
                              </span>
                            )
                        )}
                      </p>
                    </button>
                  </div>
                </div>
                <div className="card-table-inner-row row">
                  <div className="col-6 d-flex align-items-center ps-2">
                    Four of a Kind{" "}
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center px-2 position-relative">
                    {reqRes?.data?.data?.t2[14]?.gstatus == 0 && (
                      <div className="col-3 suspended__1">
                        <i className="fa fa-lock"></i>
                      </div>
                    )}
                    <button
                      className="back-btn"
                      onClick={() => {
                        handleModal(
                          reqRes?.data?.data?.t2[14]?.rate,
                          reqRes?.data?.data?.t2[14]?.nat,
                          15
                        );
                        setBetIndex(reqRes?.data?.data?.t2[14]?.sid);
                      }}>
                      {reqRes?.data?.data?.t2[14]?.rate}
                      <p>
                        {thirtyTwoCardStack.map(
                          (ele) =>
                            ele.number === 15 && (
                              <span
                                className={`${
                                  ele.number == 15 && ele.amount >= 0
                                    ? "success-1"
                                    : "zero"
                                }`}>
                                {ele.number == 15 && ele.amount}
                              </span>
                            )
                        )}
                      </p>
                    </button>
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center px-2 position-relative">
                    {reqRes?.data?.data?.t2[15]?.gstatus == 0 && (
                      <div className="col-3 suspended__1">
                        <i className="fa fa-lock"></i>
                      </div>
                    )}
                    <button
                      className="back-btn"
                      onClick={() => {
                        handleModal(
                          reqRes?.data?.data?.t2[15]?.rate,
                          reqRes?.data?.data?.t2[15]?.nat,
                          16
                        );
                        setBetIndex(reqRes?.data?.data?.t2[15]?.sid);
                      }}>
                      {reqRes?.data?.data?.t2[15]?.rate}
                      <p>
                        {thirtyTwoCardStack.map(
                          (ele) =>
                            ele.number === 16 && (
                              <span
                                className={`${
                                  ele.number == 16 && ele.amount >= 0
                                    ? "success-1"
                                    : "zero"
                                }`}>
                                {ele.number == 16 && ele.amount}
                              </span>
                            )
                        )}
                      </p>
                    </button>
                  </div>
                </div>
                <div className="card-table-inner-row row">
                  <div className="col-6 d-flex align-items-center ps-2">
                    Straight Flush{" "}
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center px-2 position-relative">
                    {reqRes?.data?.data?.t2[16]?.gstatus == 0 && (
                      <div className="col-3 suspended__1">
                        <i className="fa fa-lock"></i>
                      </div>
                    )}
                    <button
                      className="back-btn"
                      onClick={() => {
                        handleModal(
                          reqRes?.data?.data?.t2[16]?.rate,
                          reqRes?.data?.data?.t2[16]?.nat,
                          17
                        );
                        setBetIndex(reqRes?.data?.data?.t2[16]?.sid);
                      }}>
                      {reqRes?.data?.data?.t2[16]?.rate}
                      <p>
                        {thirtyTwoCardStack.map(
                          (ele) =>
                            ele.number === 17 && (
                              <span
                                className={`${
                                  ele.number == 17 && ele.amount >= 0
                                    ? "success-1"
                                    : "zero"
                                }`}>
                                {ele.number == 17 && ele.amount}
                              </span>
                            )
                        )}
                      </p>
                    </button>
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center px-2 position-relative">
                    {reqRes?.data?.data?.t2[17]?.gstatus == 0 && (
                      <div className="col-3 suspended__1">
                        <i className="fa fa-lock"></i>
                      </div>
                    )}
                    <button
                      className="back-btn"
                      onClick={() => {
                        handleModal(
                          reqRes?.data?.data?.t2[17]?.rate,
                          reqRes?.data?.data?.t2[17]?.nat,
                          18
                        );
                        setBetIndex(reqRes?.data?.data?.t2[17]?.sid);
                      }}>
                      {reqRes?.data?.data?.t2[17]?.rate}
                      <p>
                        {thirtyTwoCardStack.map(
                          (ele) =>
                            ele.number === 18 && (
                              <span
                                className={`${
                                  ele.number == 18 && ele.amount >= 0
                                    ? "success-1"
                                    : "zero"
                                }`}>
                                {ele.number == 18 && ele.amount}
                              </span>
                            )
                        )}
                      </p>
                    </button>
                  </div>
                </div>
              </div>

              <div className="Results-table">
                <div className="color-game-h mt-2">
                  <div className="px-2 pt-1">
                    <p>Last 10 Results</p>
                  </div>
                </div>
                <div className="card rst-card py-1 d-flex">
                  <div className="rst-row d-flex">
                    {results?.data?.data?.map((result, index) => {
                      const item = reqRes?.data?.data?.t2.find(
                        (item) => item.sid === result?.result
                      );

                      return (
                        <div
                          onClick={() => {
                            setMidRound(result.mid);
                            CasinoGameResult_API(result.mid);
                            setShowresultModal(true);
                            setTrophy(resultMapping[result.result]);
                          }}
                          className="rst-btn ms-1"
                          key={index}>
                          {item && <>{resultMapping[result.result]}</>}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              {/* <<<<<<<---------My-Bets-table-------------->>>>>>>>>> */}
              <div className="My-Bets-table">
                <div className="color-game-h mt-2">
                  <div className="px-2 pt-1">
                    <p>Open Bets</p>
                  </div>
                </div>
                {HistoryTable}
                {/* <div className="card-table-inner-row row"></div> */}
              </div>
            </div>
          </div>

          <RightSidebar />
          {showModal && (
            <Modal
              id={id}
              name={name}
              betDetails={betDetails}
              setbetDetails={setbetDetails}
              setShowModal={setShowModal}
              realTime={realTime}
              setRealTimeData={setRealTimeData}
            />
          )}

          {showresultModal && <ResultsModal />}
          {/* {showDataModal && <DataModal />} */}
        </div>
      </div>
    </>
  );
}
