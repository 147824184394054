import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";
import { API } from "../../API/API";
import { logoutUser } from "../../Redux/Slices/authSlice";

const WithdrawHistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [leftreferralApi, setleftreferralApi] = useState([]);

  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(20);
  const [todate, settodate] = useState("");
  const [fromdate, setfromdate] = useState("");

  const userId = sessionStorage.getItem("userName");
  const token = sessionStorage.getItem("token");
  const referral_API = async () => {
    try {
      // console.log("userId-->", userId);
      let responceRight = await API?.get(
        `UserWithdrawlHistory?uid=${userId}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      console.log("AccountStateMent-->", responceRight?.data?.data);

      responceRight = responceRight?.data?.data;
      setleftreferralApi([]);
      let arrayLeft = [];
      // 	UserId	Event	EventType	Match	Yes	No
      Array.isArray(responceRight) &&
        responceRight.forEach((item, index) => {
          arrayLeft.push({
            sr: index + 1,
            uid: item.uid,
            // touserid: item.touserid,
            // wallet: item.wallet
            //   ? item.wallet.substring(0, 4) +
            //     "..." +
            //     item.wallet.substring(item.wallet.length - 4)
            //   : null,
            request_date: item?.req_date,
            Request_amount: item?.Request_amount,
            amountusd: item?.amountusd,
            AGCAmount: item?.AGCAmount,
            admincharge: item?.adminchargerate,
            amount: item?.amount,
            rate: `${item?.rate} %`,
            req_date: item?.rdate,
            Remark: item?.ss,
            ss: item?.ss,
            txn: (
              <a
                className="link-light"
                href={`https://bscscan.com/tx/${item?.txn}`}
                target="_blank"
                style={{ whiteSpace: "nowrap" }}
                rel="noreferrer">
                View Txn
              </a>
            ),
            trans_date: item.trans_date,
          });

          setleftreferralApi(arrayLeft);
        });
    } catch (error) {
      console.log("Error While calling Myteam API", error);
      if (
        error.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };

  useEffect(() => {
    referral_API();
  }, []);
  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = leftreferralApi.slice(indexOfFirstPage, indexOfLastPost);
  var [matching_income, set_matching_income] = useState({
    cols: [
      { Header: "Sr.No", accessor: "sr" },
      { Header: "User ID", accessor: "uid" },
      { Header: "Amount", accessor: "Request_amount" },
      { Header: "Rate %", accessor: "rate" },
      { Header: "Deduction", accessor: "admincharge" },
      { Header: "Pay Amount ", accessor: "amount" },
      { Header: "Requested Date", accessor: "req_date" },
      { Header: "Status", accessor: "ss" },
      { Header: "Paid Date & Time", accessor: "trans_date" },
    ],
  });

  return (
    // <div className="col-lg-10 com-h">
    <div className="col-lg-10 com-h mt-5 pt-3">
      <div className="row">
        <div className="col-12 p-3">
          <div className="bet-h ">
            <h4 className="p-2 ms-2">MLM Withdrawal History</h4>

            {/* <div className="dates-div p-2 d-flex ">
              <div className="row">
                <div className="col-md-5 mt-1">
                  <div className="d-flex">
                    <div className="from d-flex align-items-center justify-content-center">
                      From:
                    </div>
                    <div className="">
                      <input
                        className="from-control"
                        type="date"
                        value={fromdate}
                        onChange={(e) => setfromdate(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-5 mt-1">
                  <div className="d-flex ">
                    <div className="from d-flex align-items-center justify-content-center">
                      To:
                    </div>
                    <div className="">
                      <input
                        className="from-control"
                        type="date"
                        value={todate}
                        onChange={(e) => settodate(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-2 mt-1">
                  <button
                    className="btn btn-success "
                    onClick={() => handleSearch()}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-search"
                      viewBox="0 0 16 16">
                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                    </svg>{" "}
                    Search
                  </button>
                </div>
              </div>
            </div>*/}
            <div className="">
              <Table data={[...currentPost]} columns={matching_income.cols} />
              <Table_Buttons
                setcurrentPage={setcurrentPage}
                currentPage={currentPage}
                totalData={leftreferralApi.length}
                listPerpage={listPerpage}
                handleRowsPerPageChange={setlistPerpage}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithdrawHistory;