import React, { useEffect, useState } from "react";
import RightSidebar from "../RightSidebar/RIghtSidebar";
import a from "../../Media/10SS.png";
import b from "../../Media/3SS.png";
import kblack from "../../Media/KSS_blackpan.jpg";
import kredpan from "../../Media/KHH_redpan.jpg";
import kblackchiri from "../../Media/KCC_blackchiri.jpg";
import kdiamond from "../../Media/KDD_reddimond.jpg";
import Spade from "../../Media/spade-2.png";
import Heart from "../../Media/heart-2.png";
import Club from "../../Media/club-2.png";
import Diamond from "../../Media/diamond-2.png";
import { MdCancel } from "react-icons/md";
import { FaTrophy } from "react-icons/fa";
import "./Race.css";
import { useParams } from "react-router-dom";
import { io } from "socket.io-client";
import { API } from "../../API/API";
import { useDispatch, useSelector } from "react-redux";
import { fetchThirtyTwoCardStack } from "../../Redux/Slices/thirtyTwoCardGameSlice";
import Modal from "../TeenPatti/Modal";
import { card52War } from "../../Utility/52CardImages.js";

export default function RaceTwenty() {
  const { id } = useParams();
  const uid = sessionStorage.getItem("userData");
  const token = sessionStorage.getItem("token");

  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [showresultModal, setShowresultModal] = useState(false);
  const [showDataModal, setShowdataModal] = useState(false);
  const [name, setName] = useState("");
  const [results, setResults] = useState([]);
  const [reqRes, setreqRes] = useState({ t1: [], t2: [] });
  const [realTime, setRealTimeData] = useState(false);
  const [betDetails, setbetDetails] = useState({});
  const [betIndex, setBetIndex] = useState("");
  const [midRound, setMidRound] = useState(0);
  const [casinoGameResult, setCasinoGameResult] = useState([]);
  const [trophy, setTrophy] = useState("");
  const { thirtyTwoCardStack } = useSelector(
    (state) => state.thirtyTwoCardGameStack
  );
  const resultMapping = {
    1: Spade,
    2: Heart,
    3: Club,
    4: Diamond,
  };

  const SOCKET_URL = "https://dreamwin-socket.nakshtech.info/";

  const CasinoGameResult_API = async (mid) => {
    try {
      const response = await API.post("/GetCasinoGameResultData", {
        mid: mid,
        gameid: id,
      });
      // console.log("CasinoGameResult dragon:", response.data.data[0]);
      setCasinoGameResult(response?.data?.data[0]);
    } catch (error) {
      console.error("Error CasinoGameResult_API", error);
    }
  };

  useEffect(() => {
    // console.log("Odd socket before");
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoGameSocket", id);

    cricketMatchesSocket.on("GetCasinoGameSocket_FromAPI", (data) => {
      // console.log("poker socket1==>", data);
      if (data && data.length > 0) {
        const parsedData = data.map((item) => ({
          ...item,
          reqResponse: JSON.parse(item.reqResponse),
          resultUrlResponse: JSON.parse(item.resultUrlResponse),
        }));

        // console.log("parsed", parsedData[0]);
        // console.log("parsed_result", parsedData[0].resultUrlResponse);
        // console.log("parsed_result name---->", parsedData[0].reqResponse);
        setResults(parsedData[0]?.reqResponse);
        setreqRes(parsedData[0]?.reqResponse);
        setName(parsedData[0]?.name);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  // console.log("results", results);

  const ResultsModal = () => {
    return (
      <>
        <div
          onClick={() => {
            setShowresultModal(false);
          }}
          className="Results-modal-wrapper"
        ></div>
        <div className="results-modal-container bg-dark">
          <div className="results-modal-h d-flex justify-content-between pt-2">
            <div>Round ID: {midRound}</div>
            <MdCancel
              className="cross-icon-result me-2"
              onClick={() => {
                setShowresultModal(false);
              }}
            />
          </div>
          <div>
            <div className="row  d-flex justify-content-between">
              {/* <div className="col-1 ms-2 topmarge text-center">
                <h6 className="mb-5">A</h6>
                <h6>B</h6>
              </div> */}
              {/* <div className="col-1 mt-10"> */}

              {/* </div> */}

              <div className="col-8 ms-1">
                <div className="">
                  <div className="d-flex ">
                    <span className="col-1 mt-3 mr-5">
                      {" "}
                      <img className="w-100 h-auto" src={Spade} alt="1" />
                    </span>
                    {casinoGameResult
                      .filter((card) => card.col3 === "HH")
                      .map((card) => (
                        <div className="col-1 mt-3" key={card.id}>
                          <img
                            src={card.image}
                            className="img-fluid"
                            alt={`Player Card ${card.id}`}
                          />
                        </div>
                      ))}
                  </div>
                  <div className="d-flex">
                    <span className="col-1 mt-3 mr-5">
                      {" "}
                      <img className="w-100 h-auto" src={Heart} alt="1" />
                    </span>
                    {casinoGameResult
                      .filter((card) => card.col3 === "DD")
                      .map((card) => (
                        <div className="col-1 mt-3" key={card.id}>
                          <img
                            src={card.image}
                            className="img-fluid"
                            alt={`Player Card ${card.id}`}
                          />
                        </div>
                      ))}
                  </div>
                  <div className="d-flex ">
                    <span className="col-1 mt-3 mr-5">
                      {" "}
                      <img className="w-100 h-auto" src={Club} alt="1" />
                    </span>
                    {casinoGameResult
                      .filter((card) => card.col3 === "CC")
                      .map((card) => (
                        <div className="col-1 mt-3" key={card.id}>
                          <img
                            src={card.image}
                            className="img-fluid"
                            alt={`Player Card ${card.id}`}
                          />
                        </div>
                      ))}
                  </div>
                  <div className="d-flex">
                    <span className="col-1 mt-3 mr-5">
                      {" "}
                      <img className="w-100 h-auto" src={Diamond} alt="1" />
                    </span>
                    {casinoGameResult
                      .filter((card) => card.col3 === "SS")
                      .map((card) => (
                        <div className="col-1 mt-3" key={card.id}>
                          <img
                            src={card.image}
                            className="img-fluid"
                            alt={`Player Card ${card.id}`}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  async function ResultScheduler_API() {
    try {
      let res = await API.post("/Schulder_7CareGameLive", {
        gameid: id,
        username: uid
      },
        {
          headers: {
            Authorization: token,
          },
        });
      // console.log("scheduler res", res.data);
    } catch (e) {
      console.log("Error", e);
    }
  }

  const [history, setHistory] = useState([]);

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoBetReportSocket", uid, id);
    cricketMatchesSocket.on("GetCasinoBetReportSocket_FromAPI", (data) => {
      if (data && data[0]?.length > 0) {
        setHistory(data[0]);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, [id]);

  useEffect(() => {
    ResultScheduler_API();
    dispatch(fetchThirtyTwoCardStack({ uid, gameId: id, token }));
  }, [dispatch, reqRes?.data?.data?.t1[0]?.mid]);

  const HistoryTable = (
    <>
      <div className="table-h-div row table-responsive">
        <table>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Period</th>
              <th>Multiplier</th>
              <th>Amount</th>
              <th>Date</th>
              <th>Remark</th>
              <th>Card</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {/* {loading ? (
              <tr>
                <td colSpan="6">Loading ...</td>
              </tr>
            ) : */}
            {history.length > 0 ? (
              history.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.period}</td>
                  <td>{item.multipler}</td>
                  <td>{item.amount}</td>
                  <td>{item.dd}</td>
                  <td className={item.remark === "Loss" ? "zero" : "success-1"}>
                    {item.remark}
                  </td>
                  <td>{item.card}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  No Data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );

  const handleModal = (value, nat, no) => {
    setbetDetails({
      ...betDetails,
      gameid: id,
      multipler: value,
      period: 0,
      nat: nat,
      number: no,
    });
    setShowModal(true);
    setRealTimeData(true);
  };

  function checkValid() {
    if (showModal && realTime) {
      let filteredData = reqRes?.data?.data?.t2.filter(
        (item) => item.sid === betIndex
      );
      // console.log("filter", filteredData, betDetails);
      filteredData[0]?.gstatus === "ACTIVE" &&
      (betDetails?.multipler === filteredData[0]?.l1 ||
        betDetails?.multipler === filteredData[0]?.b1 || 
        betDetails.multipler === parseFloat(2.05)||
        betDetails.multipler === parseFloat(2.00)||
        betDetails.multipler === parseFloat(1.90))
        ? setRealTimeData(true)
        : setRealTimeData(false);
    }
  }

  useEffect(() => {
    checkValid();
  }, [showModal, realTime, reqRes?.data?.data?.t2, betDetails]);

  const findCardImage = (cardId) => {
    const card = card52War?.find((card) => card.id === cardId);
    return card ? card.src : null;
  };

  const parseCardData = (cardData) => {
    return cardData?.split(",").map((card) => {
      const cardName = card.trim(); // Extract card name and trim any whitespace
      return cardName;
    });
  };

  const arrayData = parseCardData(reqRes?.data?.data?.t1[0]?.desc ?? "");
  // console.log("array", arrayData);

  const numCards = [
    "A",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "J",
    "Q",
    "K",
  ];

  return (
    <>
      <div className="col-lg-10 ">
        <div className="row home mainhomeDev">
          <div className="col-lg-8 p-1">
            <div className="teenpatti-game-div pt-2 p-2">
              <div className="teenpatti-game-h d-flex justify-content-between  ">
                <div className=" px-2">
                  <p className=" mt-1 ">{name}</p>
                </div>
                <div>
                  <p className="mt-1 px-2">
                    Round ID: {reqRes?.data?.data?.t1[0]?.mid || 0.0} | Left
                    Time: {reqRes?.data?.data?.t1[0]?.autotime || 0} | Rules
                  </p>
                </div>
              </div>
              <div className="card  teenpatti-game-card p-1 mt-1">
                <div className="row">
                  <div className="col-10">
                    <div className="" style={{ height: "170%", width: "120%" }}>
                      <iframe
                        height="100%"
                        width="100%"
                        src={`https://battlemoney.live/iframe/${id}`}
                        title=""
                      ></iframe>
                    </div>
                  </div>
                  <div className="col-2 d-flex justify-content-end">
                    <label className="switch">
                      <input type="checkbox" />
                      <span className="slider round"></span>
                    </label>
                  </div>
                  <div className="timer ">
                    <div
                      className="container"
                      style={{
                        color: "white",
                        marginLeft: "-1px",
                        marginTop: "-150px",
                      }}
                    >
                      <div className="col-8 ms-1">
                        <div className="">
                          <div className="d-flex ">
                            <span className="col-1 mt-3 mr-5">
                              {" "}
                              <img
                                className="w-100 h-auto"
                                src={Spade}
                                alt="1"
                              />
                            </span>
                            {arrayData
                              .filter((card) => {
                                // Extract the card prefix before "DD"
                                const cardPrefix = card.replace("HH", "");
                                return numCards.includes(cardPrefix);
                              })
                              .map((card, index) => (
                                <div className="col-1 mt-3" key={index}>
                                  <img
                                    src={findCardImage(card)} // Assume findCardImage can find the image based on card name
                                    className="img-fluid"
                                    style={{ width: "30px", height: "auto" }}
                                  />
                                </div>
                              ))}
                           
                          </div>
                          <div className="d-flex">
                            <span className="col-1 mt-3 mr-5">
                              {" "}
                              <img
                                className="w-100 h-auto"
                                src={Heart}
                                alt="1"
                              />
                            </span>
                            {arrayData
                              .filter((card) => {
                                // Extract the card prefix before "DD"
                                const cardPrefix = card.replace("DD", "");
                                return numCards.includes(cardPrefix);
                              })
                              .map((card, index) => (
                                <div className="col-1 mt-3" key={index}>
                                  <img
                                    src={findCardImage(card)} // Assume findCardImage can find the image based on card name
                                    className="img-fluid"
                                    style={{ width: "30px", height: "auto" }}
                                  />
                                </div>
                              ))}
                          </div>
                          <div className="d-flex ">
                            <span className="col-1 mt-3 mr-5">
                              {" "}
                              <img
                                className="w-100 h-auto"
                                src={Club}
                                alt="1"
                              />
                            </span>
                            {arrayData
                              .filter((card) => {
                                // Extract the card prefix before "CC"
                                const cardPrefix = card.replace("CC", "");
                                return numCards.includes(cardPrefix);
                              })
                              .map((card, index) => (
                                <div className="col-1 mt-3" key={index}>
                                  <img
                                    src={findCardImage(card)} // Assume findCardImage can find the image based on card name
                                    className="img-fluid"
                                    style={{ width: "30px", height: "auto" }}
                                  />
                                </div>
                              ))}
                 
                          </div>
                          <div className="d-flex">
                            <span className="col-1 mt-3 mr-5">
                              {" "}
                              <img
                                className="w-100 h-auto"
                                src={Diamond}
                                alt="1"
                              />
                            </span>
                            {arrayData
                              .filter((card) => {
                                // Extract the card prefix before "CC"
                                const cardPrefix = card.replace("SS", "");
                                return numCards.includes(cardPrefix);
                              })
                              .map((card, index) => (
                                <div className="col-1 mt-3" key={index}>
                                  <img
                                    src={findCardImage(card)} // Assume findCardImage can find the image based on card name
                                    className="img-fluid"
                                    style={{ width: "30px", height: "auto" }}
                                  />
                                </div>
                              ))}
                       
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="timer "
                    style={{ marginLeft: "550px", marginTop: "20px" }}>
                    <div className="container">
                    <div
                      className="timer_container position-absolute"
                      style={{ right: "36px", bottom: "15px" }}
                    >
                      <button
                        class="btn btn-secondary"
                        style={{ cursor: "default", background: "#6f42c1" }}
                      >
                        <span>
                          {" "}
                          {reqRes?.data?.data?.t1[0]?.autotime || 0}{" "}
                        </span>
                      </button>{" "}
                    </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <-------------------------------Back-lay-table----------> */}
              <div className="middlecardracet">
                <div className="casino-table">
                  <div className="casino-table-box">
                    <div className="casino-odd-box-container">
                      <div className="casino-nation-name">
                        <img src={kblack} alt="" />
                      </div>

                      {reqRes?.data?.data?.t2[0]?.gstatus == "SUSPENDED" ||
                      reqRes?.data?.data?.t2[0]?.gstatus == "CLOSED" ? (
                        <div className="casino-odds-box back suspended-box">
                          <div className="casino-volume">
                            {reqRes?.data?.data?.t2[0]?.gstatus}
                          </div>
                        </div>
                      ) : (
                        <div
                          className="casino-odds-box back "
                          onClick={() => {
                            handleModal(
                              reqRes?.data?.data?.t2[0]?.b1,
                              reqRes?.data?.data?.t2[0]?.nat,
                              1
                            );
                            setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                          }}
                        >
                          <div className="casino-odds">
                            {reqRes?.data?.data?.t2[0]?.b1}
                          </div>
                          <span className="casino-volume">10000</span>
                        </div>
                      )}
                      {reqRes?.data?.data?.t2[0]?.gstatus == "SUSPENDED" ||
                      reqRes?.data?.data?.t2[0]?.gstatus == "CLOSED" ? (
                        <div className="casino-odds-box lay suspended-box">
                          <div className="casino-volume">
                            {reqRes?.data?.data?.t2[0]?.gstatus}
                          </div>
                        </div>
                      ) : (
                        <div
                          className="casino-odds-box lay "
                          onClick={() => {
                            handleModal(
                              reqRes?.data?.data?.t2[0]?.l1,
                              reqRes?.data?.data?.t2[0]?.nat,
                              5
                            );
                            setBetIndex(reqRes?.data?.data?.t2[0]?.sid);
                          }}
                        >
                          <div className="casino-odds">
                            {reqRes?.data?.data?.t2[0]?.l1}
                          </div>
                          <span className="casino-volume">10000</span>
                        </div>
                      )}

                      <div className="casino-nation-book text-center w-100">
                        {" "}
                        {thirtyTwoCardStack.map(
                          (ele) =>
                            ele.number === 1 && (
                              <span
                                className={`${
                                  ele.number == 1 && ele.amount >= 0
                                    ? "success-1"
                                    : "zero"
                                }`}
                              >
                                {ele.number == 1 && ele.amount}
                              </span>
                            )
                        )}
                      </div>
                    </div>
                    <div className="casino-odd-box-container">
                      <div className="casino-nation-name">
                        <img src={kredpan} alt="" />
                      </div>
                      {reqRes?.data?.data?.t2[1]?.gstatus == "SUSPENDED" ||
                      reqRes?.data?.data?.t2[1]?.gstatus == "CLOSED" ? (
                        <div className="casino-odds-box back suspended-box">
                          <div className="casino-volume">
                            {reqRes?.data?.data?.t2[1]?.gstatus}
                          </div>
                        </div>
                      ) : (
                        <div
                          className="casino-odds-box back "
                          onClick={() => {
                            handleModal(
                              reqRes?.data?.data?.t2[1]?.b1,
                              reqRes?.data?.data?.t2[1]?.nat,
                              2
                            );
                            setBetIndex(reqRes?.data?.data?.t2[1]?.sid);
                          }}
                        >
                          <div className="casino-odds">
                            {reqRes?.data?.data?.t2[1]?.b1}
                          </div>
                          <span className="casino-volume">10000</span>
                        </div>
                      )}
                      {reqRes?.data?.data?.t2[1]?.gstatus == "SUSPENDED" ||
                      reqRes?.data?.data?.t2[1]?.gstatus == "CLOSED" ? (
                        <div className="casino-odds-box lay suspended-box">
                          <div className="casino-volume">
                            {reqRes?.data?.data?.t2[1]?.gstatus}
                          </div>
                        </div>
                      ) : (
                        <div
                          className="casino-odds-box lay "
                          onClick={() => {
                            handleModal(
                              reqRes?.data?.data?.t2[1]?.l1,
                              reqRes?.data?.data?.t2[1]?.nat,
                              6
                            );
                            setBetIndex(reqRes?.data?.data?.t2[1]?.sid);
                          }}
                        >
                          <div className="casino-odds">
                            {reqRes?.data?.data?.t2[1]?.l1}
                          </div>
                          <span className="casino-volume">10000</span>
                        </div>
                      )}
                      <div className="casino-nation-book text-center w-100">
                        {thirtyTwoCardStack.map(
                          (ele) =>
                            ele.number === 2 && (
                              <span
                                className={`${
                                  ele.number == 2 && ele.amount >= 0
                                    ? "success-1"
                                    : "zero"
                                }`}
                              >
                                {ele.number == 2 && ele.amount}
                              </span>
                            )
                        )}
                      </div>
                    </div>
                    <div className="casino-odd-box-container">
                      <div className="casino-nation-name">
                        <img src={kblackchiri} alt="" />
                      </div>
                      {reqRes?.data?.data?.t2[2]?.gstatus == "SUSPENDED" ||
                      reqRes?.data?.data?.t2[2]?.gstatus == "CLOSED" ? (
                        <div className="casino-odds-box back suspended-box">
                          <div className="casino-volume">
                            {reqRes?.data?.data?.t2[2]?.gstatus}
                          </div>
                        </div>
                      ) : (
                        <div
                          className="casino-odds-box back "
                          onClick={() => {
                            handleModal(
                              reqRes?.data?.data?.t2[2]?.b1,
                              reqRes?.data?.data?.t2[2]?.nat,
                              3
                            );
                            setBetIndex(reqRes?.data?.data?.t2[2]?.sid);
                          }}
                        >
                          <div className="casino-odds">
                            {reqRes?.data?.data?.t2[2]?.b1}
                          </div>
                          <span className="casino-volume">10000</span>
                        </div>
                      )}
                      {reqRes?.data?.data?.t2[2]?.gstatus == "SUSPENDED" ||
                      reqRes?.data?.data?.t2[2]?.gstatus == "CLOSED" ? (
                        <div className="casino-odds-box lay suspended-box">
                          <div className="casino-volume">
                            {reqRes?.data?.data?.t2[2]?.gstatus}
                          </div>
                        </div>
                      ) : (
                        <div
                          className="casino-odds-box lay "
                          onClick={() => {
                            handleModal(
                              reqRes?.data?.data?.t2[2]?.l1,
                              reqRes?.data?.data?.t2[2]?.nat,
                              7
                            );
                            setBetIndex(reqRes?.data?.data?.t2[2]?.sid);
                          }}
                        >
                          <div className="casino-odds">
                            {reqRes?.data?.data?.t2[2]?.l1}
                          </div>
                          <span className="casino-volume">10000</span>
                        </div>
                      )}
                      <div className="casino-nation-book text-center w-100">
                        {thirtyTwoCardStack.map(
                          (ele) =>
                            ele.number === 3 && (
                              <span
                                className={`${
                                  ele.number == 3 && ele.amount >= 0
                                    ? "success-1"
                                    : "zero"
                                }`}
                              >
                                {ele.number == 3 && ele.amount}
                              </span>
                            )
                        )}
                      </div>
                    </div>
                    <div className="casino-odd-box-container">
                      <div className="casino-nation-name">
                        <img src={kdiamond} />
                      </div>
                      {reqRes?.data?.data?.t2[3]?.gstatus == "SUSPENDED" ||
                      reqRes?.data?.data?.t2[3]?.gstatus == "CLOSED" ? (
                        <div className="casino-odds-box back suspended-box">
                          <div className="casino-volume">
                            {reqRes?.data?.data?.t2[3]?.gstatus}
                          </div>
                        </div>
                      ) : (
                        <div
                          className="casino-odds-box back "
                          onClick={() => {
                            handleModal(
                              reqRes?.data?.data?.t2[3]?.b1,
                              reqRes?.data?.data?.t2[3]?.nat,
                              4
                            );
                            setBetIndex(reqRes?.data?.data?.t2[3]?.sid);
                          }}
                        >
                          <div className="casino-odds">
                            {reqRes?.data?.data?.t2[3]?.b1}
                          </div>
                          <span className="casino-volume">10000</span>
                        </div>
                      )}
                      {reqRes?.data?.data?.t2[3]?.gstatus == "SUSPENDED" ||
                      reqRes?.data?.data?.t2[3]?.gstatus == "CLOSED" ? (
                        <div className="casino-odds-box lay suspended-box">
                          <div className="casino-volume">
                            {reqRes?.data?.data?.t2[3]?.gstatus}
                          </div>
                        </div>
                      ) : (
                        <div
                          className="casino-odds-box lay "
                          onClick={() => {
                            handleModal(
                              reqRes?.data?.data?.t2[3]?.l1,
                              reqRes?.data?.data?.t2[3]?.nat,
                              8
                            );
                            setBetIndex(reqRes?.data?.data?.t2[3]?.sid);
                          }}
                        >
                          <div className="casino-odds">
                            {reqRes?.data?.data?.t2[3]?.l1}
                          </div>
                          <span className="casino-volume">10000</span>
                        </div>
                      )}
                      <div className="casino-nation-book text-center w-100">
                        {thirtyTwoCardStack.map(
                          (ele) =>
                            ele.number === 4 && (
                              <span
                                className={`${
                                  ele.number == 4 && ele.amount >= 0
                                    ? "success-1"
                                    : "zero"
                                }`}
                              >
                                {ele.number == 4 && ele.amount}
                              </span>
                            )
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="casino-table-box mt-3">
                    <div className="casino-table-left-box">
                      <div className="casino-odd-box-container">
                        <div className="casino-nation-name"></div>
                        <div className="casino-nation-name">No</div>
                        <div className="casino-nation-name">Yes</div>
                      </div>
                      <div className="casino-odd-box-container">
                        <div className="casino-nation-name">
                          {reqRes?.data?.data?.t2[4]?.nat}
                        </div>
                        {reqRes?.data?.data?.t2[4]?.gstatus == "SUSPENDED" ||
                        reqRes?.data?.data?.t2[4]?.gstatus == "CLOSED" ? (
                          <div className="casino-odds-box lay suspended-box">
                            <div className="casino-volume">
                              {reqRes?.data?.data?.t2[4]?.gstatus}
                            </div>
                          </div>
                        ) : (
                          <div
                            className="casino-odds-box lay "
                            onClick={() => {
                              handleModal(
                                // reqRes?.data?.data?.t2[4]?.l1,
                                2,
                                reqRes?.data?.data?.t2[4]?.nat,
                                9
                              );
                              setBetIndex(reqRes?.data?.data?.t2[4]?.sid);
                            }}
                          >
                            <div className="casino-odds">
                              {reqRes?.data?.data?.t2[4]?.l1}
                            </div>
                            <span className="casino-volume">100</span>
                          </div>
                        )}

                        {reqRes?.data?.data?.t2[4]?.gstatus == "SUSPENDED" ||
                        reqRes?.data?.data?.t2[4]?.gstatus == "CLOSED" ? (
                          <div className="casino-odds-box back suspended-box">
                            <div className="casino-volume">
                              {reqRes?.data?.data?.t2[4]?.gstatus}
                            </div>
                          </div>
                        ) : (
                          <div
                            className="casino-odds-box back "
                            onClick={() => {
                              handleModal(
                                2,
                                reqRes?.data?.data?.t2[4]?.nat,
                                10
                              );
                              setBetIndex(reqRes?.data?.data?.t2[4]?.sid);
                            }}
                          >
                            <div className="casino-odds">
                              {reqRes?.data?.data?.t2[4]?.b1}
                            </div>
                            <span className="casino-volume">100</span>
                          </div>
                        )}
                        <div className="casino-nation-book">
                          {" "}
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 5 && (
                                <span
                                  className={`${
                                    ele.number == 5 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}
                                >
                                  {ele.number == 5 && ele.amount}
                                </span>
                              )
                          )}
                        </div>
                      </div>
                      <div className="casino-odd-box-container">
                        <div className="casino-nation-name"></div>
                        <div className="casino-nation-name">No</div>
                        <div className="casino-nation-name">Yes</div>
                      </div>
                      <div className="casino-odd-box-container">
                        <div className="casino-nation-name">
                          {" "}
                          {reqRes?.data?.data?.t2[5]?.nat}
                        </div>
                        {reqRes?.data?.data?.t2[5]?.gstatus == "SUSPENDED" ||
                        reqRes?.data?.data?.t2[5]?.gstatus == "CLOSED" ? (
                          <div className="casino-odds-box lay suspended-box">
                            <div className="casino-volume">
                              {reqRes?.data?.data?.t2[5]?.gstatus}
                            </div>
                          </div>
                        ) : (
                          <div
                            className="casino-odds-box lay "
                            onClick={() => {
                              handleModal(
                                // reqRes?.data?.data?.t2[5]?.l1,
                                2.05,
                                // parseFloat(105)/ parseFloat(100),
                                reqRes?.data?.data?.t2[5]?.nat,
                                11
                              );
                              setBetIndex(reqRes?.data?.data?.t2[5]?.sid);
                            }}
                          >
                            <div className="casino-odds">
                              {reqRes?.data?.data?.t2[5]?.l1}
                            </div>
                            <span className="casino-volume">105</span>
                          </div>
                        )}
                        {reqRes?.data?.data?.t2[5]?.gstatus == "SUSPENDED" ||
                        reqRes?.data?.data?.t2[5]?.gstatus == "CLOSED" ? (
                          <div className="casino-odds-box back suspended-box">
                            <div className="casino-volume">
                              {reqRes?.data?.data?.t2[5]?.gstatus}
                            </div>
                          </div>
                        ) : (
                          <div
                            className="casino-odds-box back "
                            onClick={() => {
                              handleModal(
                                // reqRes?.data?.data?.t2[5]?.b1,
                                // parseFloat(90)/ parseFloat(100),
                                1.9,
                                reqRes?.data?.data?.t2[5]?.nat,
                                12
                              );
                              setBetIndex(reqRes?.data?.data?.t2[5]?.sid);
                            }}
                          >
                            <div className="casino-odds">
                              {reqRes?.data?.data?.t2[5]?.b1}
                            </div>
                            <span className="casino-volume">90</span>
                          </div>
                        )}
                        <div className="casino-nation-book">
                          {" "}
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 6 && (
                                <span
                                  className={`${
                                    ele.number == 6 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}
                                >
                                  {ele.number == 6 && ele.amount}
                                </span>
                              )
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="casino-table-right-box">
                      <div className="casino-odd-box-container">
                        <div className="casino-nation-name">
                          {" "}
                          {reqRes?.data?.data?.t2[6]?.nat}
                        </div>
                        {reqRes?.data?.data?.t2[6]?.gstatus == "SUSPENDED" ||
                        reqRes?.data?.data?.t2[6]?.gstatus == "CLOSED" ? (
                          <div className="casino-odds-box back suspended-box w-100">
                            <div className="casino-volume">
                              {reqRes?.data?.data?.t2[6]?.gstatus}
                            </div>
                          </div>
                        ) : (
                          <div
                            className="casino-odds-box back w-100 "
                            onClick={() => {
                              handleModal(
                                reqRes?.data?.data?.t2[6]?.b1,
                                reqRes?.data?.data?.t2[6]?.nat,
                                13
                              );
                              setBetIndex(reqRes?.data?.data?.t2[6]?.sid);
                            }}
                          >
                            <div className="casino-odds">
                              {reqRes?.data?.data?.t2[6]?.b1}
                            </div>                            
                          </div>
                        )}

                        <div className="casino-nation-book text-center w-100 text-danger">
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 7 && (
                                <span
                                  className={`${
                                    ele.number == 7 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}
                                >
                                  {ele.number == 7 && ele.amount}
                                </span>
                              )
                          )}
                        </div>
                      </div>
                      <div className="casino-odd-box-container">
                        <div className="casino-nation-name">
                          {" "}
                          {reqRes?.data?.data?.t2[7]?.nat}
                        </div>
                        {reqRes?.data?.data?.t2[7]?.gstatus == "SUSPENDED" ||
                        reqRes?.data?.data?.t2[7]?.gstatus == "CLOSED" ? (
                          <div className="casino-odds-box back suspended-box w-100">
                            <div className="casino-volume">
                              {reqRes?.data?.data?.t2[7]?.gstatus}
                            </div>
                          </div>
                        ) : (
                          <div
                            className="casino-odds-box back w-100 "
                            onClick={() => {
                              handleModal(
                                reqRes?.data?.data?.t2[7]?.b1,
                                reqRes?.data?.data?.t2[7]?.nat,
                                14
                              );
                              setBetIndex(reqRes?.data?.data?.t2[7]?.sid);
                            }}
                          >
                            <div className="casino-odds">
                              {reqRes?.data?.data?.t2[7]?.b1}
                            </div>
                          </div>
                        )}
                        <div className="casino-nation-book text-center w-100 text-danger">
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 8 && (
                                <span
                                  className={`${
                                    ele.number == 8 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}
                                >
                                  {ele.number == 8 && ele.amount}
                                </span>
                              )
                          )}
                        </div>
                      </div>
                      <div className="casino-odd-box-container">
                        <div className="casino-nation-name">
                          {" "}
                          {reqRes?.data?.data?.t2[8]?.nat}
                        </div>
                        {reqRes?.data?.data?.t2[8]?.gstatus == "SUSPENDED" ||
                        reqRes?.data?.data?.t2[8]?.gstatus == "CLOSED" ? (
                          <div className="casino-odds-box back suspended-box w-100">
                            <div className="casino-volume">
                              {reqRes?.data?.data?.t2[8]?.gstatus}
                            </div>
                          </div>
                        ) : (
                          <div
                            className="casino-odds-box back w-100 "
                            onClick={() => {
                              handleModal(
                                reqRes?.data?.data?.t2[8]?.b1,
                                reqRes?.data?.data?.t2[8]?.nat,
                                15
                              );
                              setBetIndex(reqRes?.data?.data?.t2[8]?.sid);
                            }}
                          >
                            <div className="casino-odds">
                              {reqRes?.data?.data?.t2[8]?.b1}
                            </div>
                          </div>
                        )}
                        <div className="casino-nation-book text-center w-100 text-danger">
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 9 && (
                                <span
                                  className={`${
                                    ele.number == 9 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}
                                >
                                  {ele.number == 9 && ele.amount}
                                </span>
                              )
                          )}
                        </div>
                      </div>
                      <div className="casino-odd-box-container">
                        <div className="casino-nation-name">
                          {" "}
                          {reqRes?.data?.data?.t2[9]?.nat}
                        </div>
                        {reqRes?.data?.data?.t2[9]?.gstatus == "SUSPENDED" ||
                        reqRes?.data?.data?.t2[9]?.gstatus == "CLOSED" ? (
                          <div className="casino-odds-box back suspended-box w-100">
                            <div className="casino-volume">
                              {reqRes?.data?.data?.t2[9]?.gstatus}
                            </div>
                          </div>
                        ) : (
                          <div
                            className="casino-odds-box back w-100 "
                            onClick={() => {
                              handleModal(
                                reqRes?.data?.data?.t2[9]?.b1,
                                reqRes?.data?.data?.t2[9]?.nat,
                                16
                              );
                              setBetIndex(reqRes?.data?.data?.t2[9]?.sid);
                            }}
                          >
                            <div className="casino-odds">
                              {reqRes?.data?.data?.t2[9]?.b1}
                            </div>
                          </div>
                        )}
                        <div className="casino-nation-book text-center w-100 text-danger">
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 10 && (
                                <span
                                  className={`${
                                    ele.number == 10 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}
                                >
                                  {ele.number == 10 && ele.amount}
                                </span>
                              )
                          )}
                        </div>
                      </div>
                      <div className="casino-odd-box-container">
                        <div className="casino-nation-name">
                          {" "}
                          {reqRes?.data?.data?.t2[10]?.nat}
                        </div>
                        {reqRes?.data?.data?.t2[10]?.gstatus == "SUSPENDED" ||
                        reqRes?.data?.data?.t2[10]?.gstatus == "CLOSED" ? (
                          <div className="casino-odds-box back suspended-box w-100">
                            <div className="casino-volume">
                              {reqRes?.data?.data?.t2[10]?.gstatus}
                            </div>
                          </div>
                        ) : (
                          <div
                            className="casino-odds-box back w-100 "
                            onClick={() => {
                              handleModal(
                                reqRes?.data?.data?.t2[10]?.b1,
                                reqRes?.data?.data?.t2[10]?.nat,
                                17
                              );
                              setBetIndex(reqRes?.data?.data?.t2[10]?.sid);
                            }}
                          >
                            <div className="casino-odds">
                              {reqRes?.data?.data?.t2[10]?.b1}
                            </div>
                          </div>
                        )}
                        <div className="casino-nation-book text-center w-100 text-danger">
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 11 && (
                                <span
                                  className={`${
                                    ele.number == 11 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}
                                >
                                  {ele.number == 11 && ele.amount}
                                </span>
                              )
                          )}
                        </div>
                      </div>
                      <div className="casino-odd-box-container">
                        <div className="casino-nation-name">
                          {" "}
                          {reqRes?.data?.data?.t2[11]?.nat}
                        </div>
                        {reqRes?.data?.data?.t2[11]?.gstatus == "SUSPENDED" ||
                        reqRes?.data?.data?.t2[11]?.gstatus == "CLOSED" ? (
                          <div className="casino-odds-box back suspended-box w-100">
                            <div className="casino-volume">
                              {reqRes?.data?.data?.t2[11]?.gstatus}
                            </div>
                          </div>
                        ) : (
                          <div
                            className="casino-odds-box back w-100 "
                            onClick={() => {
                              handleModal(
                                reqRes?.data?.data?.t2[11]?.b1,
                                reqRes?.data?.data?.t2[11]?.nat,
                                18
                              );
                              setBetIndex(reqRes?.data?.data?.t2[11]?.sid);
                            }}
                          >
                            <div className="casino-odds">
                              {reqRes?.data?.data?.t2[11]?.b1}
                            </div>
                          </div>
                        )}
                        <div className="casino-nation-book text-center w-100 text-danger">
                          {thirtyTwoCardStack.map(
                            (ele) =>
                              ele.number === 12 && (
                                <span
                                  className={`${
                                    ele.number == 12 && ele.amount >= 0
                                      ? "success-1"
                                      : "zero"
                                  }`}
                                >
                                  {ele.number == 12 && ele.amount}
                                </span>
                              )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="Results-table">
                <div className="color-game-h mt-2">
                  <div className="px-2 pt-1">
                    <p>Last 10 Results</p>
                  </div>
                </div>
                <div className="card rst-card py-1 d-flex">
                  <div className="rst-row d-flex">
                    {results?.data?.result?.map((result, index) => {
                      const item = reqRes?.data?.data?.t2.find(
                        (item) => item.sid === result?.result
                      );

              
                      return (
                        <div
                          onClick={() => {
                            setMidRound(result.mid);
                            CasinoGameResult_API(result.mid);
                            setShowresultModal(true);
                            setTrophy(item.nat[0]);
                          }}
                          className="rst-btn ms-1"
                          key={index}
                        >
                          {item &&  (
                            <>
                              <img
                                src={resultMapping[result.result] }
                                className="img-fluid"
                                alt={`Player Card ${index}`}
                              />
                            </>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              {/* <<<<<<<---------My-Bets-table-------------->>>>>>>>>> */}
              <div className="My-Bets-table">
                <div className="color-game-h mt-2">
                  <div className="px-2 pt-1">
                    <p>Open Bets</p>
                  </div>
                </div>
                {HistoryTable}
              </div>
            </div>
          </div>

          <RightSidebar />
          {showModal && (
            <Modal
              id={id}
              name={name}
              betDetails={betDetails}
              setbetDetails={setbetDetails}
              setShowModal={setShowModal}
              realTime={realTime}
              setRealTimeData={setRealTimeData}
            />
          )}
          {showresultModal && <ResultsModal />}
          {/* {showDataModal && <DataModal />} */}
        </div>
      </div>
    </>
  );
}
