import React from "react";
import { useState } from "react";
import home from "../../Media/1.png";
import inplay from "../../Media/2.png";
import casino from "../../Media/3.png";
import tennis from "../../Media/4.png";
import cricket from "../../Media/5.png";
import football from "../../Media/6.png";
import { IoMdArrowDropdown } from "react-icons/io";
import "./Sidebar.css";
import { Link, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { competionDetails } from "../../Redux/Slices/competitionSlice";
import { competionwisematchDetails } from "../../Redux/Slices/compititionwisematch";
import { updateCurrentMatch } from "../../Redux/Slices/authSlice";

function Sidebar({ showSidebar, handleLinkClick }) {
  const [subMenu, setSubMenu] = useState(false);
  const [show, setShow] = useState(false);
  const [cricketMenu, setCricketMenu] = useState(false);
  const dispatch = useDispatch();
  var dropdown = document.getElementsByClassName("dropdown-btn");
  var i;

  for (i = 0; i < dropdown.length; i++) {
    dropdown[i].addEventListener("click", function () {
      this.classList.toggle("active");
      var dropdownContent = this.nextElementSibling;
      if (dropdownContent.style.display === "block") {
        dropdownContent.style.display = "none";
      } else {
        dropdownContent.style.display = "block";
      }
    });
  }

  const { competitions } = useSelector((state) => state.competition);
  const { matchs } = useSelector((state) => state.competitionwise);
  const handleMenuClick = (event) => {
    if (cricketMenu === event) {
      setCricketMenu(false);
    } else {
      dispatch(competionDetails({ eventId: event })).unwrap();
      setCricketMenu(event);
    }
  };

  const handleSubmenuClick = (event) => {
    if (subMenu === event) {
      setSubMenu(false);
    } else {
      dispatch(
        competionwisematchDetails({
          competitionId: event,
          eventId: cricketMenu,
        })
      ).unwrap();
      setSubMenu(event);
    }
  };

  return (
    <div
      className={`sidebar   side-bar col-lg-2 col-sm-4  h-sm-auto d-lg-block${
        showSidebar ? "d-block sm-side-bar position-absolute" : " d-none"
      }`}
    >
      <div className="row">
        <div className="col-12">
          <div className="sb-h py-2 pt-3 ">QUICK LINKS</div>
          <ul className="list-group">
            <li className="list-group-item">
              <div className="d-flex ">
                <NavLink onClick={handleLinkClick} className="nav-link" to="/">
                  <img className=" side-icon" src={home} alt="h" />
                  <span className="ms-2">HOME</span>
                </NavLink>
              </div>
            </li>
            <li className="list-group-item">
              <div className="d-flex">
                <NavLink
                  onClick={handleLinkClick}
                  className="nav-link"
                  to="/InPlay"
                >
                  <img className=" side-icon" src={inplay} alt="h" />
                  <span className="ms-2">IN-PLAY</span>
                </NavLink>
              </div>
            </li>
            {/* <li className="list-group-item">
              <div className="d-flex">
                <NavLink
                  onClick={handleLinkClick}
                  className="nav-link"
                  to="/LokSabha"
                >
                  <img className=" side-icon" src={inplay} alt="h" />
                  <span className="ms-2">Lok Sabha 2024</span>
                </NavLink>
              </div>
            </li> */}

            {/* <li className="list-group-item">
              <div className="d-flex">
                <NavLink
                  onClick={handleLinkClick}
                  className="nav-link"
                  to="/Ipl2024"
                >
                  <img className=" side-icon" src={inplay} alt="h" />
                  <span className="ms-2">World Cup 2024</span>
                </NavLink>
              </div>
            </li> */}

            <li className="list-group-item">
              <div className="d-flex">
                <NavLink
                  // onClick={handleLinkClick}
                  className="nav-link"
                  // to="/LiveCasino"
                >
                  <img className=" side-icon" src={casino} alt="h" />
                  <span className="ms-2">LIVE CASINO</span>
                </NavLink>
              </div>
            </li>
          </ul>
          <div className="sb-h py-2 pt-3 ">SPORTS MENU</div>
          <ul className="list-group">
            {/* <li className="list-group-item">
              <NavLink className="nav-link">
                <div
                  onClick={() => {
                    setInplayMenu(!inPlayMenu);
                  }}
                  className="d-flex"
                >
                  <img className=" side-icon" src={inplay} alt="h" />
                  <span className="ms-2">IN-PLAY</span>
                </div>
              </NavLink>
            </li>
            {inPlayMenu && (
              <div className="menu d-flex justify-content-between align-items-center px-2 ">
                Test Match
              </div>
            )} */}
            <li className="list-group-item">
              <NavLink className="nav-link">
                <div
                  // onClick={() => {
                  //   handleMenuClick(4);
                  // }}
                  className="d-flex justify-content-between align-items-center"
                >
                  <div>
                    <img className=" side-icon" src={cricket} alt="h" />
                    <span className="ms-2">CRICKET</span>
                  </div>
                  <IoMdArrowDropdown className="menu-icon" />
                </div>
              </NavLink>
            </li>
            {cricketMenu === 4 && (
              <div className="d-flex flex-column">
                {competitions?.map((comp) => (
                  <div key={comp.CompetitionID}>
                    <div
                      onClick={() => handleSubmenuClick(comp.CompetitionID)}
                      className="menu d-flex justify-content-between align-items-center px-2 "
                    >
                      {comp.name}
                      <IoMdArrowDropdown className="menu-icon" />
                    </div>

                    {subMenu === comp.CompetitionID &&
                      matchs?.map((match, index) => (
                        <div className="d-flex flex-column" key={index}>
                          <div className="subMenu d-flex justify-content-center align-items-center">
                            <NavLink
                              onClick={() =>
                                dispatch(
                                  updateCurrentMatch({
                                    ...match,
                                    ComName: comp.name,
                                  })
                                ) 
                              }
                              to={`${
                                match.name === "IPL 2024"
                                  ? "/Ipl2024"
                                  : `/Sport/${match.id}`
                              }`}
                              className="nav-link"
                            >
                              {match.name}{" "}
                            </NavLink>
                          </div>
                        </div>
                      ))}
                  </div>
                ))}
              </div>
            )}

            <li className="list-group-item">
              <NavLink className="nav-link">
                <div
                  // onClick={() => {
                  //   handleMenuClick(2);
                  // }}
                  className="d-flex justify-content-between align-items-center"
                >
                  <div>
                    <img className=" side-icon" src={tennis} alt="h" />
                    <span className="ms-2">TENNIS</span>
                  </div>
                  <IoMdArrowDropdown className="menu-icon" />
                </div>
              </NavLink>
            </li>
            {cricketMenu == 2 && (
              <div className="d-flex flex-column">
                {competitions?.map((comp) => (
                  <div key={comp.CompetitionID}>
                    <div
                      onClick={() => handleSubmenuClick(comp.CompetitionID)}
                      className="menu d-flex justify-content-between align-items-center px-2 "
                    >
                      {comp.name}
                      <IoMdArrowDropdown className="menu-icon" />
                    </div>

                    {subMenu === comp.CompetitionID &&
                      matchs?.map((match, index) => (
                        <div className="d-flex flex-column" key={index}>
                          <div className="subMenu d-flex justify-content-center align-items-center">
                            <NavLink
                              onClick={() =>
                                dispatch(
                                  updateCurrentMatch({
                                    ...match,
                                    ComName: comp.name,
                                  })
                                )
                              }
                              to={`/Tennis/${match.id}`}
                              className="nav-link"
                            >
                              {match.name}{" "}
                            </NavLink>
                          </div>
                        </div>
                      ))}
                  </div>
                ))}
              </div>
            )}

            <li className="list-group-item">
              <NavLink className="nav-link">
                <div
                  // onClick={() => {
                  //   handleMenuClick(1);
                  // }}
                  className="d-flex justify-content-between align-items-center"
                >
                  <div>
                    <img className=" side-icon" src={football} alt="h" />
                    <span className="ms-2">FOOTBALL</span>
                  </div>
                  <IoMdArrowDropdown className="menu-icon" />
                </div>
              </NavLink>
            </li>
            {cricketMenu == 1 && (
              <div className="d-flex flex-column">
                {competitions?.map((comp) => (
                  <div key={comp.CompetitionID}>
                    <div
                      onClick={() => handleSubmenuClick(comp.CompetitionID)}
                      className="menu d-flex justify-content-between align-items-center px-2 "
                    >
                      {comp.name}
                      <IoMdArrowDropdown className="menu-icon" />
                    </div>

                    {subMenu === comp.CompetitionID &&
                      matchs?.map((match, index) => (
                        <div className="d-flex flex-column" key={index}>
                          <div className="subMenu d-flex justify-content-center align-items-center">
                            <NavLink
                              onClick={() =>
                                dispatch(
                                  updateCurrentMatch({
                                    ...match,
                                    ComName: comp.name,
                                  })
                                )
                              }
                              to={`/Football/${match.id}`}
                              className="nav-link"
                            >
                              {match.name}{" "}
                            </NavLink>
                          </div>
                        </div>
                      ))}
                  </div>
                ))}
              </div>
            )}
          </ul>
          {/* <div className="sb-h py-2 pt-3 ">Income Reports</div>
          <ul className="list-group">
            <li className="list-group-item">
              <NavLink onClick={handleLinkClick} className="nav-link">
                <Link to="/RoiBonus" style={{ color: "white" }}>
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="ms-2">ROI Income</span>
                  </div>
                </Link>
              </NavLink>
            </li>
            <li className="list-group-item">
              <NavLink onClick={handleLinkClick} className="nav-link">
                <Link to="/LevelIncome" style={{ color: "white" }}>
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="ms-2">ROI Level Income</span>
                  </div>
                </Link>
              </NavLink>
            </li>
            <li className="list-group-item">
              <NavLink onClick={handleLinkClick} className="nav-link">
                <Link to="/MatchingBonus" style={{ color: "white" }}>
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="ms-2">Matching Bonus</span>
                  </div>
                </Link>
              </NavLink>
            </li>
            <li className="list-group-item">
              <NavLink onClick={handleLinkClick} className="nav-link">
                <Link to="/DirectDepositIncome" style={{ color: "white" }}>
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="ms-2">Direct Deposit Income</span>
                  </div>
                </Link>
              </NavLink>
            </li>
            <li className="list-group-item">
              <NavLink onClick={handleLinkClick} className="nav-link">
                <Link to="/CTOIncome" style={{ color: "white" }}>
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="ms-2">CTO Income</span>
                  </div>
                </Link>
              </NavLink>
            </li>
            <li className="list-group-item">
              <NavLink onClick={handleLinkClick} className="nav-link">
                <Link to="/RoyaltyBonus" style={{ color: "white" }}>
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="ms-2">Royalty Bonus</span>
                  </div>
                </Link>
              </NavLink>
            </li>
            <li className="list-group-item">
              <NavLink onClick={handleLinkClick} className="nav-link">
                <Link to="/WithdrawalShare" style={{ color: "white" }}>
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="ms-2">Withdrawal Share Income</span>
                  </div>
                </Link>
              </NavLink>
            </li>           
          </ul> */}
          {/* <div className="sb-h py-2 pt-3 ">Team Reports</div>
          <ul className="list-group mb-5">
            <li className="list-group-item">
              <NavLink onClick={handleLinkClick} className="nav-link">
                <Link to="/DirectTeam" style={{ color: "white" }}>
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="ms-2">Direct Team</span>
                  </div>
                </Link>
              </NavLink>
            </li>
            <li className="list-group-item">
              <NavLink onClick={handleLinkClick} className="nav-link">
                <Link to="/BinaryTree" style={{ color: "white" }}>
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="ms-2">Matching Tree</span>
                  </div>
                </Link>
              </NavLink>
            </li>
            <li className="list-group-item">
              <NavLink onClick={handleLinkClick} className="nav-link">
                <Link to="/LevelDetails" style={{ color: "white" }}>
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="ms-2">Level Details</span>
                  </div>
                </Link>
              </NavLink>
            </li>
            <li className="list-group-item">
              <NavLink onClick={handleLinkClick} className="nav-link">
                <Link to="/MyDownline" style={{ color: "white" }}>
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="ms-2">My Downline</span>
                  </div>
                </Link>
              </NavLink>
            </li>
          </ul> */}
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
