import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./Depositepayment.css";
import { logoutUser } from "../../Redux/Slices/authSlice";
import { API } from "../../API/API";
import { toast } from "react-toastify";

function DepositGateway() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [value, setValue] = useState(0);
  const userId = sessionStorage.getItem("userData");
  const token = sessionStorage.getItem("token");
  let [spiner, setspiner] = useState(false);
  const [gateway_type, setgateway_type] = useState("0");

  const incrementValue = (amount) => {
    setValue(parseInt(value) + amount);
  };

  const handleSubmit = async () => {
    try {
      setspiner(true);

      if (!value) {
        toast.error("Please enter amount");
        setspiner(false);
        return;
      }

      if (value < 100) {
        toast.error("Minimum 100 INR will be deposit !");
        setspiner(false);
        return;
      }

      let res = await API.post(
        "genarateOrderId",
        {
          username: userId,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );

      //console.log("generatedOrderId", res.data.data);

      if (res.data.data.result === "success") {
        let ress = await API.post("getQrCodeNew", {
          clientid: res.data.data.clientid,
          amount: `${value}`,
          fname: res.data.data.fname,
          email: res.data.data.email,
          mobile: res.data.data.mobile,
          userId: userId,
          gateway_type: gateway_type,
        });
        //console.log("paymentResponse", ress.data?.data);

        if (ress.data?.data?.status === "SUCCESS") {
          window.location.href = `${ress.data?.data?.payment_link}`;
        } else {
          toast.error(ress.data?.data.msg);
        }
        setspiner(false);
      } else {
        toast.error(res.data.data.result);
        setspiner(false);
      }
    } catch (e) {
      console.log("error while calling fuction sell", e);
      setspiner(false);
      if (
        e?.response?.data?.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };

  useEffect(() => {}, []);

  const HandleAmount = async (e) => {
    let value = e.target.value;
    const newValue = value.replace(/[^0-9]/gi, "");
    setValue(newValue);
  };

  return (
    // <div className="col-lg-10 com-h">
    <div className="col-lg-10 com-h mt-5 pt-3 depositesection">
      <div className="row mx-0">
        <div className="col-lg-6 col-md-8 col-sm-10 col-12 mx-auto">
          <div className="card paymentcard mb-3">
            <div className="card-body">
              <div className="heading mb-2">
                REQUEST YOUR DEPOSIT PAYMENT ONLINE
              </div>
              <div className="normalinput text-start mb-2">
                <label htmlFor="amount">Select Channel</label>
              </div>
              <ul
                className="navs nav nav-pills ms-2 mb-3 gap-3"
                // id="pills-tab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    // id="pills-UPI-x-QR-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-UPI-x-QR"
                    type="button"
                    // role="tab"
                    aria-controls="pills-UPI-x-QR"
                    aria-selected="true"
                    onClick={() => setgateway_type("0")}
                  >
                    <div className="imagetext">
                      <div className="text">
                        {" "}
                        UPI 1 x QR<br></br>
                        UPI 1 x QR
                      </div>
                    </div>
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link "
                    // id="pills-UPI-x-QR-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-UPI-x-QR"
                    type="button"
                    // role="tab"
                    aria-controls="pills-UPI-x-QR"
                    aria-selected="true"
                    onClick={() => setgateway_type("1")}
                  >
                    <div className="imagetext">
                      {/* <div className="image">
                                  <img
                                    className="w-100 h-100"
                                    src={UPI}
                                    alt=""
                                  />
                                </div> */}
                      <div className="text">
                        UPI 2 x QR<br></br>
                        UPI 2 x QR
                      </div>
                    </div>
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    // id="pills-UPI-x-QR-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-UPI-x-QR"
                    type="button"
                    // role="tab"
                    aria-controls="pills-UPI-x-QR"
                    aria-selected="true"
                    onClick={() => setgateway_type("2")}
                  >
                    <div className="imagetext">
                      <div className="text">
                        {" "}
                        UPI 3 x QR<br></br>
                        UPI 3 x QR
                      </div>
                    </div>
                  </button>
                </li>
              </ul>
              <div className="normalinput text-start mb-2">
                <label htmlFor="amount">Amount</label>
                <div className="inputimage">
                  <input
                    type="text"
                    formcontrolname="utr"
                    class="form-control custom shadow-none border-0"
                    value={value}
                    maxLength={10}
                    onChange={(e) => HandleAmount(e)}
                    placeholder="Enter Amount"
                  />
                </div>
              </div>
              <div className="row  g-3 mainrow">
                <div className="col-lg-3 col-6 col-sm-4 col-md-3">
                  <button
                    className="amoutbtn btn"
                    onClick={() => incrementValue(100)}
                  >
                    + 100
                  </button>
                </div>

                <div className="col-lg-3 col-6 col-sm-4 col-md-3 ">
                  <button
                    className="amoutbtn btn"
                    onClick={() => incrementValue(1000)}
                  >
                    + 1000
                  </button>
                </div>

                <div className="col-lg-3 col-6 col-sm-4 col-md-3">
                  <button
                    className="amoutbtn btn"
                    onClick={() => incrementValue(2000)}
                  >
                    + 2000
                  </button>
                </div>

                <div className="col-lg-3 col-6 col-sm-4 col-md-3">
                  <button
                    className="amoutbtn btn"
                    onClick={() => incrementValue(5000)}
                  >
                    + 5000
                  </button>
                </div>

                <div className="col-lg-3 col-6 col-sm-4 col-md-3">
                  <button
                    className="amoutbtn btn"
                    onClick={() => incrementValue(10000)}
                  >
                    + 10000
                  </button>
                </div>

                <div className="col-lg-3 col-6 col-sm-4 col-md-3">
                  <button
                    className="amoutbtn btn"
                    onClick={() => incrementValue(25000)}
                  >
                    + 25000
                  </button>
                </div>

                <div className="col-lg-3 col-6 col-sm-4 col-md-3">
                  <button
                    className="amoutbtn btn"
                    onClick={() => incrementValue(50000)}
                  >
                    + 50000
                  </button>
                </div>
                <div className="col-lg-3 col-6 col-sm-4 col-md-3">
                  <button
                    className="amoutbtn btn"
                    onClick={() => incrementValue(100000)}
                  >
                    + 100000
                  </button>
                </div>
              </div>
              <button
                type="button"
                className="submit btn"
                onClick={() => handleSubmit()}
              >
                Submit
              </button>
            </div>
          </div>

          <div className="card notcard mb-3">
            <div className="card-body">
              <h4 class="text-danger fw-bold mb-0 main-heading">Note:</h4>
              <ul class="notes mt-3 ms-3 mb-0 mt-0">
                {/* <li class="mb-1">
                  {" "}
                  AFTER DEPOSIT, UPLOAD SLIP, WRITE YOUR DEPOSIT AMOUNT.
                </li> */}
                <li class="mb-1"> COPY &amp; ENTER THE UTR NUMBER. </li>
                <li class="mb-1">
                  {" "}
                  CLICK SUBMIT BUTTON &amp; RECIEVE POINTS &amp; BONUS
                  INSTANTLY.{" "}
                </li>
                <li class="mb-1">
                  {" "}
                  NEFT RECIEVING TIME VARIES FROM 40 MINUTES TO 1 HOUR.
                </li>
                <li class="mb-1">
                  {" "}
                  SITE IS NOT RESPONSIBLE FOR MONEY DEPOSITED TO OLD, IN-ACTIVE
                  OR CLOSED ACCOUNTS.{" "}
                </li>
              </ul>
            </div>
          </div>
          {/* <div className="card notcard mb-3">
            <p className="text-white mb-0 py-2">No Data Found</p>
          </div> */}
        </div>
      </div>
    </div>
  );
}
export default DepositGateway;
