import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useNavigate } from "react-router-dom";
import { fetchAccountStatement } from "../../Redux/Slices/accountStatementSlice";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";
import { API } from "../../API/API";
import { logoutUser } from "../../Redux/Slices/authSlice";

const WithdrawalStatement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { accountStatement, isLoading, error } = useSelector(
  //   (state) => state.accountStatement
  // );
  const [leftreferralApi, setleftreferralApi] = useState([]);

  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(20);
  const [todate, settodate] = useState("");
  const [fromdate, setfromdate] = useState("");

  const userId = sessionStorage.getItem("userName");
  const token = sessionStorage.getItem("token");
  const referral_API = async () => {
    try {
      // console.log("userId-->", userId);
      let responceRight = await API?.post(
        `Get_WithdrawalStatementOfUserId`,
        {
          UserId: userId,
          username: sessionStorage.getItem("userName"),
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log("WithdrawalStatement-->", responceRight?.data?.data[0]);

      responceRight = responceRight?.data?.data[0];
      setleftreferralApi([]);
      let arrayLeft = [];
      Array.isArray(responceRight) &&
        responceRight.forEach((item, index) => {
          arrayLeft.push({
            sr: index + 1,
            UserId: item.UserId,
            uid: `${item.UserId} / ${item.uid}`,
            InAmount: item.InAmount,
            OutAmount: item.OutAmount,
            Balance: item.Balance,
            Edate: item.Edate1,
            remark: item.SesionId
              ? `${item.Narration} : ${item.SesionId}`
              : `${item.Narration}`,
            Narration: item.Narration,
            SesionId: item.SesionId,
          });

          setleftreferralApi(arrayLeft);
        });
    } catch (error) {
      console.log("Error While calling Myteam API", error);
      if (
        error.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };

  useEffect(() => {
    referral_API();
  }, []);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = leftreferralApi.slice(indexOfFirstPage, indexOfLastPost);
  var [matching_income, set_matching_income] = useState({
    cols: [
      { Header: "S.No.", accessor: "sr" },
      { Header: "Date", accessor: "Edate" },
      { Header: "Credit", accessor: "InAmount" },
      { Header: "Debit", accessor: "OutAmount" },
      { Header: "Balance", accessor: "Balance" },
      { Header: "Narration", accessor: "remark" },
    ],
  });

  return (
    // <div className="col-lg-10 com-h">
    <div className="col-lg-10 com-h mt-5 pt-3">
      <div className="row">
        <div className="col-12 p-3">
          <div className="bet-h ">
            <h4 className="p-2 ms-2">Withdrawal Wallet Statement</h4>

            <div className="">
              <Table data={[...currentPost]} columns={matching_income.cols} />
              <Table_Buttons
                setcurrentPage={setcurrentPage}
                currentPage={currentPage}
                totalData={leftreferralApi.length}
                listPerpage={listPerpage}
                handleRowsPerPageChange={setlistPerpage}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithdrawalStatement;
